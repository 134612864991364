<div class="kingster-page-wrapper" id="kingster-page-wrapper" *ngIf="staffData && staffData.teacherType === 'TEACHING'">
  <div class="gdlr-core-page-builder-body" *ngIf="staffData">
    <div class="gdlr-core-pbf-wrapper " style="padding: 70px 0px 40px 0px;">
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                    <div class="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                      style="border-width: 0px;" *ngIf="staffData">
                      <img [src]="webService.getUserImage(staffData)" width="700" height="450" alt="" /> <span
                        class="gdlr-core-image-overlay "></span>
                    </div>
                  </div>
                </div>
                <span class="mobile-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 34px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #161616 ;">
                          {{staffData.title}} {{staffData.lastName}} {{staffData.firstName}} <small style="font-size: 20px;">{{staffData.qualification}}</small>
                        </h3>
                      </div> <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 20px ;font-style: normal ;color: #6c6c6c ;">{{staffData.designation}}</span>
                    </div>
                  </div>
                </span>
                <span *ngIf="id === 'rajaguru'" class="mobile-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Principal's Desk</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style="padding-bottom: 20px ;">
                      <div class="gdlr-core-text-box-item-content"
                        style="font-size: 17px ;letter-spacing: 0px ;text-transform: none ; text-align: justify;">
                        <p style="text-align: justify;">The purpose of education is to teach our children to think and
                          prosper in a progressive
                          manner. Students should understand the significance of planning and prioritizing their time
                          and effort, which is essential to achieve success.</p>
                        <p style="text-align: justify;">Students should have the ability to discover the cause and
                          effect of every event. They must
                          not surrender to youthful fancies and indulgences. They should always be steady in their
                          studies and prepare themselves for the next day. Seriousness and sincerity in the classroom
                          and dedication through hard work leads to success.</p>
                        <p style="text-align: justify;">They must learn to preserve our heritage, traditions and
                          environment for themselves as well
                          as for the next generation.</p>
                        <p style="text-align: justify;">Parents should remember that their wards should never be forced
                          but guided to achieve their
                          objectives in an easy and fruitful manner.</p>
                        <p style="text-align: justify;">Our institution has excellent infrastructure with sincere and
                          experienced faculty. I wish all
                          the young, budding students to utilize the facilities extended by our college.</p>
                        <p style="text-align: justify;">Hard work, dedication, commitment and perseverance are the most
                          vital attributes for a person
                          to march towards success. The future is yours. So, you need to prepare yourself to succeed
                          amidst the cut-throat competition prevailing in the global arena.</p>
                        <p style="text-align: justify;">As understood rightly, teaching is not just transmitting
                          knowledge, but more importantly,
                          forming and transforming young people.</p>
                      </div>
                    </div>
                  </div>
                </span>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-personnel-info-item gdlr-core-item-pdb gdlr-core-item-pdlr clearfix"
                    style="padding-bottom: 17px ;">
                    <div class="gdlr-core-personnel-info-item-list-wrap">
                      <div class="kingster-personnel-info-list kingster-type-social-shortcode">
                        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                          style="padding-bottom: 0px ;">
                          <a [href]="webService.transform(staffData.facebookLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="facebook"
                            style="font-size: 18px ;color: #50bd77 ;" *ngIf="staffData.facebookLink">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a [href]="webService.transform(staffData.linkedInLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="linkedin"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"
                            *ngIf="staffData.linkedInLink"><i class="fa fa-linkedin"></i></a>
                          <a [href]="webService.transform(staffData.twitterLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="twitter"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"
                            *ngIf="staffData.twitterLink"><i class="fa fa-twitter"></i>
                          </a>
                        </div>
                      </div>
                      <div class="kingster-personnel-info-list kingster-type-email" *ngIf="showEmail"> <i
                          class="kingster-personnel-info-list-icon fa fa-envelope-open"> </i> {{staffData.email}}
                      </div>
                      <div *ngIf="!showEmail">
                        <p>
                          Verify to View Email Id
                        </p>
                        <re-captcha (resolved)="emailResolved($event)"
                          siteKey="6LdcNc0aAAAAAKGckL3WDpujJk9aMvH86N-htABy"></re-captcha>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  *ngIf="staffData.clerivate !== '' || staffData.gscolor !== '' || staffData.scopus !== '' || staffData.orcid !== '' || staffData.rgate !== ''">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Reasearch Links</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                        <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                          *ngIf="staffData.gscolor && staffData.gscolor !== ''">
                          <a [href]="webService.transform(staffData.gscolor)" class="scolor-icon" target="_blank"
                            title="Facebook">
                            <img src="./assets/reaserch/gscolor.png" class="gscolor" />
                          </a>
                        </div>
                        <div class="gdlr-core-pbf-column gdlr-core-column-20"
                          *ngIf="staffData.scopus && staffData.scopus !== ''">
                          <a [href]="webService.transform(staffData.scopus)" class="scolor-icon" target="_blank"
                            title="Facebook">
                            <img src="./assets/reaserch/scopus.png" class="scopus" />
                          </a>
                        </div>
                        <div class="gdlr-core-pbf-column gdlr-core-column-20"
                          *ngIf="staffData.orcid && staffData.orcid !== ''">
                          <a [href]="webService.transform(staffData.orcid)" class="scolor-icon" target="_blank"
                            title="Facebook">
                            <img src="./assets/reaserch/orcid.png" class="orcid" />
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
                        style="padding-top: 10px;">
                        <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                          *ngIf="staffData.clerivate && staffData.clerivate !== ''">
                          <a [href]="webService.transform(staffData.clerivate)" class="scolor-icon" target="_blank"
                            title="Facebook">
                            <img src="./assets/reaserch/clerivate.png" class="clerivate" />
                          </a>
                        </div>
                        <div class="gdlr-core-pbf-column gdlr-core-column-30"
                          *ngIf="staffData.rgate && staffData.rgate !== ''">
                          <a [href]="webService.transform(staffData.rgate)" class="scolor-icon" target="_blank"
                            title="Facebook">
                            <img src="./assets/reaserch/rgate.png" class="rgate" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <span *ngIf="staffData && !isEmpty(staffData.skill)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Skills</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-skill-bar-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-size-small gdlr-core-type-rectangle">
                      <span *ngFor="let skill of staffData.skill;" class="badge badge-primary">
                        {{skill}}
                      </span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(experience)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Professional Experience</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item clearfix ">
                      <ul class="cbp_tmtimeline">
                        <li *ngFor="let exp of experience;">
                          <div class="cbp_tmicon cbp_tmicon-phone">
                            <i class="faPra fa-briefcase"></i>
                          </div>
                          <div class="cbp_tmlabel wow fadeInRight animated">
                            <h3 style="font-size: 14px;">{{exp.designation}},
                              {{exp.department}}</h3>
                            <h4 style="font-size: 12px;">
                              {{formatDate(exp.fromDate)}}{{exp.isOnGoing ? ' to Till Date' : ' to ' + formatDate(exp.toDate)}}
                            </h4>
                            <p style="font-size: 11px;" class="projectParagraph">
                              {{exp.institution}}, <br>
                              {{exp.institutionAddress}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(education)">
                  <div class="gdlr-core-pbf-element" style="padding-top: 50px;">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Academic Qualifications</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item clearfix ">
                      <ul class="cbp_tmtimeline">
                        <li *ngFor="let eduValue of education;">
                          <div class="cbp_tmicon cbp_tmicon-phone">
                            <i class="faPra fa-trophy"></i>
                          </div>
                          <div class="cbp_tmlabel wow fadeInRight animated">
                            <h3 style="font-size: 14px;">{{eduValue.qualification}} in
                              {{eduValue.qualificationType}}</h3>
                            <h4 style="font-size: 12px;">{{eduValue.fromDate}} to
                              {{eduValue.toDate}}</h4>
                            <p class="projectParagraph" style="font-size: 11px;">
                              {{eduValue.institution}} <br>
                              {{eduValue.institutionAddress}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column gdlr-core-column-40">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
              style="margin: 0px 0px 20px 0px;padding: 0px 0px 0px 0px;">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <span class="web-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 34px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #161616 ;">
                          {{staffData.title}} {{staffData.lastName}} {{staffData.firstName}} <small style="font-size: 20px;">{{staffData.qualification}}</small>
                        </h3>
                      </div> <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 20px ;font-style: normal ;color: #6c6c6c ;">{{staffData.designation}}</span>
                    </div>
                  </div>
                </span>
                <span *ngIf="id === 'rajaguru'" class="web-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Principal's Desk</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style="padding-bottom: 20px ;">
                      <div class="gdlr-core-text-box-item-content"
                        style="font-size: 17px ;letter-spacing: 0px ;text-transform: none ; text-align: justify;">
                        <p style="text-align: justify;">The purpose of education is to teach our children to think and
                          prosper in a progressive
                          manner. Students should understand the significance of planning and prioritizing their time
                          and effort, which is essential to achieve success.</p>
                        <p style="text-align: justify;">Students should have the ability to discover the cause and
                          effect of every event. They must
                          not surrender to youthful fancies and indulgences. They should always be steady in their
                          studies and prepare themselves for the next day. Seriousness and sincerity in the classroom
                          and dedication through hard work leads to success.</p>
                        <p style="text-align: justify;">They must learn to preserve our heritage, traditions and
                          environment for themselves as well
                          as for the next generation.</p>
                        <p style="text-align: justify;">Parents should remember that their wards should never be forced
                          but guided to achieve their
                          objectives in an easy and fruitful manner.</p>
                        <p style="text-align: justify;">Our institution has excellent infrastructure with sincere and
                          experienced faculty. I wish all
                          the young, budding students to utilize the facilities extended by our college.</p>
                        <p style="text-align: justify;">Hard work, dedication, commitment and perseverance are the most
                          vital attributes for a person
                          to march towards success. The future is yours. So, you need to prepare yourself to succeed
                          amidst the cut-throat competition prevailing in the global arena.</p>
                        <p style="text-align: justify;">As understood rightly, teaching is not just transmitting
                          knowledge, but more importantly,
                          forming and transforming young people.</p>
                      </div>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(projectData)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Projects</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                      <span>
                        <span *ngFor="let project of projectData | keyvalue">
                          <div>
                            <h6 class="secondary">{{project.key}}</h6>
                          </div>
                          <div>
                            <ul *ngIf="project.value">
                              <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px; padding-left: 10px;;"
                                *ngFor="let projectValue of project.value;">
                                <span class="gdlr-core-icon-list-icon-wrap" style="margin-top: 4px ;"> <i
                                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                    style="color: #6b6b6b ;font-size: 16px ;width: 16px ;"> </i> </span>
                                <div class="gdlr-core-icon-list-content-wrap">
                                  <span class="gdlr-core-icon-list-content"
                                    style="font-size: 17px ;text-transform: none ;">
                                    <b>{{projectValue.title}}</b>
                                  </span>
                                  <span class="secondary"> {{formatDate(projectValue.fromDate)}} -
                                    {{formatDate(projectValue.toDate)}} </span>
                                  <br>
                                  {{projectValue.description}}
                                  <br>
                                  <span class="danger">Institution/Agency : </span> {{projectValue.agency}}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(publicationData)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Publications</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                      <span>
                        <span *ngFor="let publication of publicationData | keyvalue">
                          <div>
                            <h6 class="secondary">{{publication.key}}</h6>
                          </div>
                          <div>
                            <ul *ngIf="publication.value">
                              <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px; padding-left: 10px;;"
                                *ngFor="let publicationValue of publication.value;">
                                <span class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                                    class="gdlr-core-icon-list-icon fa fa-hand-o-right"
                                    style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                                <div class="gdlr-core-icon-list-content-wrap">
                                  <span class="gdlr-core-icon-list-content"
                                    style="font-size: 17px ;text-transform: none ;">
                                    <span class="secondary">{{publicationValue.type}}</span> <br>
                                    <b>{{publicationValue.title}}</b>
                                  </span>
                                  <span class="danger">Year : </span> {{publicationValue.year}}
                                  <br>
                                  <span class="danger" *ngIf="publicationValue.publication != ''">Name of the Journal : </span> {{publicationValue.publication}}
                                  <br>
                                  <span class="danger">Publisher : </span> {{publicationValue.publisher}}
                                  <span *ngIf="publicationValue.ISBN != '' || publicationValue.issue != ''">
                                    <br> <span *ngIf="publicationValue.ISBN != ''"> [<b>ISBN/ISSN</b> :
                                      {{publicationValue.ISBN}}]
                                      &nbsp;
                                    </span><span *ngIf="publicationValue.issue != ''"> [<b>Issue</b> :
                                      {{publicationValue.issue}}]</span>
                                  </span>
                                  <span *ngIf="publicationValue.volume != '' || publicationValue.pageNo != ''">
                                    <br> <span *ngIf="publicationValue.volume != ''"> [<b>Volume</b> :
                                      {{publicationValue.volume}}] </span>&nbsp;<span
                                      *ngIf="publicationValue.pageNo != ''">
                                      [<b>Page No</b> : {{publicationValue.pageNo}}]</span>
                                  </span>
                                  <span *ngIf="publicationValue.authors && publicationValue.authors != ''">
                                    <br> <b> Authors: </b>
                                      {{publicationValue.authors}}
                                  </span>
                                  <span *ngIf="publicationValue.insitutionAffiliation && publicationValue.insitutionAffiliation != ''">
                                    <br> <b> Institutional affiliation as mentioned in the publication: </b>
                                      {{publicationValue.insitutionAffiliation}}
                                  </span>
                                  <span *ngIf="publicationValue.citationIndex && publicationValue.citationIndex != ''">
                                    <br> <b> Citation Index: </b>
                                      {{publicationValue.citationIndex}}
                                  </span>
                                  <span *ngIf="publicationValue.citationIndexNos && publicationValue.citationIndexNos != ''">
                                    <br> <b> Number of citations excluding self citations: </b>
                                      {{publicationValue.citationIndexNos}}
                                  </span>
                                  <span *ngIf="publicationValue.publishedResearchArticle && publicationValue.publishedResearchArticle != ''">
                                    <br> <b> Published research article in: </b>
                                      {{publicationValue.publishedResearchArticle}}
                                  </span>
                                  <span *ngIf="publicationValue.publicationUrl != ''"> <br> <b> URL: </b> <a
                                      href="{{publicationValue.publicationUrl}}" target="_blank">
                                      {{publicationValue.publicationUrl}} </a> </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(presentationData)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Presentation</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                      <span>
                        <span *ngFor="let datamap of presentationData | keyvalue">
                          <div>
                            <h6 class="secondary">{{datamap.key}}</h6>
                          </div>
                          <div>
                            <ul *ngIf="datamap.value">
                              <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px; padding-left: 10px;;"
                                *ngFor="let data of datamap.value;">
                                <span class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                                    class="gdlr-core-icon-list-icon fa fa-arrow-right"
                                    style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                                <div class="gdlr-core-icon-list-content-wrap">
                                  <span class="gdlr-core-icon-list-content"
                                    style="font-size: 17px ;text-transform: none ;">
                                    <span class="secondary">{{data.type}}</span> <br>
                                    <b>{{data.title}}</b>
                                  </span>
                                  <span class="secondary"> {{formatDate(data.fromDate)}} - {{formatDate(data.toDate)}}
                                  </span>
                                  <br>
                                  <span class="danger">Organized By : </span> {{data.organized}}
                                  <br>
                                  <span class="danger">Venue : </span> {{data.venue}}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(participationData)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Participation</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                      <span>
                        <span *ngFor="let datamap of participationData | keyvalue">
                          <div>
                            <h6 class="secondary">{{datamap.key}}</h6>
                          </div>
                          <div>
                            <ul *ngIf="datamap.value">
                              <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px; padding-left: 10px;;"
                                *ngFor="let data of datamap.value;">
                                <span class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                                    class="gdlr-core-icon-list-icon fa fa-fighter-jet"
                                    style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                                <div class="gdlr-core-icon-list-content-wrap">
                                  <span class="gdlr-core-icon-list-content"
                                    style="font-size: 17px ;text-transform: none ;">
                                    <span class="secondary">{{data.type}}</span> <br>
                                    <b>{{data.title}}</b>
                                  </span>
                                  <span class="secondary"> {{formatDate(data.fromDate)}} - {{formatDate(data.toDate)}}
                                  </span>
                                  <br>
                                  <span class="danger">Organized By : </span> {{data.organized}}
                                  <br>
                                  <span class="danger">Venue : </span> {{data.venue}}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(organizedData)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 55px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Organized</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                      <span>
                        <span *ngFor="let datamap of organizedData | keyvalue">
                          <div>
                            <h6 class="secondary">{{datamap.key}}</h6>
                          </div>
                          <div>
                            <ul *ngIf="datamap.value">
                              <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px; padding-left: 10px;;"
                                *ngFor="let data of datamap.value;">
                                <span class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                                    class="gdlr-core-icon-list-icon fa fa-arrow-circle-o-right"
                                    style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                                <div class="gdlr-core-icon-list-content-wrap">
                                  <span class="gdlr-core-icon-list-content"
                                    style="font-size: 17px ;text-transform: none ;">
                                    <span class="secondary">{{data.type}}</span> <br>
                                    <b>{{data.title}}</b>
                                  </span>
                                  <span class="secondary"> {{formatDate(data.fromDate)}} - {{formatDate(data.toDate)}}
                                  </span>
                                  <br>
                                  <span class="danger">Role/Designation : </span> {{data.role}}
                                  <br>
                                  <span class="danger">Organized By : </span> {{data.organized}}
                                  <br>
                                  <span class="danger">Venue : </span> {{data.venue}}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-element">
            <section id="ngx-datatable-basic" *ngFor="let formData of gridData">
              <div *ngIf="(formData && formData.data && formData.data.length > 0) && formData.displayExporting"
                style="margin-top: 20px;">
                <hr style="border-width: 2px; border-color: #3db166;">
                <div style="margin-top: 5px;">
                  <h5 class="secondary card-title">{{formData.title}}</h5>
                </div>
                <div style="overflow: auto;">
                  <table style="min-width: 835px;" class="dynamic-table">
                    <thead>
                      <tr>
                        <th *ngFor="let column of formData.columns | keyvalue: asIsOrder">{{column.value}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let form of formData.data; index as idx;">
                        <td *ngFor="let column of formData.columns | keyvalue: asIsOrder">{{form[column.key] === '00/00/0000' ? '--' : form[column.key]}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="kingster-page-wrapper" id="kingster-page-wrapper"
  *ngIf="staffData && staffData.teacherType === 'NON_TEACHING'">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-wrapper " style="padding: 70px 0px 40px 0px;">
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                    <div class="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                      style="border-width: 0px;" *ngIf="staffData">
                      <img [src]="webService.getUserImage(staffData)" width="700" height="450" alt="" /> <span
                        class="gdlr-core-image-overlay"></span>
                    </div>
                  </div>
                </div>
                <span class="mobile-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 34px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #161616 ;">
                          {{staffData.title}} {{staffData.lastName}} {{staffData.firstName}} <small style="font-size: 20px;">{{staffData.qualification}}</small>
                        </h3>
                      </div> <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 20px ;font-style: normal ;color: #6c6c6c ;">{{staffData.designation}}</span>
                    </div>
                  </div>
                </span>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-personnel-info-item gdlr-core-item-pdb gdlr-core-item-pdlr clearfix"
                    style="padding-bottom: 17px ;">
                    <div class="gdlr-core-personnel-info-item-list-wrap">
                      <div class="kingster-personnel-info-list kingster-type-social-shortcode">
                        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                          style="padding-bottom: 0px ;">
                          <a [href]="webService.transform(staffData.facebookLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="facebook"
                            style="font-size: 18px ;color: #50bd77 ;" *ngIf="staffData.facebookLink">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a [href]="webService.transform(staffData.linkedInLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="linkedin"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"
                            *ngIf="staffData.linkedInLink"><i class="fa fa-linkedin"></i></a>
                          <a [href]="webService.transform(staffData.twitterLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="twitter"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"
                            *ngIf="staffData.twitterLink"><i class="fa fa-twitter"></i>
                          </a>
                        </div>
                      </div>
                      <div class="kingster-personnel-info-list kingster-type-email" *ngIf="showEmail"> <i
                          class="kingster-personnel-info-list-icon fa fa-envelope-open"> </i> {{staffData.email}}
                      </div>
                      <div *ngIf="!showEmail">
                        <p>
                          Verify to View Email Id
                        </p>
                        <re-captcha (resolved)="emailResolved($event)"
                          siteKey="6LdcNc0aAAAAAKGckL3WDpujJk9aMvH86N-htABy"></re-captcha>
                      </div>
                    </div>
                  </div>
                </div>
                <span *ngIf="staffData && !isEmpty(staffData.skill)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Skills</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-skill-bar-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-size-small gdlr-core-type-rectangle">
                      <span *ngFor="let skill of staffData.skill;" class="badge badge-primary">
                        {{skill}}
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column gdlr-core-column-40">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
              style="margin: 0px 0px 20px 0px;padding: 0px 0px 0px 0px;">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <span class="web-header">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 34px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #161616 ;">
                          {{staffData.title}} {{staffData.lastName}} {{staffData.firstName}} <small style="font-size: 20px;">{{staffData.qualification}}</small>
                        </h3>
                      </div> <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 20px ;font-style: normal ;color: #6c6c6c ;">{{staffData.designation}}</span>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(experience)">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Professional Experience</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item clearfix ">
                      <ul class="cbp_tmtimeline">
                        <li *ngFor="let exp of experience;">
                          <div class="cbp_tmicon cbp_tmicon-phone">
                            <i class="faPra fa-briefcase"></i>
                          </div>
                          <div class="cbp_tmlabel wow fadeInRight animated">
                            <h3 style="font-size: 14px;">{{exp.designation}},
                              {{exp.department}}</h3>
                            <h4 style="font-size: 12px;">
                              {{formatDate(exp.fromDate)}}{{exp.isOnGoing ? ' to Till Date' : ' to ' + formatDate(exp.toDate)}}
                            </h4>
                            <p style="font-size: 11px;" class="projectParagraph">
                              {{exp.institution}}, <br>
                              {{exp.institutionAddress}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
                <span *ngIf="!isEmpty(education)">
                  <div class="gdlr-core-pbf-element" style="padding-top: 50px;">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      style="margin-bottom: 45px ;">
                      <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                        style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                          Academic Qualifications</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-icon-list-item clearfix ">
                      <ul class="cbp_tmtimeline">
                        <li *ngFor="let eduValue of education;">
                          <div class="cbp_tmicon cbp_tmicon-phone">
                            <i class="faPra fa-trophy"></i>
                          </div>
                          <div class="cbp_tmlabel wow fadeInRight animated">
                            <h3 style="font-size: 14px;">{{eduValue.qualification}} in
                              {{eduValue.qualificationType}}</h3>
                            <h4 style="font-size: 12px;">{{eduValue.fromDate}} to
                              {{eduValue.toDate}}</h4>
                            <p class="projectParagraph" style="font-size: 11px;">
                              {{eduValue.institution}} <br>
                              {{eduValue.institutionAddress}}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>