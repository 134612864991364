<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">IQAC</div>
      <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      7.3.1 Institutional Distinctiveness: Quality Enhancement </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The performance of the Institution towards quality enhancement is a distinctive feature. The
                      College extends quality education that empowers the rural men and women for their holistic
                      development. The Institution caters mostly to the underprivileged first generation students
                      mobilizing financial support from the stakeholders,<b> Corporate Social Responsibility Schemes and
                        University Grants Commission.</b></p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      I Quality Education for the Underprivileged </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution imparts quality education for students belonging mostly to the Scheduled Caste
                      and Scheduled Tribe. Student admission is made observing the reservation rules of the Government
                      of Tamil Nadu. The fee prescribed by the Government is payable in three instalments. This has
                      considerably lessened the financial burden of the stakeholders. As a sequel, there is a tremendous
                      increase in the number of girl applicants. At present, the student strength is 3451 (Male 1082,
                      Female 2369). The Girls’ Hostel accommodates 150 inmates. Day scholar girls residing at a radius
                      of 70. Kms use 16 College buses which provide safe transportation.</p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      II STEP Course for English Proficiency</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution in collaboration with The Hindu Group offered Standardised Test for English
                      Proficiency (STEP) with the latter’s trainers for all first year undergraduates in batches.
                      Different levels of workbooks and manuals were used to improve the learners’ English proficiency.
                      On completion of the Course, students pursue UG Programmes in English medium with confidence.
                    </p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      III Empowerment and Uplift Training as Part of CSR Project through Redington Foundation</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution is identified as <b>Elite Smart Learning Centre</b> by<b> Redington Foundation,
                        Chennai </b> for imparting training programme for all the UG students in Communication Skills,
                      Personality Development and subject knowledge.
                    </p>
                    <p><b>Redington Foundation</b> has sponsored 25 Computers, a CLEVERTOUCH 75” Smart Interactive Panel
                      with all accessories at the <b>Redington Hotspot Lab</b> and a Smart Classroom. Students receive
                      training from Trainers and Elite Enables associated with Redington free of cost. Students take up
                      job interviews with confidence after attending the training. The College tries to convert the
                      graduates into employable youths. <b>Redington Foundation </b>has donated Laptops free of cost,
                      Rs.6500/- as scholarship and 50% concessional rate Laptops to several meritorious parentless
                      students. 17 Redington Trained students got placement in Nice Education, Kerala and Simho HR
                      Services Pvt. Ltd., Chennai.
                    </p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      IV TCS Affirmative Action-Free Employability Training for SC/ST and Underprivileged III UG
                      Students.</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution has provided free employability training for SC/ST and underprivileged III UG
                      students tapping CSR fund of TATA Group’s Affirmative Action Programme. 47 students received
                      training from 27.07.2018 to 07.09.2018 (100 hours) in English Communication, Basic Corporate
                      Etiquette, Numerical Ability, Logical Reasoning, General English, Basic Computer Operating Skills
                      and Group Discussion. Students took three written tests: Pre-Training, Mid Training and Post
                      Training to gauge their progress. After the written test, HR round, Technical round and Managerial
                      round, 14 students were finally selected and placed. The <b>Centre for Counselling and Guidance
                        Cell </b> coordinated the Training Programme.</p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      V High Order Service for the Mentee Institutions – UGC PARAMARSH SCHEME
                    </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>As a well-performing accredited Institution, the College was accorded Mentor status by the
                      <b>University Grants Commission</b> for guiding five nearby Mentee Institutions aspiring for NAAC
                      accreditation. Under the UGC-PARAMARSH Scheme, the Institution has brought about enhancement in
                      the overall quality of the Mentee Institutions through Mentor-Mentee Meetings; Seminars;
                      Workshops; Conference; Lectures; Training; and Demonstrations on ICT-Enabled Classroom by sharing
                      knowledge, Information, Resources etc. <b>The Internal Quality Assurance Cell</b> of the College
                      offered guidance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      VI Entrepreneurial Training for the Public belonging to SC/ST Communities
                    </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution has broadened its ambit and scope for imparting Entrepreneurial Training to the
                      public belonging to SC/ST Communities as a Joint R&D Project. <b>The Ministry of Science and
                        Technology, Government of India</b> has approved to sanction the Institution Rs.10 lakhs every
                      year for organizing programmes as a <b>Public Entrepreneurial Training Centre</b> for a period of
                      five years beginning from 2021.
                    </p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      VII DBT Star College Component for Strengthening Science UG Programmes
                    </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p><b>The Department of Biotechnology, Ministry of Science and Technology, Government of India, New
                        Delhi</b> has approved and granted the Institution Star College Status on the basis of All India
                      level for strengthening six undergraduate science programmes: Physics, Chemistry, Mathematics,
                      Zoology, Computer Science, and Nutrition & Dietetics.
                    </p>
                    <h6> <b>Provisions</b> </h6>
                    <div
                      class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                      style="padding-bottom: 25px ; text-align: justify;">
                      <ul>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">Developing technical skills for big data analysis with R,
                              Python and SPSS packages.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Hands on training on maintenance of equipment and log book,
                              purchase of lab materials, and Handling of Toxic Chemicals.
                            </span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Studying the impact of industrial effluents in Fireworks and
                              Matchworks Industries.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Creating interest among the students in the fields of Condensed
                              Mater.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">To expertise students in food service management.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Workshops, Hands on Training, Symposium, Guest Lectures, FDP,
                              National and International Conferences.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Addition of equipment to facilitate all students to handle
                              costly instruments individually.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ;">Honoria and TA for Visiting Faculty. </span></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      VIII Research Centre Status
                    </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The Institution promotes research culture. <b>Madurai Kamaraj University, Madurai </b>has
                      approved and elevated seven Departments as Research Centres:
                    </p>

                    <div
                      class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                      style="padding-bottom: 25px ; text-align: justify;">
                      <ul>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">Tamil, English, History, Mathematics, Physics, Computer Science
                              and Commerce.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">36 approved Research Supervisors guide their
                              full-time/part-time Research Wards.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">There are 65 ongoing researches.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">To encourage research, the College Management waives Research
                              fee payable to the Institution for its faculty members. The Library holdings can support
                              the Research Scholars with source materials.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">The Library subscribes for eShodh Sindu and <b>British Council
                                in India Digital Library</b>.</span></div>
                        </li>
                        <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                            class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                              class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                              style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                          <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                              style="font-size: 18px ; ">The faculty members have contributed 531 Research articles to
                              leading accredited Journals.</span></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Diagrammatic Representation of Quality Enhancement
                    </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: center;">
                    <img src="assets/images/distinctiveness.jpeg" style="width: 75%;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-iqac-menu></app-iqac-menu>
  </div>
</div>