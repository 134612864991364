<div *ngIf="departmentData" class="kingster-page-title-wrap  kingster-style-custom kingster-center-align"
  style="background-image: url({{departmentCoverImage}});">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-bottom-gradient"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr" style="padding-top: 400px ;padding-bottom: 60px ;"
      *ngIf="departmentData">
      <h1 class="kingster-page-title"
        style="font-size: 48px ;font-weight: 700 ;text-transform: none ;letter-spacing: 0px ;color: #ffffff ;">
        {{departmentData.name}}</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper " style="margin: 0px 0px 30px 0px;">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 35px 0px 20px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner">
            <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-background-wrap"></div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div *ngIf="departmentData"
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px; padding: 35px 50px 22px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">
                            Faculty Members</h3>
                        </div>
                        <a class="gdlr-core-feature-box-link"
                          routerLink="/departmentProfiles/{{departmentData.id}}"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -3px 0px -3px;">
                <div class="gdlr-core-pbf-background-wrap"></div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div *ngIf="departmentData"
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px; padding: 35px 50px 22px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">
                            Syllabus</h3>
                        </div><a class="gdlr-core-feature-box-link"
                          routerLink="/departmentDocs/{{departmentData.id}}/SYLLABUS" target="_self"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style="margin: 0px 0px 0px -7px;padding: 0px 0px 45px 0px;">
                <div class="gdlr-core-pbf-background-wrap"></div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element" *ngIf="departmentData">
                    <a (click)="departmentDocs('TIME_TABLE')"
                      routerLink="/departmentDocs/{{departmentData.id}}/SYLLABUS">
                      <div
                        class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                        <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                          style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px; padding: 35px 50px 22px;">
                          <div class="gdlr-core-feature-box-background"></div>
                          <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                            <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">
                              PEOs, POs & PSOs</h3>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element mobile-header">
              <div class="gdlr-core-event-item gdlr-core-item-pdb gdlr-core-item-pdlr" id="div_1dd7_94">
                <div class="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr" id="div_1dd7_95">
                  <div class="gdlr-core-block-item-title-inner clearfix">
                    <h3 class="gdlr-core-block-item-title" id="h3_1dd7_32">Notice Board
                    </h3>
                    <div class="gdlr-core-block-item-title-divider" id="div_1dd7_96"> </div>
                  </div>
                </div>
                <div class="gdlr-core-event-item-holder clearfix" style="height: 300px"
                  *ngIf="departmentNews && departmentNews.length">
                  <div id="newsScroll">
                    <ul>
                      <li *ngFor="let news of departmentNews">
                        <div class="gdlr-core-event-item-list gdlr-core-style-widget gdlr-core-item-pdlr  clearfix"
                          id="div_1dd7_97">
                          <div class="gdlr-core-event-item-content-wrap">
                            <h3 class="gdlr-core-event-item-title">
                              <a *ngIf="news.contentType === 'EXTERNAL_LINK'" target="_blank"
                                [href]="news.externalURL">{{news.title}}</a>
                              <a *ngIf="news.contentType !== 'EXTERNAL_LINK'"
                                routerLink="/news/{{news.id}}">{{news.title}}</a>
                            </h3>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div style="text-align: center; padding-bottom: 20px;"
                  *ngIf="!departmentNews || !departmentNews.length">
                  <h5> No Data Found </h5>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element" *ngIf="departmentData && departmentData.about">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                  <span [innerHTML]="departmentData.about"></span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element" *ngIf="yearOfEstablishment">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Year of Establishment</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element" *ngIf="yearOfEstablishment">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div
                  class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                  style="padding-bottom: 25px ; text-align: justify;">
                  <ul>
                    <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"
                      *ngIf="yearOfEstablishment && yearOfEstablishment.ug">
                      <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                          class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                          style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                      <div class="gdlr-core-icon-list-content-wrap">
                        <span class="gdlr-core-icon-list-content" style="font-size: 18px ;">
                          <span style="width: 80px; display: inline-block;">
                            UG
                          </span>
                          :
                          <span>
                            {{yearOfEstablishment.ug}}
                          </span>
                        </span>
                      </div>
                    </li>
                    <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"
                      *ngIf="yearOfEstablishment && yearOfEstablishment.postGraduate">
                      <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                          class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                          style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                      <div class="gdlr-core-icon-list-content-wrap">
                        <span class="gdlr-core-icon-list-content" style="font-size: 18px ;">
                          <span style="width: 80px; display: inline-block;">
                            PG
                          </span>
                          :
                          <span>
                            {{yearOfEstablishment.postGraduate}}
                          </span>
                        </span>
                      </div>
                    </li>
                    <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"
                      *ngIf="yearOfEstablishment && yearOfEstablishment.mPhil">
                      <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                          class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                          style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                      <div class="gdlr-core-icon-list-content-wrap">
                        <span class="gdlr-core-icon-list-content" style="font-size: 18px ;">
                          <span style="width: 80px; display: inline-block;">
                            M.Phil
                          </span>
                          :
                          <span>
                            {{yearOfEstablishment.mPhil}}
                          </span>
                        </span>
                      </div>
                    </li>
                    <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"
                      *ngIf="yearOfEstablishment && yearOfEstablishment.phD">
                      <span class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                          class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                          style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                      <div class="gdlr-core-icon-list-content-wrap">
                        <span class="gdlr-core-icon-list-content" style="font-size: 18px ;">
                          <span style="width: 80px; display: inline-block;">
                            Ph.D.
                          </span>
                          :
                          <span>
                            {{yearOfEstablishment.phD}}
                          </span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="web-header gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " id="div_1dd7_93">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <div class="gdlr-core-pbf-element web-header">
                  <div class="gdlr-core-event-item gdlr-core-item-pdb" id="div_1dd7_94">
                    <div class="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr"
                      id="div_1dd7_95">
                      <div class="gdlr-core-block-item-title-inner clearfix">
                        <h3 class="gdlr-core-block-item-title" id="h3_1dd7_32">Notice Board
                        </h3>
                        <div class="gdlr-core-block-item-title-divider" id="div_1dd7_96"> </div>
                      </div>
                    </div>
                    <div class="gdlr-core-event-item-holder clearfix" style="height: 300px"
                      *ngIf="departmentNews && departmentNews.length">
                      <div id="newsScroll">
                        <ul>
                          <li *ngFor="let news of departmentNews">
                            <div class="gdlr-core-event-item-list gdlr-core-style-widget gdlr-core-item-pdlr  clearfix"
                              id="div_1dd7_97">
                              <div class="gdlr-core-event-item-content-wrap">
                                <h3 class="gdlr-core-event-item-title">
                                  <a *ngIf="news.contentType === 'EXTERNAL_LINK'" target="_blank"
                                    [href]="news.externalURL">{{news.title}}</a>
                                  <a *ngIf="news.contentType !== 'EXTERNAL_LINK'"
                                    routerLink="/news/{{news.id}}">{{news.title}}</a>
                                </h3>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div style="text-align: center; padding-bottom: 20px;"
                      *ngIf="!departmentNews || !departmentNews.length">
                      <h5> No Data Found </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="text-23" class="widget widget_text kingster-widget web-header">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content" *ngIf="departmentData">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Contact Info</h3>
                    <p>
                      <span style="color: #3db166; font-size: 18px; font-weight: 600;"
                        *ngIf="departmentData">{{departmentData.name}}</span>
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                      </span></p>
                    <span *ngIf="departmentData && departmentData.primaryContactNumber">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Primary Contact </span>
                      <br />
                      <p><span style="font-size: 15px;">{{departmentData.primaryContactNumber}} </span></p>
                    </span>
                    <span *ngIf="departmentData && departmentData.secondaryContactNumber">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Secondary Contact </span>
                      <br />
                      <p><span style="font-size: 15px;">{{departmentData.secondaryContactNumber}} </span></p>
                    </span>
                    <span *ngIf="departmentData.email">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Email</span>
                      <p><span style="font-size: 15px;">{{departmentData.email}}</span></p>
                    </span>
                    <span *ngIf="departmentData.departmentURL">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Department Site</span>
                      <p><span style="font-size: 15px;">{{departmentData.departmentURL}}</span></p>
                    </span>
                    <span
                      *ngIf="departmentData.twitterLink || departmentData.linkedInLink || departmentData.facebookLink">
                      <span class="gdlr-core-space-shortcode" style="margin-top: 40px ;"></span>
                      <h3 style="font-size: 20px; color: #fff; margin-bottom: 15px;">Social Info</h3>
                      <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                        style="padding-bottom: 0px ;">
                        <span *ngIf="departmentData.facebookLink">
                          <a [href]="webService.transform(departmentData.facebookLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="facebook" style="color: #3db166 ;"
                            rel="noopener">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </span>
                        <span *ngIf="departmentData.linkedInLink">
                          <a [href]="webService.transform(departmentData.linkedInLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="linkedin"
                            style="color: #3db166 ;  margin-left: 14px" rel="noopener">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </span>
                        <span *ngIf="departmentData.twitterLink">
                          <a [href]="webService.transform(departmentData.twitterLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="twitter"
                            style="color: #3db166 ;  margin-left: 14px" rel="noopener">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper" style="padding-top: 0px;" *ngIf="programmes && programmes.length">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Programmes Offered
                  </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element" *ngIf="programmes && programmes.length">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <th style="width: 30%;"> PROGRAMME NAME </th>
                          <th style="width: 10%;"> DURATION </th>
                          <th style="width: 10%;"> INTAKE </th>
                          <th style="width: 50%;"> ELIGIBILITY </th>
                        </tr>
                        <tr *ngFor="let programme of programmes; index as idx">
                          <td style="text-align: left; padding-left: 10px ;">{{programme.programmeName}}</td>
                          <td style="text-align: center; padding-left: 10px ;">{{programme.duration}}</td>
                          <td style="text-align: center; padding-left: 10px ;">{{programme.intake}}</td>
                          <td style="text-align: left; padding-left: 10px ; white-space: pre-wrap;">{{programme.eligibility}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper " style="padding: 90px 0px 60px 0px;" *ngIf="departmentData">
        <div class="gdlr-core-pbf-background-wrap" style="background-color: #efefef ;"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <app-events-calendar type="DEPARTMENT" [departmentId]="departmentData.id"></app-events-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper" *ngIf="departmentData && alumniData && alumniData.length">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider  gdlr-core-item-pdlr">
                <div class="gdlr-core-block-item-title-inner clearfix">
                  <h3 id="h3_1dd7_10" class="gdlr-core-block-item-title"> Prominent Alumni - {{departmentData.name}}
                  </h3>
                </div>
                <div class="gdlr-core-flexslider flexslider gdlr-core-js-5" data-type="carousel" data-column="4"
                  data-nav="navigation" data-nav-parent="gdlr-core-personnel-item">
                  <div class="flex-viewport" style="overflow: hidden; position: relative;">
                    <ul class="slides">
                      <li class="gdlr-core-item-mglr flex-with-active-class" *ngFor="let alumni of alumniData">
                        <div class="gdlr-core-personnel-list clearfix">
                          <div
                            class="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                            <img [src]="webService.getAlumniImage(alumni)" alt="" width="500" height="700"
                              title="Well-groomed stylish young man with bow tie posing on dark background."
                              draggable="false"></div>
                          <div class="gdlr-core-personnel-list-content-wrap">
                            <h3 style="font-size: 18px; margin-bottom: 3px;">{{alumni.name}}</h3>
                            <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                              style="font-size: 12px;"> {{alumni.designation}} </div>
                            <div class="gdlr-core-personnel-list-divider gdlr-core-skin-divider"></div>
                            <div class="gdlr-core-personnel-list-content" style="margin-bottom: 5px;">
                              <p style="margin-bottom: 0px;">{{alumni.organization}}</p>
                            </div>
                            <div class="gdlr-core-personnel-list-content" style="font-weight: bold; margin-top: 0px;">
                              <p>
                                <span style="font-weight: normal;"> <b> Pass Out Year: </b>
                                  {{alumni.passOutYear}} </span> </p>
                            </div>
                            <div class="gdlr-core-personnel-list-social">
                              <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                                style="padding-bottom: 0px ;">
                                <a [href]="webService.transform(alumni.linkedInLink)" *ngIf="alumni.linkedInLink !== ''"
                                  target="_blank" class="gdlr-core-social-network-icon" title="google-plus">
                                  <i class="fa fa-linkedin-square"></i></a>
                                <a [href]="webService.transform(alumni.facebookLink)" *ngIf="alumni.facebookLink !== ''"
                                  target="_blank" class="gdlr-core-social-network-icon" title="facebook">
                                  <i class="fa fa-facebook"></i></a>
                                <a [href]="webService.transform(alumni.twitterLink)" *ngIf="alumni.twitterLink !== ''"
                                  target="_blank" class="gdlr-core-social-network-icon" title="twitter">
                                  <i class="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element mobile-header">
              <div id="text-23" class="widget widget_text kingster-widget">
                <div class="textwidget">
                  <div class="gdlr-core-widget-box-shortcode "
                    style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                    <div class="gdlr-core-widget-box-shortcode-content" *ngIf="departmentData">
                      <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Contact Info</h3>
                      <p>
                        <span style="color: #3db166; font-size: 18px; font-weight: 600;"
                          *ngIf="departmentData">{{departmentData.name}}</span>
                        <br />
                        <span style="font-size: 15px;">
                          Sri S.R.N.M College, <br />
                          Sattur, <br />
                          Virudhunagar (DT), <br />
                          Tamil Nadu, India.
                          <br>
                          <b>Pin:</b> 626 203
                        </span></p>
                      <span *ngIf="departmentData && departmentData.primaryContactNumber">
                        <span style="color: #3db166; font-size: 14px; font-weight: 500;">Primary Contact </span>
                        <br />
                        <p><span style="font-size: 15px;">{{departmentData.primaryContactNumber}} </span></p>
                      </span>
                      <span *ngIf="departmentData && departmentData.secondaryContactNumber">
                        <span style="color: #3db166; font-size: 14px; font-weight: 500;">Secondary Contact </span>
                        <br />
                        <p><span style="font-size: 15px;">{{departmentData.secondaryContactNumber}} </span></p>
                      </span>
                      <span *ngIf="departmentData.email">
                        <span style="color: #3db166; font-size: 14px; font-weight: 500;">Email</span>
                        <p><span style="font-size: 15px;">{{departmentData.email}}</span></p>
                      </span>
                      <span *ngIf="departmentData.departmentURL">
                        <span style="color: #3db166; font-size: 14px; font-weight: 500;">Department Site</span>
                        <p><span style="font-size: 15px;">{{departmentData.departmentURL}}</span></p>
                      </span>
                      <span
                        *ngIf="departmentData.twitterLink || departmentData.linkedInLink || departmentData.facebookLink">
                        <span class="gdlr-core-space-shortcode" style="margin-top: 40px ;"></span>
                        <h3 style="font-size: 20px; color: #fff; margin-bottom: 15px;">Social Info</h3>
                        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                          style="padding-bottom: 0px ;">
                          <span *ngIf="departmentData.facebookLink">
                            <a [href]="webService.transform(departmentData.facebookLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="facebook" style="color: #3db166 ;"
                              rel="noopener">
                              <i class="fa fa-facebook"></i>
                            </a>
                          </span>
                          <span *ngIf="departmentData.linkedInLink">
                            <a [href]="webService.transform(departmentData.linkedInLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="linkedin"
                              style="color: #3db166 ;  margin-left: 14px" rel="noopener">
                              <i class="fa fa-linkedin"></i>
                            </a>
                          </span>
                          <span *ngIf="departmentData.twitterLink">
                            <a [href]="webService.transform(departmentData.twitterLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="twitter"
                              style="color: #3db166 ;  margin-left: 14px" rel="noopener">
                              <i class="fa fa-twitter"></i>
                            </a>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>