import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public eventData: any;
  public eventAlbums: any;
  public id: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadEvent();
  }

  ngOnInit(): void {
   
  }

  loadEvent() {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getEvenData(this.id).subscribe((data) => {
      this.eventData = data;
    });
    this.webService.getEventAlbums(this.id).subscribe((data) => {
      this.eventAlbums = data;
    });
  }

}
