import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WebServiceService } from './../web-service.service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  private re = /(?:\.([^.]+))?$/;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
  @Input() title: string = '';
  @Input() src: string = '';
  public type: any|string = 'other'
  documentVisible = false;
  imageVisible = false;

  constructor(
    private webServiceService: WebServiceService
  ) { }

  ngOnInit(): void {
    const fileType: any = this.re.exec(this.src);
    if(fileType[1] && fileType[1] === 'pdf') {
      this.type = 'document';
    } else if(fileType[1] && (fileType[1] === 'jpg' || fileType[1] === 'png' || fileType[1] === 'jpeg' )) {
      this.type = 'image';
    } else {
      this.type = 'other';
    }
  }

  openDocument() {
    this.webServiceService.downloadFile(this.src).subscribe((res: any) => {
      this.pdfViewerOnDemand.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
      this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/reresh pdf
      this.documentVisible = true;
    }
  );
  }

  openImageDocument() {
    this.imageVisible = true;
  }

}



 
 
 
 