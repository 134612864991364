import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-department-syllabus-time-table',
  templateUrl: './department-syllabus-time-table.component.html',
  styleUrls: ['./department-syllabus-time-table.component.scss']
})
export class DepartmentSyllabusTimeTableComponent implements OnInit {

  public documents: any;
  public departmentData: any;
  public id: any;
  public title: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
   
  }

  loadCommitee() {
    const id: any = this.route.snapshot.paramMap.get('id');
    const type: any = this.route.snapshot.paramMap.get('type');
    this.id = parseInt(id);
    if (type === 'SYLLABUS') {
      this.title = 'Syllabus';
    }
    if (type === 'TIME_TABLE') {
      this.title = 'PEOs, POs & PSOs';
    }
    this.webService.getDepartmentProfile(this.id).subscribe((data) => {
      this.departmentData = data;
      if (type === 'SYLLABUS') {
        this.documents = data.syllabus;
      }
      if (type === 'TIME_TABLE') {
        this.documents = data.timeTable;
      }
    });
  }

  getUserName(email: string) {
    const username = email.split('@');
    return username[0];
  }
}
