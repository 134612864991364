<app-banner title="Our Library" align="left"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper " style="margin: 0px 0px 30px 0px;">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 35px 0px 20px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 27px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">Sri
                    S.Ramasamy Naidu Memorial College - Library</h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none; text-align: justify;">
                  <p>A Library Committee is functioning in the college with the Principal as its chairperson and the
                    Librarian as the coordinator. The committee has members from various departments. It decides the
                    policy of enhancing the library resources. </p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Library Administration Members</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div>
                      <div
                        class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                        <div
                          class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                          <div class="gdlr-core-personnel-list clearfix">
                            <div class="gdlr-core-personnel-list-content-wrap">
                              <a (click)="navigate('nattar')" style="cursor: pointer;" class="user-link">
                                <h3 class="gdlr-core-personnel-list-title"
                                  style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                                  Dr S NATTAR </h3>
                                <div
                                  class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                                  style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                                  Librarian</div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Details of the Library </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">Total
                            Carpet Area</h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          4978 Sq. ft
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">
                            Seating capacity</h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          100
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">
                            Working Hours</h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          Weekdays: 09.00 A.M. To 6.00 P.M
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Layout of the Library</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>Separate reading section and IT zone for accessing e-resources are available. Signboards are placed
                    wherever required. There are adequate personnel to guide the users in searching books and for using
                    OPAC.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Access to the Library</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>Floor plan is placed at the entrance of the college premises for easy access. Ramp facility is
                    available for the differently abled users. Open access system is followed.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Details of Library Holdings</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <th> Resources</th>
                          <th></th>
                        </tr>
                        <tr>
                          <td>Text Books </td>
                          <td>29684</td>
                        </tr>
                        <tr>
                          <td>Reference Books</td>
                          <td>9491</td>
                        </tr>
                        <tr>
                          <td>e-Books</td>
                          <td>123309</td>
                        </tr>
                        <tr>
                          <td>Journals</td>
                          <td>38</td>
                        </tr>
                        <tr>
                          <td>e-Journals</td>
                          <td>6237</td>
                        </tr>
                        <tr>
                          <td>CD & Video</td>
                          <td>45</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              style="padding-bottom: 40px ;">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Arrangement of Library Resources</h3>
                <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                  style="font-size: 22px ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-left-align">
              <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                <p>Dewey Decimal Classification (DDC) system is followed for the classification and arrangement of
                  books.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              style="padding-bottom: 40px ;">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  INFLIBNET</h3>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
              <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                <div style="overflow-x: auto;">
                  <table style="min-width: 835px;">
                    <tbody>
                      <tr>
                        <td>Full Text Journals </td>
                        <td> 6000 +</td>
                      </tr>
                      <tr>
                        <td>e-Book </td>
                        <td>1,35,000 +</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              style="padding-bottom: 40px ;">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  ICT Deployed in the Library</h3>
                <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                  style="font-size: 22px ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Library Automation</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>The College Library is fully automated. ROVAN Software has been installed with the
                  following three main modules for the Library. Book lending and stacking data in library is
                  computerized. Bar coding is adopted. OPAC (Online Public Access Catalogue) System is
                  followed.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-left-align">
              <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                <div style="overflow-x: auto;">
                  <table style="min-width: 835px;">
                    <tbody>
                      <tr>
                        <td>Total number of computers for public access</td>
                        <td> 6</td>
                      </tr>
                      <tr>
                        <td>Total numbers of printers for public access </td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Internet band width speed </td>
                        <td>60 mbps</td>
                      </tr>
                      <tr>
                        <td>Participation in Resource sharing networks/consortia </td>
                        <td>INFLIBINET-NLIST</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 15px; margin-bottom: 15px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Usage </h3>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-left-align">
              <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                <div style="overflow-x: auto;">
                  <table style="min-width: 835px;">
                    <tbody>
                      <tr>
                        <td> Average number of walk-ins </td>
                        <td> 450 per day </td>
                      </tr>
                      <tr>
                        <td> Average number of books issued/returned </td>
                        <td> 410 per day </td>
                      </tr>
                      <tr>
                        <td> Ratio of library books to students enrolled</td>
                        <td> 11:1 </td>
                      </tr>
                      <tr>
                        <td> Average number of books added during last three years </td>
                        <td> 1273 </td>
                      </tr>
                      <tr>
                        <td> Average number of login to OPAC </td>
                        <td> 50 </td>
                      </tr>
                      <tr>
                        <td> Average number of login to e-resources </td>
                        <td> 30 </td>
                      </tr>
                      <tr>
                        <td> Average number of e-resources downloaded/printed </td>
                        <td> 25 </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              style="padding-top: 30px ;">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Services Provided by the Library </h3>
                <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                  style="font-size: 22px ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Manuscripts </h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>The hard copies of U.G, P.G Projects, M. Phil Dissertations and Ph.D. theses are available for
                  reference.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Reference </h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>A separate section is allotted for reference books and back volumes of Journals.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Reprography </h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>Previous question papers, syllabus and current events in the newspapers can be photocopied free of
                  cost.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Information Deployment and Notification</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>Previous question papers, syllabus and current events in the newspapers can be photocopied free of
                  cost.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  OPAC</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                <p>Online Public Access Catalogue is available.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Internet Access</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>Internet facility is available for students and staff free of cost.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Downloads</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>Unlimited downloads are available for staff and students on request.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Printing</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>Printing facilities are available. Reading list/ Bibliography compilation is done on request.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  In-house/remote access to e-resources</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>INFLIBNET facility is provided for remote access on request.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  User Orientation</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content"
                style="font-size: 16px ;text-transform: none ; text-align: justify;">
                <p>New entrants are given orientation on library resources, rules and regulations.</p>
                <p>Assistance in Searching Databases</p>
                <p>Library Staff assist the students to search online databases.</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element" style="margin-top: 5px;">
            <div
              class="gdlr-core-title-item clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 18px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  INFLIBNET/IUC Facilities</h3>
              </div>
              <br />
              <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                <p>The College subscribes INFLIBNET -NLIST</p>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              style="padding-top: 30px ;">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                  Library Rules </h3>
                <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                  style="font-size: 22px ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
          </div>
          <div
            class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
            style="padding-bottom: 25px ; text-align: justify;">
            <ul>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">Strict silence should be observed in the Library.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">Every student can borrow not more than two / three books at a time from
                    the library.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">Books borrowed from the library must be returned on the due date.</span>
                </div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">The Librarian has the right to recall any book at any time even before the
                    expiry of normal lending period.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">If a student fails to return a book on the due date, a fine of one rupee
                    per day will be levied. Sundays and holidays will be excluded.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">Absence from college will not be ordinarily admitted as an excuse for
                    delay in the return of books.
                  </span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 18px ;">Students are directed to use all Library books with care.Writing in any
                    form in the books is forbidden. Damage fee (Double the cost of the book) will be collectted from the
                    students, who damage the books in any form.</span></div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div id="text-23" class="widget widget_text kingster-widget">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Library Contact Info</h3>
                    <p>
                      <!-- <span style="color: #3db166; font-size: 18px; font-weight: 600;">Dr V.KANAGAVEL</span> -->
                      <br>
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Librarian</span>
                      <br />
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                      </span></p>
                    <!-- <span style="color: #3db166; font-size: 14px; font-weight: 500;">Boy's Hostel</span>
                    <br />
                    <p><span style="font-size: 15px;">+ 91 - 04562 - 261054</span></p>
                    <span style="color: #3db166; font-size: 14px; font-weight: 500;">Girls's Hostel</span>
                    <br />
                    <p><span style="font-size: 15px;">+ 91 - 04562 - 261440</span></p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>