<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <app-home-slider></app-home-slider>
    <app-important-anoncement></app-important-anoncement>
    <app-important-links></app-important-links>
    <div
      class="gdlr-core-pbf-wrapper"
      id="div_1dd7_91"
      style="padding-bottom: 0px"
    >
      <div class="gdlr-core-pbf-background-wrap"></div>
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
        <div
          class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
        >
          <div class="gdlr-core-pbf-column gdlr-core-column-30">
            <div
              class="gdlr-core-pbf-column-content-margin gdlr-core-js"
              id="div_1dd7_93"
            >
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-event-item gdlr-core-item-pdb"
                    id="div_1dd7_94"
                  >
                    <div
                      class="gdlr-core-block-item-title-wrap gdlr-core-left-align gdlr-core-item-mglr"
                      id="div_1dd7_95"
                    >
                      <div class="gdlr-core-block-item-title-inner clearfix">
                        <h3 class="gdlr-core-block-item-title" id="h3_1dd7_32">
                          News
                        </h3>
                        <div
                          class="gdlr-core-block-item-title-divider"
                          id="div_1dd7_96"
                        ></div>
                      </div>
                    </div>
                    <div class="gdlr-core-event-item-holder clearfix">
                      <div
                        id="newsScroll"
                        style="min-height: 350px"
                        *ngIf="academicNews && academicNews.length"
                      >
                        <ul>
                          <li *ngFor="let news of academicNews">
                            <div
                              class="gdlr-core-event-item-list gdlr-core-style-widget gdlr-core-item-pdlr clearfix"
                              id="div_1dd7_97"
                              style="padding-left: 0px"
                            >
                              <div
                                class="gdlr-core-event-item-content-wrap"
                                style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: left;
                                "
                              >
                                <div style="flex-basis: 5%">
                                  <img
                                    src="./../../assets/images/bulb.gif"
                                    style="width: 40px"
                                  />
                                </div>
                                <div style="padding-left: 5px">
                                  <h3 class="gdlr-core-event-item-title">
                                    <a
                                      *ngIf="
                                        news.contentType === 'EXTERNAL_LINK'
                                      "
                                      target="_blank"
                                      [href]="news.externalURL"
                                      >{{ news.title }}</a
                                    >
                                    <a
                                      *ngIf="
                                        news.contentType !== 'EXTERNAL_LINK'
                                      "
                                      routerLink="/news/{{ news.id }}"
                                      >{{ news.title }}</a
                                    >
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        style="min-height: 350px; text-align: center"
                        *ngIf="academicNews && !academicNews.length"
                      >
                        <h4 style="justify-content: center; align-self: center">
                          No News Found
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column gdlr-core-column-30">
            <div
              class="gdlr-core-pbf-column-content-margin gdlr-core-js"
              id="div_1dd7_93"
            >
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-event-item gdlr-core-item-pdb"
                    id="div_1dd7_94"
                  >
                    <div
                      class="gdlr-core-block-item-title-wrap gdlr-core-left-align gdlr-core-item-mglr"
                      id="div_1dd7_95"
                    >
                      <div class="gdlr-core-block-item-title-inner clearfix">
                        <h3 class="gdlr-core-block-item-title" id="h3_1dd7_32">
                          Calendar
                        </h3>
                        <div
                          class="gdlr-core-block-item-title-divider"
                          id="div_1dd7_96"
                        ></div>
                      </div>
                    </div>
                    <div class="gdlr-core-event-item-holder clearfix">
                      <div
                        id="eventsScroll"
                        style="min-height: 350px"
                        *ngIf="events && events.length"
                      >
                        <ul style="width: 100%">
                          <li *ngFor="let event of events">
                            <div
                              class="gdlr-core-event-item-list gdlr-core-style-widget gdlr-core-item-pdlr clearfix"
                              id="div_1dd7_97"
                            >
                              <div class="gdlr-core-event-item-content-wrap">
                                <span
                                  class="gdlr-core-event-item-info gdlr-core-type-start-date-month"
                                >
                                  <span class="gdlr-core-date">{{
                                    getDate(event.startDate)
                                  }}</span>
                                  <span class="gdlr-core-month">{{
                                    getMonth(event.startDate)
                                  }}</span>
                                </span>
                                <h3 class="gdlr-core-event-item-title">
                                  <a routerLink="/event/{{ event.id }}">{{
                                    event.title
                                  }}</a>
                                </h3>
                                <div class="gdlr-core-event-item-info-wrap">
                                  <span
                                    class="gdlr-core-event-item-info gdlr-core-type-time"
                                  >
                                    <span class="gdlr-core-head">
                                      <i class="icon_clock_alt"> </i>
                                    </span>
                                    <span class="gdlr-core-tail"
                                      >{{ getDateFull(event.startDate) }} -
                                      {{ getDateFull(event.endDate) }}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        style="min-height: 350px; text-align: center"
                        *ngIf="events && !events.length"
                      >
                        <h4 style="justify-content: center; align-self: center">
                          No Events Found
                        </h4>
                        <div
                          class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                          style="padding-top: 20px"
                        >
                          <a
                            class="gdlr-core-button gdlr-core-button-transparent gdlr-core-button-no-border"
                            routerLink="/academicCalander"
                            id="a_1dd7_7"
                          >
                            <span class="gdlr-core-content"
                              >View Academic Calendar
                            </span>
                            <i
                              class="gdlr-core-pos-right fa fa-long-arrow-right"
                              id="i_1dd7_2"
                            >
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gdlr-core-pbf-element"
                    *ngIf="events && events.length"
                  >
                    <div
                      class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                    >
                      <a
                        class="gdlr-core-button gdlr-core-button-transparent gdlr-core-button-no-border"
                        routerLink="/academicCalander"
                        id="a_1dd7_7"
                      >
                        <span class="gdlr-core-content"
                          >View Academic Calendar
                        </span>
                        <i
                          class="gdlr-core-pos-right fa fa-long-arrow-right"
                          id="i_1dd7_2"
                        >
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="gdlr-core-pbf-wrapper " id="div_1dd7_21">
        <div class="gdlr-core-pbf-background-wrap">
          <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" id="div_1dd7_22"
            data-parallax-speed="0.2"> </div>
        </div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align"
                      style="padding-bottom: 20px ;">
                      <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                        style="border-width: 0px;"> <img src="./../../assets/images/logo_full.png" alt="" width="200"
                          height="200" title="logo-white"> </div>
                    </div>
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                      id="div_1dd7_25">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_5">About Our
                          Institution
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-40">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      id="div_1dd7_26">
                      <div class="gdlr-core-text-box-item-content" id="div_1dd7_27">
                        <p> Sri S.Ramasamy Naidu, familiarly known as "SR" was a
                          dedicated educationalist and freedom fighter. His
                          realization that political freedom will be of no value
                          unless there is freedom from illiteracy led him to work
                          tirelessly for the great cause of education. Thanks to his
                          keen interest and initiative today, this part of Tamilnadu
                          is blessed with a number of educational institutions. </p>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      id="div_1dd7_28">
                      <div class="gdlr-core-text-box-item-content" id="div_1dd7_29">
                        <p> Four decades ago, some great philanthropists of this region
                          founded our College in memory of this visionary leader, in
                          order to realize his noble ambition. It was incepted at
                          Nagalapuram village in 1970 with Thiru.V.Venugopala
                          Krishnasamy Naidu as its Founder President. The Mission of
                          the College was to serve the interests of the rural poor,
                          help them have the benefit of excellent education and find
                          employment, and thereby ameliorate their lifestyles. </p>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <a class="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-no-border"
                        routerLink="/about" id="gdlr-core-button-id-66813"> <span class="gdlr-core-content">Read
                          More</span> <i class="gdlr-core-pos-right fa fa-long-arrow-right" id="i_1dd7_0"> </i> </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div
        class="gdlr-core-pbf-wrapper"
        style="
          margin: 0px auto 0px auto;
          padding: 0px 0px 0px 0px;
          max-width: 100%;
        "
      >
        <div
          class="gdlr-core-pbf-background-wrap"
          style="background-color: #192f59"
        ></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div class="gdlr-core-pbf-wrapper-content clearfix">
            <div
              class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
            >
              <div
                class="gdlr-core-pbf-column-content-margin gdlr-core-js gdlr-core-column-extend-left principal-image"
              >
                <img
                  src="./../../assets/images/principal_r1.jpg"
                  style="height: 100%; width: 100%"
                />
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-30">
              <div
                class="gdlr-core-pbf-column-content-margin gdlr-core-js"
                style="padding: 100px 0px 90px 75px; height: 589px"
                data-sync-height="height-1"
                data-sync-height-center=""
              >
                <div class="gdlr-core-pbf-background-wrap"></div>
                <div
                  class="gdlr-core-pbf-column-content clearfix gdlr-core-js gdlr-core-sync-height-content"
                >
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          style="
                            font-size: 35px;
                            font-weight: 700;
                            letter-spacing: 0px;
                            text-transform: none;
                            color: #ffffff;
                          "
                        >
                          Dr. P. Rajaguru
                        </h3>
                      </div>
                      <span
                        class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="
                          font-weight: 500;
                          font-style: normal;
                          color: #3db166;
                        "
                        >Principal</span
                      >
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-blockquote-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-large-size"
                      style="padding-bottom: 0px"
                    >
                      <div class="gdlr-core-blockquote gdlr-core-info-font">
                        <div class="gdlr-core-blockquote-item-content-wrap">
                          <div
                            class="gdlr-core-blockquote-item-content gdlr-core-skin-content"
                            style="
                              font-size: 21px;
                              font-weight: 600;
                              letter-spacing: 0px;
                              color: #ffffff;
                              text-align: justify;
                            "
                          >
                            <p>
                              We cannot always build the future for our youth.
                              We can build our youth for the future. Remember,
                              the reward for every good action will be good
                              only.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                    >
                      <a
                        class="gdlr-core-button gdlr-core-button-transparent gdlr-core-button-no-border"
                        routerLink="/profile/rajaguru"
                        style="padding: 0px; color: #a6a6a6"
                      >
                        <span class="gdlr-core-content"
                          >View Principal's Desk</span
                        >
                        <i class="gdlr-core-pos-right fa fa-long-arrow-right">
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gdlr-core-pbf-wrapper" id="div_1dd7_44">
        <div class="gdlr-core-pbf-background-wrap"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div
              class="gdlr-core-pbf-column gdlr-core-column-40 gdlr-core-column-first"
            >
              <div
                class="gdlr-core-pbf-column-content-margin gdlr-core-js"
                id="div_1dd7_45"
                data-sync-height="height-2"
              >
                <div
                  class="gdlr-core-pbf-column-content clearfix gdlr-core-js gdlr-core-sync-height-content"
                >
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-blog-item gdlr-core-item-pdb clearfix gdlr-core-style-blog-widget"
                    >
                      <div
                        class="gdlr-core-block-item-title-wrap gdlr-core-left-align gdlr-core-item-mglr"
                        id="div_1dd7_46"
                      >
                        <div class="gdlr-core-block-item-title-inner clearfix">
                          <h3
                            class="gdlr-core-block-item-title"
                            id="h3_1dd7_10"
                          >
                            Autonomy
                          </h3>
                          <div
                            class="gdlr-core-block-item-title-divider"
                            id="div_1dd7_47"
                          ></div>
                        </div>
                        <br />
                        <span
                          class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                          style="line-height: 35px; text-align: justify"
                        >
                          <strong>
                            Sri S. Ramasamy Naidu Memorial College
                          </strong>
                          became an autonomous college in 2010. It continues to
                          be an affiliated college of the
                          <strong> Madurai Kamaraj University </strong> and is
                          autonomous in the sense that it is free to frame its
                          own courses of studies and to adopt innovative methods
                          of teaching and evaluation. The university confers the
                          degrees to the students after passing the examinations
                          held by the college and the name of the college will
                          be indicated in the degrees by the
                          <strong> Madurai Kamaraj University </strong>.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-column gdlr-core-column-20"
              id="gdlr-core-column-8"
            >
              <div
                class="gdlr-core-pbf-column-content-margin gdlr-core-js gdlr-core-column-extend-right"
                id="div_1dd7_48"
                data-sync-height="height-2"
              >
                <div class="gdlr-core-pbf-background-wrap">
                  <div
                    class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    id="div_1dd7_49"
                    data-parallax-speed="0"
                  ></div>
                </div>
                <div
                  class="gdlr-core-pbf-column-content clearfix gdlr-core-js gdlr-core-sync-height-content"
                >
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="div_1dd7_50"
                    >
                      <div
                        class="gdlr-core-title-item-left-icon"
                        id="div_1dd7_51"
                      >
                        <i class="icon_link_alt" id="i_1dd7_1"> </i>
                      </div>
                      <div class="gdlr-core-title-item-left-icon-wrap">
                        <div class="gdlr-core-title-item-title-wrap clearfix">
                          <h3
                            class="gdlr-core-title-item-title gdlr-core-skin-title"
                            id="h3_1dd7_15"
                          >
                            Quick Links
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-66469"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_16"
                        >
                          <a routerLink="departments">Departments </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_54"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_55"
                      ></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-51281"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_18"
                        >
                          <a routerLink="/examResults" target="_self"
                            >Exam Results</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_56"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_57"
                      ></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-78243"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_19"
                        >
                          <a routerLink="/infrastructure" target="_self"
                            >Infrastructure</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_58"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_59"
                      ></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-14842"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_20"
                        >
                          <a routerLink="/academicCalander" target="_self"
                            >Academic Calendar</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_62"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_63"
                      ></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-14842"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_20"
                        >
                          <a routerLink="/placement" target="_self"
                            >Placement</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_62"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_63"
                      ></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="gdlr-core-title-item-id-14842"
                    >
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3
                          class="gdlr-core-title-item-title gdlr-core-skin-title"
                          id="h3_1dd7_20"
                        >
                          <a routerLink="/annualReport" target="_self"
                            >Annual Report</a
                          >
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                      id="div_1dd7_62"
                    >
                      <div
                        class="gdlr-core-divider-line gdlr-core-skin-divider"
                        id="div_1dd7_63"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper" id="div_1dd7_72">
        <div class="gdlr-core-pbf-background-wrap">
          <div
            class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            id="div_1dd7_73"
            data-parallax-speed="0.2"
          ></div>
        </div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
            id="div_1dd7_74"
            style="cursor: pointer"
            (click)="openPlayer()"
          >
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                id="div_1dd7_75"
              >
                <div
                  class="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                  id="div_1dd7_76"
                >
                  <img
                    src="./../../assets/images/icon-play.png"
                    alt=""
                    width="82"
                    height="82"
                    title="icon-play"
                  />
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
              >
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3
                    class="gdlr-core-title-item-title gdlr-core-skin-title"
                    id="h3_1dd7_23"
                  >
                    Video Tour of SRNMC
                  </h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                id="div_1dd7_77"
              >
                <div class="gdlr-core-text-box-item-content" id="div_1dd7_78">
                  <p>
                    Take a tour in SRNMC and you will find the best institution
                    in the state. The video will take you to every places in
                    this institution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="gdlr-core-pbf-wrapper"
        id="div_1dd7_30"
        data-skin="Column Service"
      >
        <div class="gdlr-core-pbf-background-wrap" id="div_1dd7_31"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div
              class="gdlr-core-pbf-column gdlr-core-column-15 gdlr-core-column-first"
            >
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                    >
                      <div
                        class="gdlr-core-column-service-media gdlr-core-media-image"
                        id="div_1dd7_33"
                      >
                        <img
                          src="./../../assets/images/col-icon-6.png"
                          alt=""
                          width="41"
                          height="41"
                          title="col-icon-1"
                        />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3
                            class="gdlr-core-column-service-title gdlr-core-skin-title"
                            id="h3_1dd7_8"
                          >
                            3500+ Students
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-column gdlr-core-column-15"
              id="gdlr-core-column-5"
            >
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      id="div_1dd7_35"
                    >
                      <div
                        class="gdlr-core-column-service-media gdlr-core-media-image"
                        id="div_1dd7_36"
                      >
                        <img
                          src="./../../assets/images/col-icon-7.png"
                          alt=""
                          width="50"
                          height="50"
                          title="col-icon-2"
                        />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3
                            class="gdlr-core-column-service-title gdlr-core-skin-title"
                            id="h3_1dd7_9"
                          >
                            135+ Faculty Members
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-column gdlr-core-column-15"
              id="gdlr-core-column-6"
            >
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      id="div_1dd7_38"
                    >
                      <div
                        class="gdlr-core-column-service-media gdlr-core-media-image"
                        id="div_1dd7_39"
                      >
                        <img
                          src="./../../assets/images/col-icon-3.png"
                          alt=""
                          width="50"
                          height="50"
                          title="col-icon-3"
                        />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3
                            class="gdlr-core-column-service-title gdlr-core-skin-title"
                            id="h3_1dd7_6"
                          >
                            34+ Courses
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-column gdlr-core-column-15"
              id="gdlr-core-column-7"
            >
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      id="div_1dd7_41"
                    >
                      <div
                        class="gdlr-core-column-service-media gdlr-core-media-image"
                        id="div_1dd7_42"
                      >
                        <img
                          src="./../../assets/images/col-icon-4.png"
                          alt=""
                          width="47"
                          height="47"
                          title="col-icon-4"
                        />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3
                            class="gdlr-core-column-service-title gdlr-core-skin-title"
                            id="h3_1dd7_7"
                          >
                            48.54 Acres of Campus
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider gdlr-core-item-pdlr"
              >
                <div class="gdlr-core-block-item-title-inner clearfix">
                  <h3 id="h3_1dd7_10" class="gdlr-core-block-item-title">
                    Prominent Alumni of SRNMC
                  </h3>
                </div>
                <div
                  class="gdlr-core-flexslider flexslider gdlr-core-js-4"
                  data-type="carousel"
                  data-column="4"
                  data-nav="navigation"
                  data-nav-parent="gdlr-core-personnel-item"
                >
                  <div
                    class="flex-viewport"
                    style="overflow: hidden; position: relative"
                  >
                    <ul class="slides">
                      <li
                        class="gdlr-core-item-mglr flex-with-active-class"
                        *ngFor="let alumni of alumniData"
                      >
                        <div class="gdlr-core-personnel-list clearfix">
                          <div
                            class="gdlr-core-personnel-list-image gdlr-core-media-image gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover"
                          >
                            <img
                              [src]="webService.getAlumniImage(alumni.alumne)"
                              alt=""
                              width="500"
                              height="700"
                              title="Well-groomed stylish young man with bow tie posing on dark background."
                              draggable="false"
                            />
                          </div>
                          <div class="gdlr-core-personnel-list-content-wrap">
                            <h3 style="font-size: 18px; margin-bottom: 3px">
                              {{ alumni.alumne.name }}
                            </h3>
                            <div
                              class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                              style="font-size: 12px"
                            >
                              {{ alumni.alumne.designation }}
                            </div>
                            <div
                              class="gdlr-core-personnel-list-divider gdlr-core-skin-divider"
                            ></div>
                            <div
                              class="gdlr-core-personnel-list-content"
                              style="margin-bottom: 5px"
                            >
                              <p style="margin-bottom: 0px">
                                {{ alumni.alumne.organization }}
                              </p>
                            </div>
                            <div
                              class="gdlr-core-personnel-list-content"
                              style="font-weight: bold; margin-top: 0px"
                            >
                              <p>
                                <span style="color: #3db166"
                                  >{{ alumni.department.name }}
                                </span>
                                <br />
                                <span style="font-weight: normal">
                                  <b> Pass Out Year: </b>
                                  {{ alumni.alumne.passOutYear }}
                                </span>
                              </p>
                            </div>
                            <div class="gdlr-core-personnel-list-social">
                              <div
                                class="gdlr-core-social-network-item gdlr-core-item-pdb gdlr-core-none-align"
                                style="padding-bottom: 0px"
                              >
                                <a
                                  [href]="
                                    webService.transform(
                                      alumni.alumne.linkedInLink
                                    )
                                  "
                                  *ngIf="alumni.alumne.linkedInLink !== ''"
                                  target="_blank"
                                  class="gdlr-core-social-network-icon"
                                  title="google-plus"
                                >
                                  <i class="fa fa-linkedin-square"></i
                                ></a>
                                <a
                                  [href]="
                                    webService.transform(
                                      alumni.alumne.facebookLink
                                    )
                                  "
                                  *ngIf="alumni.alumne.facebookLink !== ''"
                                  target="_blank"
                                  class="gdlr-core-social-network-icon"
                                  title="facebook"
                                >
                                  <i class="fa fa-facebook"></i
                                ></a>
                                <a
                                  [href]="
                                    webService.transform(
                                      alumni.alumne.twitterLink
                                    )
                                  "
                                  *ngIf="alumni.alumne.twitterLink !== ''"
                                  target="_blank"
                                  class="gdlr-core-social-network-icon"
                                  title="twitter"
                                >
                                  <i class="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="gdlr-core-pbf-wrapper"
        id="div_1dd7_30"
        data-skin="Column Service"
      >
        <div class="gdlr-core-pbf-background-wrap" id="div_1dd7_31"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div
              class="gdlr-core-text-box-item-content gdlr-core-center-align"
              id="div_1dd7_78"
            >
              <img
                src="./../../assets/images/logo_full.png"
                width="150"
                height="150"
              />
              <h2 style="font-size: 32px; padding-top: 10px">
                Sri S.Ramasamy Naidu Memorial College (Autonomous)
              </h2>
              <h4 style="font-size: 14px; line-height: 25px">
                (A Co-educational,Autonomous and Telugu Linguistic Minority
                Institution, affiliated to Madurai Kamaraj University)
              </h4>
              <h4 style="font-size: 14px">
                (Re-Accredited with 'A++' Grade by NAAC)
              </h4>
              <h3 style="font-size: 20px">
                Sattur, Virudhunagar (DT), Tamilnadu - 626 203
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-dialog
      header="Video Tour of SRNMC"
      [(visible)]="playerVisible"
      [maximizable]="true"
      [blockScroll]="true"
      [draggable]="false"
      [dismissableMask]="true"
      appendTo="body"
      [modal]="true"
      [baseZIndex]="200"
      [style]="{ width: '80vw' }"
    >
      <div>
        <iframe
          width="100%"
          height="550px"
          src="https://www.youtube.com/embed/fGb9ydyNwIA"
          title="Video Tour of SRNMC"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </p-dialog>

    <p-dialog
      header=""
      [(visible)]="naacGradeVisible"
      [blockScroll]="true"
      [draggable]="false"
      [dismissableMask]="true"
      appendTo="body"
      [modal]="true"
      [baseZIndex]="200"
      [style]="{ width: '55vw' }"
      styleClass="grade-naac"
    >
      <img src="./../../assets//images/add_23_24.png" width="100%" />
      <div style="text-align: center">
        <button class="enquiry-button" (click)="openEnquiryForm()">
          &gt;&gt; Click Here for Admission Enquiry &lt;&lt;
        </button>
      </div>
    </p-dialog>
    <p-dialog
      header=""
      [(visible)]="enquiryVisible"
      [blockScroll]="true"
      [draggable]="false"
      [dismissableMask]="true"
      appendTo="body"
      [modal]="true"
      [baseZIndex]="200"
      [style]="{ width: '55vw' }"
      styleClass="grade-naac"
    >
      <div style="text-align: center">
        <h3>Admission 2023 - 2024 Enquiry</h3>
        <div>
          <form
            [formGroup]="applicationForm"
            (ngSubmit)="onSubmit()"
            *ngIf="applicationForm"
          >
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-column-60">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Full Name *"
                  class="enquiry-input form-control"
                />
                <span
                  *ngIf="
                    applicationForm.controls.name &&
                    applicationForm.controls.name.invalid &&
                    (applicationForm.controls.name.dirty ||
                      applicationForm.controls.name.touched)
                  "
                >
                  <div
                    class="error-msg"
                    *ngIf="applicationForm.controls.name.errors.required"
                  >
                    Please enter your Full Nam
                  </div>
                </span>
              </div>
              <div class="gdlr-core-column-60">
                <input
                  formControlName="mobile"
                  type="text"
                  placeholder="Mobile Number *"
                  class="enquiry-input form-control"
                />
                <span
                  *ngIf="
                    applicationForm.controls.mobile &&
                    applicationForm.controls.mobile.invalid &&
                    (applicationForm.controls.mobile.dirty ||
                      applicationForm.controls.mobile.touched)
                  "
                >
                  <div
                    class="error-msg"
                    *ngIf="applicationForm.controls.mobile.errors.required"
                  >
                    Please enter your Mobile Number
                  </div>
                </span>
                <span
                  *ngIf="
                    applicationForm.controls.mobile &&
                    applicationForm.controls.mobile.invalid &&
                    (applicationForm.controls.mobile.dirty ||
                      applicationForm.controls.mobile.touched)
                  "
                >
                  <div
                    class="error-msg"
                    *ngIf="applicationForm.controls.mobile.errors.pattern"
                  >
                    Please, Enter 10 digit Mobile Number
                  </div>
                </span>
              </div>
              <div class="gdlr-core-column-60">
                <select
                  class="enquiry-input form-control"
                  formControlName="course"
                >
                  <option value="">Select Course *</option>
                  <option value="B.A., History - Aided">
                    B.A., History - Aided
                  </option>
                  <option value="B.A., English - Aided">
                    B.A., English - Aided
                  </option>
                  <option value="B.Com - Aided">B.Com - Aided</option>
                  <option value="B.Sc., Maths - Aided">
                    B.Sc., Maths - Aided
                  </option>
                  <option value="B.Sc., Physics - Aided">
                    B.Sc., Physics - Aided
                  </option>
                  <option value="B.Sc., Chemistry - Aided">
                    B.Sc., Chemistry - Aided
                  </option>
                  <option value="B.Sc., Zoology - Aided">
                    B.Sc., Zoology - Aided
                  </option>
                  <option value="B.Sc., Computer Science - Aided">
                    B.Sc., Computer Science - Aided
                  </option>
                  <option value="M.Com - Aided">M.Com - Aided</option>
                  <option value="M.Sc., Mathematics - Aided">
                    M.Sc., Mathematics - Aided
                  </option>
                  <option value="B.A., Tamil - UnAided">
                    B.A., Tamil - UnAided
                  </option>
                  <option value="B.A., English - UnAided">
                    B.A., English - UnAided
                  </option>
                  <option value="B.Com - UnAided">B.Com - UnAided</option>
                  <option value="B.Com (CA) - UnAided">
                    B.Com.,(CA) - UnAided
                  </option>
                  <option value="B.Com (PA) - UnAided">
                    B.Com.,(PA) - UnAided
                  </option>
                  <option value="B.B.A - UnAided">B.B.A - UnAided</option>
                  <option value="B.Sc., Computer Science - UnAided">
                    B.Sc., Computer Science - UnAided
                  </option>
                  <option value="B.Sc., Maths - UnAided">
                    B.Sc., Maths - UnAided
                  </option>
                  <option value="B.Sc., Nutrition & Dietetics - UnAided">
                    B.Sc., Nutrition & Dietetics - UnAided
                  </option>
                  <option value="B.Sc., Physics - UnAided">
                    B.Sc., Physics - UnAided
                  </option>
                  <option value="B.Sc., Chemistry - UnAided">
                    B.Sc., Chemistry - UnAided
                  </option>
                  <option value="M.A., Tamil - UnAided">
                    M.A., Tamil - UnAided
                  </option>
                  <option value="M.A., English - UnAided">
                    M.A., English - UnAided
                  </option>
                  <option value="M.A., History - UnAided">
                    M.A., History - UnAided
                  </option>
                  <option value="M.Com - UnAided">M.Com - UnAided</option>
                  <option value="M.Sc., Physics - UnAided">
                    M.Sc., Physics - UnAided
                  </option>
                  <option value="M.Sc., Chemistry - UnAided">
                    M.Sc., Chemistry - UnAided
                  </option>
                  <option value="M.Sc., Computer Science - UnAided">
                    M.Sc., Computer Science - UnAided
                  </option>
                </select>
                <span
                  *ngIf="
                    applicationForm.controls.course &&
                    applicationForm.controls.course.invalid &&
                    (applicationForm.controls.course.dirty ||
                      applicationForm.controls.course.touched)
                  "
                >
                  <div
                    class="error-msg"
                    *ngIf="applicationForm.controls.course.errors.required"
                  >
                    Please select the course
                  </div>
                </span>
              </div>
              <div style="margin-top: 15px; text-align: center">
                <re-captcha
                  formControlName="captchaConfirm"
                  siteKey="6LfJuXglAAAAAKKHBcQpq8Ggcjt0q6j2EnPLIK1a"
                ></re-captcha>
              </div>
              <div class="gdlr-core-column-60 gdlr-core-center-align">
                <button
                  [disabled]="!applicationForm.valid"
                  type="submit"
                  class="enquiry-button {{
                    !applicationForm.valid ? 'disabled' : ''
                  }}"
                  style="margin-top: 20px"
                >
                  Submit Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </p-dialog>
  </div>
</div>
