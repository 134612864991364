<app-banner title="Our Hostel" align="left"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper " style="margin: 0px 0px 30px 0px;">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 35px 0px 20px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 27px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">Sri
                    S.Ramasamy Naidu Memorial College Hostel</h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>The college has two separate hostels for girl students and one for boy students. All the three
                    hostels have spacious and well ventilated rooms. The rooms are furnished with ceiling fans and steel
                    cots. Steam cooking is adopted in the hostels. The boys hostel has one volleyball court and the
                    girls hostels have two volleyball courts. A paid phone is kept in the girls hostels enabling
                    the inmates to speak to their family members. </p>
                  <p>The hostels are provided with televisions and newspapers. Hobby-enhancement courses such as
                    typewriting, embroidery, doll making are taught to inmates of the girls hostel. Hostel students
                    use the 12 station multi-gym after college hours also. The hostels are equipped with TVs, reading
                    halls, required number of restrooms with adequate sanitation, incinerators for the disposal of
                    napkins, RO-plant for safe drinking water, browsing centre and a 125 KVA Generator.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Hostel Administration Members</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-line-height gdlr-core-column-extend-left">
              <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr. P. Rajaguru</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Principal & Warden</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr V. Kanagavel</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Deputy Warden</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr R. Rameshkumar</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Dean Student Affairs</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr J. Geethamani</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Resident Warden - Girl's Hostel</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Ms A. Aruna Devi</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Assistant Resident Warden - Girl's Hostel</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr S. Nagaraj</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Resident Warden- Boy's Hostel </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Mr. S. Sridharan	</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Assistant Resident Warden - Boy's Hostel</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Accommodation Availability</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">Boys
                            Hostel </h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          Rooms - 60
                          <br>
                          Capacity - 300
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">Girls
                            Hostel- I </h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          Rooms - 50
                          <br>
                          Capacity - 250
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-20">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " style="margin: 0px -7px 0px 0px;">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-feature-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div class="gdlr-core-feature-box gdlr-core-js gdlr-core-feature-box-type-outer"
                        style="background-color: #3db166 ;border-width: 0px 0px 0px 0px;border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;">
                        <div class="gdlr-core-feature-box-content gdlr-core-sync-height-content">
                          <h3 class="gdlr-core-feature-box-item-title" style="font-size: 16px ;font-weight: 600 ;">Girls
                            Hostel- II </h3>
                        </div>
                        <p class="gdlr-core-column-service-content" style="color: white;">
                          Rooms - 47
                          <br>
                          Capacity - 250
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                  style="border-color: #3db166 ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <th> Acdemic Year</th>
                          <th>Boys Hostel</th>
                          <th>Girls Hostel</th>
                        </tr>
                        <tr>
                          <td>2012-13</td>
                          <td>112</td>
                          <td>464</td>
                        </tr>
                        <tr>
                          <td>2013-14</td>
                          <td>105</td>
                          <td>462</td>
                        </tr>
                        <tr>
                          <td>2014-15</td>
                          <td>78</td>
                          <td>326</td>
                        </tr>
                        <tr>
                          <td>2015-16</td>
                          <td>79</td>
                          <td>283</td>
                        </tr>
                        <tr>
                          <td>2016-17</td>
                          <td>73</td>
                          <td>299</td>
                        </tr>
                        <tr>
                          <td>2017-18</td>
                          <td>99</td>
                          <td>263</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div id="text-23" class="widget widget_text kingster-widget">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Hostel Contact Info</h3>
                    <p>
                      <span style="color: #3db166; font-size: 18px; font-weight: 600;">Dr V.KANAGAVEL</span>
                      <br>
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Deputy Warden</span>
                      <br />
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                      </span></p>
                    <span style="color: #3db166; font-size: 14px; font-weight: 500;">Boy's Hostel</span>
                    <br />
                    <p><span style="font-size: 15px;">+ 91 - 04562 - 261054</span></p>
                    <span style="color: #3db166; font-size: 14px; font-weight: 500;">Girls's Hostel</span>
                    <br />
                    <p><span style="font-size: 15px;">+ 91 - 04562 - 261440</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
