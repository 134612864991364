import { Component, OnInit, ElementRef } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var jQuery: any;
import * as _ from 'lodash';
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  public departmentData: any;
  public departmentNews: any;
  public alumniData:any;
  public departmentCoverImage:any;
  public yearOfEstablishment: any;
  public programmes = [];
  public id: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
    private element: ElementRef,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
   
  }

  loadCommitee() {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getDepartmentProfile(this.id).subscribe((data) => {
      this.departmentData = data;
      this.departmentCoverImage = this.webService.getDepartmentCover(data);
    });
    this.webService.getDepartmentNews(this.id).subscribe((data) => {
      this.departmentNews = data;
      setTimeout(() => {
        this.showNewsScroll();
      }, 1000);
    });
    this.webService.getAlumniByDeptId(this.id).subscribe((data: any) => {
      this.alumniData = _.shuffle(data);
      setTimeout(() =>{
        const gdlr_core_js = jQuery(this.element.nativeElement).find('.gdlr-core-js-5');
        jQuery(this.element.nativeElement).gdlr_core_title_divider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_flexslider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_sly( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_isotope( gdlr_core_js );
      }, 500);
    });
    this.webService.getDepartmentData(26).subscribe((data: any) => {
      this.constructYearOfEstablisment(data);
    });
    this.webService.getDepartmentData(27).subscribe((data: any) => {
      this.constructProgrammesOffered(data);
    });
  }


  constructYearOfEstablisment(resdata: any) {
    const gridData: any = {};
    gridData['title'] = resdata.title;
    gridData['jsonId'] = resdata.formJson.id;
    gridData['maxEntry'] = resdata.formJson.maxEntry;
    gridData['data'] = [];
    const data = _.filter(resdata.formData, {deptId: this.id});
    for (let index = 0; index < data.length; index++) {
      const formData = data[index];
      const form = {
        docs: formData.docs,
      };
      _.extend(form, JSON.parse(formData.formData));
      gridData['data'].push(form);
    }
    let columns = new Map()
    const json = JSON.parse(resdata.formJson.formJSON);
    for (let index1 = 0; index1 < json.components.length; index1++) {
      const component = json.components[index1];
      if(component.input) {
        columns.set(component.key, component.label);
      }
    }
    gridData['columns'] = columns;
    if(gridData.data && gridData.data.length) {
      this.yearOfEstablishment = gridData.data[0];
    }
  }

  constructProgrammesOffered(resdata: any) {
    const gridData: any = {};
    gridData['title'] = resdata.title;
    gridData['jsonId'] = resdata.formJson.id;
    gridData['maxEntry'] = resdata.formJson.maxEntry;
    gridData['data'] = [];
    const data = _.filter(resdata.formData, {deptId: this.id});
    for (let index = 0; index < data.length; index++) {
      const formData = data[index];
      const form = {
        docs: formData.docs,
      };
      _.extend(form, JSON.parse(formData.formData));
      gridData['data'].push(form);
    }
    let columns = new Map()
    const json = JSON.parse(resdata.formJson.formJSON);
    for (let index1 = 0; index1 < json.components.length; index1++) {
      const component = json.components[index1];
      if(component.input) {
        columns.set(component.key, component.label);
      }
    }
    gridData['columns'] = columns;
    if(gridData.data && gridData.data.length) {
      this.programmes = gridData.data;
    }
  }

  openFacultyMembers(id: any, title: any) {
    this.router.navigate(['departmentProfiles', id, title])
  }
  
  departmentDocs(type: any) {
    this.router.navigate(['departmentDocs', this.departmentData.id, type])
  }

  showNewsScroll() {
    jQuery('#newsScroll').easyTicker({
      direction: 'up',
      visible: 5,
      easing: 'swing'
    });
  }
  
}
