<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Research Guides</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Research Guides</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('poongothai')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr R.
                          Poongothai </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Tamil
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ramanathan')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr P.
                          Ramanathan </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Tamil
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('dhanuja')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr V.
                          Dhanuja </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Tamil
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('mkavitha')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr M.
                          Kavitha </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Tamil
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ajantha')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr B.
                          Ajantha </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of English
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('padmashini')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr A.
                          Padmashini </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of English
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('marithai')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr.S. Marithai </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of English
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ganeshram')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr S.
                          Ganeshram </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of History
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('brindaumamaheswari')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr S.
                          Brinda Uma Maheswari </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of History
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ramanujam')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr R.
                          Ramanujam </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of History
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('revathi')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr R.
                          Revathi </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of History
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('thiripurasundari')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr V.
                          Thiripurasundari
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Mathematics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('sridevi')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr R.
                          Sridevi
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Mathematics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('meeradevi')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr B.
                          Meeradevi
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Mathematics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('nirmaladevi')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr S. P.
                          Nirmaladevi
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Mathematics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('thamilmaran')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr P.
                          Thamilmaran
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Physics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('sundaravenkatesh')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr P.
                          Sundara Venkatesh
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Physics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('tkavitha')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr T.
                          Kavitha
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Chemistry
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ravichandran')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr B.
                          Ravichandran
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Zoology
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('kaliraj')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr P.
                          Kaliraj
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Zoology
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('kkrishnaveni')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr K.
                          Krishnaveni
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Computer Science
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('arunesh')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr K.
                          Arunesh
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Computer Science
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ranichitra')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr A
                          Ranichitra
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Computer Science
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('kanagavel')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr V.
                          Kanagavel
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Commerce
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('muthumani')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr A.
                          Muthumani
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Commerce
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('navaneethakrishnan')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr K.
                          Navaneethakrishnan
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Commerce
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('vijayaragavan')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr T.
                          Vijayaragavan
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Commerce
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('rejina')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr A.
                          Rejina
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Commerce
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('jayanthi')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr S.
                          Jayanthi
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of English
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('geetha')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr K. V.
                          Geetha
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Mathematics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('nithya')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr S.
                          Nithya
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Physics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('ksk')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr K.
                          Saravana Kumar
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Physics
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                  <div class="gdlr-core-personnel-list clearfix">
                    <div class="gdlr-core-personnel-list-content-wrap">
                      <a (click)="navigate('latha')" style="cursor: pointer;" class="user-link">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">Dr.V. Latha
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          Department of Chemistry
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                <div class="gdlr-core-personnel-list clearfix">
                  <div class="gdlr-core-personnel-list-content-wrap">
                    <a (click)="navigate('fathimafarzana')" style="cursor: pointer;" class="user-link">
                      <h3 class="gdlr-core-personnel-list-title"
                        style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr.U. Fathima Farzana </h3>
                      <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                        Department of English
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                <div class="gdlr-core-personnel-list clearfix">
                  <div class="gdlr-core-personnel-list-content-wrap">
                    <a (click)="navigate('sudhadevi')" style="cursor: pointer;" class="user-link">
                      <h3 class="gdlr-core-personnel-list-title"
                        style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;"> Dr.M. Sudhadevi </h3>
                      <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                        style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                        Department of English
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>
