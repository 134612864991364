<app-banner title="Placement Cell" align="left"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper " style="margin: 0px 0px 30px 0px;">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 35px 0px 20px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 27px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">Sri
                    S.Ramasamy Naidu Memorial College | Placement Cell</h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>The college has a Placement Cell with faculty from various departments as members. It functions in
                    close coordination with the career guidance cell. The vision of the Cell is to impart essential
                    training to meet the expectation of industry and to help maximum number of students to get placed.
                  </p>
                  <p>The Placement Cell maintains good rapport with various concerns that offer placement to the
                    students. It arranges campus interviews and sends students to various off campus interviews.
                    Further, the Cell conducts personality development classes in collaboration with the Career Guidance
                    Cell.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Placement Cell Members</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-line-height gdlr-core-column-extend-left">
              <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;"> Dr. J. Geethamani </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Placement Officer</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Ms. P. Lakshmi Priya</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Deputy
                            Placement Officer
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">Dr. S. Arumuga Devi</h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">Deputy
                            Placement Officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                  style="border-color: #3db166 ;border-bottom-width: 3px ;"></div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <th> Academic Year</th>
                          <th>No of Students Placed</th>
                        </tr>
                        <tr>
                          <td>2012-13</td>
                          <td>37</td>
                        </tr>
                        <tr>
                          <td>2013-14</td>
                          <td>33</td>
                        </tr>
                        <tr>
                          <td>2014-15</td>
                          <td>21</td>
                        </tr>
                        <tr>
                          <td>2015-16</td>
                          <td>194</td>
                        </tr>
                        <tr>
                          <td>2016-17</td>
                          <td>170</td>
                        </tr>
                        <tr>
                          <td>2017-18</td>
                          <td>93</td>
                        </tr>
                        <tr>
                          <td>2018-19</td>
                          <td>376</td>
                        </tr>
                        <tr>
                          <td>2019-20</td>
                          <td>318</td>
                        </tr>
                        <tr>
                          <td>2020-21</td>
                          <td>342</td>
                        </tr>
                        <tr>
                          <td>2021-22</td>
                          <td>194</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div id="text-23" class="widget widget_text kingster-widget">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Contact Info</h3>
                    <p>
                      <span style="color: #3db166; font-size: 18px; font-weight: 600;"> Dr. J. Geethamani  </span>
                      <br>
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">The Placement Officer</span>
                      <br />
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                      </span></p>
                    <span style="color: #3db166; font-size: 14px; font-weight: 500;">Mobile</span>
                    <br />
                    <p><span style="font-size: 15px;">+91 - 9865042019 </span></p>
                    <span style="color: #3db166; font-size: 14px; font-weight: 500;">Email</span>
                    <br />
                    <p><span style="font-size: 15px;">placement@srnmcollege.ac.in</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
