import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-research-seed-money-projects',
  templateUrl: './research-seed-money-projects.component.html',
  styleUrls: ['./research-seed-money-projects.component.scss']
})
export class ResearchSeedMoneyProjectsComponent implements OnInit {

  public today: any;
  public projectData: any;
  public seedMoneyOnGoing: any;
  public seedMoneyCompleted: any;
  constructor(
    private element: ElementRef,
    public webService: WebServiceService,
  ) { }

  ngOnInit(): void {
    this.webService.getCurrentDate().subscribe((data) => {
      this.today = moment(data);
      this.webService.getSeedMoneyProjects().subscribe((projects) => {
        this.constructGrid(projects);
      });
    });
  }

  constructGrid(resdata: any) {
    const gridData: any = {};
    gridData['title'] = resdata.title;
    gridData['jsonId'] = resdata.formJson.id;
    gridData['maxEntry'] = resdata.formJson.maxEntry;
    gridData['data'] = [];
    const data = resdata.formData;
    for (let index = 0; index < data.length; index++) {
      const formData = data[index];
      const form = {
        docs: formData.docs,
        staffName: formData.staff.title + ' ' + formData.staff.lastName + ' ' + formData.staff.firstName + ' ' + formData.staff.qualification,
        staffDesignation: formData.staff.designation
      };
      _.extend(form, JSON.parse(formData.formData));
      gridData['data'].push(form);
    }
    let columns = new Map()
    columns.set('staffName', 'Staff Name');
    columns.set('staffDesignation', 'Staff Designation');
    const json = JSON.parse(resdata.formJson.formJSON);
    for (let index1 = 0; index1 < json.components.length; index1++) {
      const component = json.components[index1];
      if(component.input) {
        columns.set(component.key, component.label);
      }
    }
    gridData['columns'] = columns;
    const seedMoneyOnGoing = [];
    const seedMoneyCompleted = [];
    for (let j = 0; j < gridData.data.length; j++) {
      let projectData = gridData.data[j];
      const parsedDate: any = moment(projectData.start1, 'DD/MM/YYYY');
      if (parsedDate && parsedDate.isValid) {
        var isafter = this.today.isAfter(parsedDate);
        if (!isafter) {
          seedMoneyOnGoing.push(projectData);
        } else {
          seedMoneyCompleted.push(projectData);
        }
      } else {
        seedMoneyOnGoing.push(projectData);
      }
    }
    this.seedMoneyOnGoing = _.orderBy(seedMoneyOnGoing, (o: any) => {
      if (o.start1) {
        const parsedDate: any = moment(o.start1, 'DD/MM/YYYY');
        return moment(parsedDate).format('YYYYMMDD');
      } else {
        return moment(new Date()).format('YYYYMMDD');
      }
    }, ['desc']);
    this.seedMoneyCompleted = _.orderBy(seedMoneyCompleted, (o: any) => {
      if (o.start1) {
        const parsedDate: any = moment(o.start1, 'DD/MM/YYYY');
        return moment(parsedDate).format('YYYYMMDD');
      } else {
        return moment(new Date()).format('YYYYMMDD');
      }
    }, ['desc']);
  }

  formatDate(date: any) {
    if(date) {
      const parsed = moment(date, 'DD/MM/YYYY')
      return moment(parsed).format('Do MMM YYYY')
    } else {
      return '';
    }
  }

}
