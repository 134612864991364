import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-iqac-best-practices',
  templateUrl: './iqac-best-practices.component.html',
  styleUrls: ['./iqac-best-practices.component.scss']
})
export class IqacBestPracticesComponent implements OnInit, AfterViewInit {

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() =>{
      const gdlr_core_js = jQuery(this.element.nativeElement).find('.gdlr-core-js-tab');
      jQuery(this.element.nativeElement).gdlr_core_tab( gdlr_core_js );
    }, 500);
  }

}
