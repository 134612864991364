<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">DBT</div>
      <h1 class="kingster-page-title">Equipment Purchased</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ; min-height: 750px; height: 750px;">
                <ng2-pdfjs-viewer pdfSrc="https://storage.srnmcollege.ac.in/srnmcweb/dbt/dbt_equipment_details.pdf" [openFile]="false" [viewBookmark]="false"></ng2-pdfjs-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-dbt-menu></app-dbt-menu>
  </div>
</div>