import { Component, OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { WebServiceService } from './../web-service.service';
import { Router} from '@angular/router';
@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss']
})
export class InfrastructureComponent implements OnInit {

  public infaAlbums: Array<any> = [];
  constructor(
    private element: ElementRef,
    public webService: WebServiceService,
    public router: Router,

  ) { }

  ngOnInit(): void {
    const infaAlbums: any= [];
    this.webService.getInfraAlbums().subscribe((data) => {
      data.forEach((image: any) => {
        if(image && image.albumImages && image.albumImages.length) {
          infaAlbums.push({
            id: image.id,
            coverImage: this.getImage(image.albumImages),
            title: image.title
          });
        }
      });
      this.infaAlbums = infaAlbums;
    });
  }

  getImage(images: Array<any>): string {
    const item = images[Math.floor(Math.random() * images.length)];
    return this.webService.getBucketURL(item);
  }

  openAlubm(id: any) {
    this.router.navigate(['/albums', id])
  }
}
