import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ict-tools',
  templateUrl: './ict-tools.component.html',
  styleUrls: ['./ict-tools.component.scss']
})
export class IctToolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
