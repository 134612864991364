<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">IQAC</div>
      <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Best Practices </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-tab-item gdlr-core-js-tab gdlr-core-item-pdb gdlr-core-left-align gdlr-core-tab-style1-horizontal gdlr-core-item-pdlr">
                <div class="gdlr-core-tab-item-wrap">
                  <div class="gdlr-core-tab-item-title-wrap clearfix gdlr-core-title-font">
                    <div class="gdlr-core-tab-item-title gdlr-core-active" data-tab-id="1">Best Practice I</div>
                    <div class="gdlr-core-tab-item-title" data-tab-id="2">Best Practice II</div>
                  </div>
                  <div class="gdlr-core-tab-item-content-wrap clearfix">
                    <div class="gdlr-core-tab-item-content  gdlr-core-active" data-tab-id="1"
                      style="background-color: rgb(255, 255, 255); ">
                      <div
                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                        <div class="gdlr-core-title-item-title-wrap ">
                          <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                            style="font-size: 16px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">Title of
                            the Practice: Green Campus Initiatives, Eco-Friendliness and Infrastructure
                            Augmentation..<span
                              class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                      <!-- Tab1 - start -->
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Objectives of the Practice
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div
                        class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                        style="padding-bottom: 25px ; text-align: justify;">
                        <ul>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To enhance the teaching and learning environment through clean
                                and green campus.
                              </span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To undertake a massive tree plantation programme.
                              </span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To avoid the use of polythene and plastic substances.
                                campus.
                              </span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To install Rain Water Harvesting structures.
                                activities.</span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To manage waste water effectively.</span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To generate maximum non-conventional energy.</span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">To add infrastructure to strengthen teaching–learning
                                processes.</span></div>
                          </li>
                          <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                              class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                                class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                                style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                            <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                                style="font-size: 16px ;">Digitization initiatives.</span></div>
                          </li>
                        </ul>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              The Context
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The College is located in Sattur, a drought prone area in
                              Virudhunagar District in Tamil Nadu. Radiation of sunlight had to be curbed to reduce heat
                              in the campus. Green campus initiatives and Swachha Scheme had to be implemented. Water
                              scarcity is a major problem in the whole district. For the smooth functioning of the
                              Institution, water requirement of the Institution had to be met. To reduce the exorbitant
                              cost of electric power, solar and wind energy had to be tapped. In order to facilitate
                              teaching and learning processes, infrastructural addition was also necessary. Execution of
                              the plans in stages with continuous funding by the Management, the Philanthropists and the
                              Alumni have turned the Institution into an Eco-friendly Green Campus with all amenities in
                              the Golden Jubilee Year – 2021.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              The Practice
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">A massive tree plantation programme was undertaken to
                              convert the College into a green campus. Thousands of neem and other trees were planted in
                              the College excluding the building and pathway areas. The entire campus is covered by
                              trees and plants. The flora and the fauna abound and birds visit the campus in large
                              numbers for hibernation and reproduction. The Gardener takes care of the trees and plants.
                              Rain Water Harvesting and Management was given next priority to tide over water scarcity
                              on the campus. “Little drops make an ocean” is an adage. The roads in the Campus are laid
                              in such a way that they slope towards the percolation pond on the eastern side. Rain water
                              from the entire Campus area including the building roofs flow automatically into the pond.
                              This measure ensures cent per cent rain water harvesting. A ban was imposed on the use of
                              polythene and plastic substances. The Campus is kept tidy and clean through upkeep
                              activities. Paver block roads were laid in the entire campus. TNSCST-sponsored Solar and
                              Wind Power generation Projects were implemented. Wind power generation is a new venture
                              introduced by the Institution in Sattur area. Bio Gas is produced from hostel wastes to
                              reduce expenditure on Liquified Petroleum Gas. Augmentation of physical infrastructure was
                              necessitated by the expansion in the number of programmes. Additionally, seven Classrooms,
                              Air-conditioned Seminar Halls, Guest House and Conference Hall with state-of-the-art
                              architecture were built. The hostel buildings, the Office and the Principal’s Chamber have
                              been renovated. Classrooms, Departments, Library, the Office of the Controller of
                              Examinations and the College Office have been digitized.</p>
                          </div>
                        </div>
                      </div>

                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Evidence of Success
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The College is lush green with trees spread over 48.54 acres
                              of land. The greenery in the campus provides shade and oxygen. Radiation of heat rays is
                              reduced to the maximum level. The trees attract birds. The campus has rich scope for
                              biodiversity. Further spouting of branches of leaves will render the campus still more
                              cool and conducive for teaching-learning processes. The cleanliness programmes have
                              rendered the campus eco-friendly. Paver block roads interconnect all the buildings and add
                              aesthetic beauty to the campus. The pond filled with harvested rain water has become a
                              perennial source of water for the students, the staff and the hostellers. Rain water is
                              harvested fully and managed ably. There is no water scarcity on the campus even during hot
                              summer seasons. The Institution is self-reliant for electric power requirement. The Solar
                              (125 KVA) and Wind (10 KVA) electricity generated meets the entire power requirement and
                              the excess power is shared with TNEB on commercial basis. The additional infrastructural
                              facilities provide excellent ambience for regular teaching, learning and the conduct of
                              Seminars, Conferences and Workshops. Digitization initiatives have toned up teaching,
                              Departmental administration, Library Management, the Examination Processes and the Office
                              Management.</p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Problems Encountered and Resources Required
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The rich vegetation on the campus requires careful watering
                              and manuring periodically. Maintaining the greenery especially during the vacations is
                              challenging. Seasonal virus infections stunt the growth of the vegetation. Irregular
                              downpour reduces rain water savings in the pond. To fulfil the Vision Golden Jubilee, the
                              College Management has incurred huge expenditure on eco-centric initiatives,
                              infrastructure and digitization. There is death of funds for implementing Waste Management
                              System with Recycling Units of Solid and Liquid Wastes. Grant facility is desirable for
                              executing the plans.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Notes (Optional)
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The Institution has become a renowned seat of learning with
                              conducive atmosphere for quality education and research. Shifts in temperatures and
                              weather patterns do not affect the students and the staff members. Implementation of the
                              SWACHHA schemes render the Campus a healthy place. Infrastructure and digitization have
                              enriched the quality of teaching, examination system and administrative efficiency.
                              Aspirants for higher education turned to the Institution as their destination.
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Tab1 - end -->
                    </div>
                    <div class="gdlr-core-tab-item-content " data-tab-id="2"
                      style="background-color: rgb(255, 255, 255); ">
                      <div
                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                        <div class="gdlr-core-title-item-title-wrap ">
                          <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                            style="font-size: 16px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">Title of the Practice : Institutional Social Service Engrossment 
                            <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                          </h3>
                        </div>
                      </div>
                      <!-- Tab2 - start -->
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Objectives of the Practice
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The Institution is supported with funds from the stakeholders, State and Central Government Agencies and Non-Governmental Agencies. So, it reciprocates to society through its committed social welfare programmes. The object of the practice is to conduct social service programmes through Quality Cells / NCC / NSS / YRC / Rotaract Club / RRC Departments to ameliorate the sufferings of the poor and the illiterate living in and around Sattur through Camps, Festival, Awareness Programmes, Rallies etc.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Context
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">Most of the Inhabitants in Sattur and its vicinity are poor
                              and illiterate labourers working in Pyro tech and Match industries. The broad areas
                              identified for social uplift related to health, environment and education. In the wake of
                              COVID pandemic, the conversion of the Boys’ and Girls’ Hostels into a COVID CENTRE was
                              necessary to quarantine COVID Positive patients and treat them, if necessary. Free
                              Paediatric Heart Screening, Eye Camp, Blood Donation and <b>SWACCHA</b> programmes were
                              necessary. The masses required clarifications on nutritive food health and well-being.
                              Free medical help was necessary. Danger of the deadly diseases had to be spread.
                              Eco-friendly environment consciousness had to be created. <b>Popularisation of Science</b>
                              among
                              the Schoolgoers was necessary. Womenfolk had to be trained in writing. <b>Self Help
                                Group</b>
                              Women require knowledge in operating computers. The target beneficiaries of the service
                              included children, adults, old people and schoolgoers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              The Practice
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The Institution supported the <b>Health Department</b> in
                              the fight
                              against <b>COVID</b>. As <b>COVID Centre</b> facilities were given for quarantine,
                              treatment and
                              vaccination. Vaccination Camp was also conducted and details entered. The Institution in
                              association with <b>Apollo Children’s Hospital, Chennai</b> conducted a Free Paediatric
                              Heart
                              Screening Camp. 24 day to 17 year old children from five Divisions participated. Out of
                              179 children, 35 needed heart surgery after 2D, Echo and Dopper Examinations. Free surgery
                              and transport facilities were arranged. The Institution facilitated an Eye Camp in
                              collaboration with <b>Aravind Eye Hospital, Tirunelveli</b> at Kallamanaickerpatti
                              Village.
                              Ophthalmologists tested visual acuity and offered medical guidance. Rotaract Club in
                              association with <b>Aravind Eye Hospital, Tirunelveli</b> camped at Subramaniyapuram,
                              Thayilpatti
                              and conducted free vision check up for 100 villagers. Patients requiring cataract
                              operations were referred to eye hospital. Free spectacles were distributed to the needy.
                              Eye camp was conducted at Nadar Primary School, Elayirampannai. Doctors and Paramedics
                              screened the patients. Cataract patients were referred to Aravind Eye Hospital for free
                              operations. Blood Donation Camps were conducted.

                            </p>
                            <p style="margin-bottom: 15px;">The villagers were given many awareness programmes: SWACCHA
                              programmes at Government Hospital, Uppathur, Vinayaga Temple, Lort Siva Temple and Perumal
                              Temple (Sattur). Awareness programmes were conducted on Breast Feeding at Vembakottai,
                              Menstrual Hygiene at Thayilpatti. Peptic Ulcer at Melapatti (Kovilpatti), Anaemia at
                              Manthithoppu (Kovilpatti), Corona at Umayathalaivanpatti (Thiruvengadam), Tuberculosis at
                              Kanjampatti (Sattur).

                            </p>
                            <p style="margin-bottom: 15px;">As part of the Green initiative, trees were planted at
                              Primary School in Othayal. Tree plantation programme was held at Nadu Soorangudi village.
                              A Rally was conducted in Sattur for removal of Seemai Karuvelam Trees (Prosopisjuliflora).

                            </p>
                            <p style="margin-bottom: 15px;"><b>TNSCST and NCSTC – Sponsored</b> programme on
                              <b>Popularization of
                                Science Activities</b> was conducted at the College Science Laboratories to motivate
                              Higher
                              Secondary Students to learn science programmes. The Departments of Physics and Chemistry
                              drew students mostly from Government Schools in Nalli, Melagandhi Nagar, Maharajapuram,
                              Sevalpatti, Subbiahpuram, O.Mettupatti to their laboratories and conducted practical
                              training classes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Evidence of Success
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">The success stories of the practice give encouragement,
                              satisfaction and happiness. <b>Virudhunagar District Collector</b> appreciated the
                              Institutional
                              <b>COVID 19 Centre</b> at the Independence Day Celebrations. 35 poor children received
                              heart
                              surgery free of cost at <b>Apollo Children’s Hospital Chennai</b>. Many Villagers were
                              referred
                              for cataract operations. Tamil Medium Higher Secondary students were motivated to do
                              science programmes in their UG. Elders in villages learnt the importance of hygiene.
                              Regular practice of yoga promoted their spiritual and physical well- being. Wearing
                              Helmets and observance of traffic rules have reduced road accidents and loss of lives. The
                              elders were reverted to traditional food eschewing fast food. Villagers understood the
                              evil effects of karuvelam trees around them. The myths related to blood donation were
                              exploded and villagers became potential blood donors in cases of emergency. The tree
                              plantation programmes and avoidance of plastic materials have added significantly to green
                              environment. SHG Women learnt to operate computers. Illiterate ladies sign instead of
                              affixing their thumb impressions. Government College Students in Sattur happily used the
                              Institutional Laboratory facility for their University Practical Examination
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Problems Encountered and Resources Required
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">Conduct of camps in villages surrounding Sattur posed
                              challenges. The organizers made pre-visits to the respective villages to mobilize support
                              from local administrative bodies and the public to arrange advertisement. Arranging
                              transport facilities for faculty members and student volunteers also posed difficulties.
                              Medical teams visiting Eye camps, hospitality arrangement for the beneficiaries, supply of
                              free spectacles, purchase of miscellaneous items involve huge expenditure. Grant facility
                              under these heads is desirable.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                          style="padding-bottom: 20px ;">
                          <div class="gdlr-core-title-item-title-wrap clearfix">
                            <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                              style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                              Notes (Optional)
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="gdlr-core-pbf-element">
                        <div
                          class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                          <div class="gdlr-core-text-box-item-content"
                            style="font-size: 16px ;text-transform: none ; text-align: justify;">
                            <p style="margin-bottom: 15px;">Institutional Social Service Engrossment makes the
                              Institution – Society bondage intimate and strong. The beneficiaries become well-wishers
                              and they support the Institution by admitting their children. They are ambassadors
                              spreading salient features about the Institution to the public. The Institution owes its
                              popularity partly to the favourable gesture of the beneficiaries through its Institutional
                              social service. Institutions would do well to administer similar measures for their own
                              benefit.
                            </p>
                            <img src="assets/images/best_practice_2.svg" style="width: 100%;" />
                          </div>
                        </div>
                      </div>
                      <!-- Tab2 - end -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-iqac-menu></app-iqac-menu>
  </div>
</div>