<div class="gdlr-core-pbf-wrapper hp1-col-services" data-skin="Blue Title" id="gdlr-core-wrapper-1">
  <div class="gdlr-core-pbf-background-wrap"> </div>
  <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
    <div class="gdlr-core-pbf-wrapper-container clearfix">
      <div class=" gdlr-core-pbf-wrapper-container-inner gdlr-core-item-mglr clearfix" id="div_1dd7_0">
        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-left" id="div_1dd7_64"
          data-sync-height="height-3" data-sync-height-center>
          <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                style="padding-left: 15px;">
                <div class="gdlr-core-text-box-item-content" id="div_1dd7_66">
                  <div class="gdlr-core-twitter-item gdlr-core-item-pdb" id="div_1dd7_67">
                    <div class="gdlr-core-block-item-title-nav ">
                      <div class="gdlr-core-flexslider-nav gdlr-core-plain-style gdlr-core-block-center">
                      </div>
                    </div>
                    <div class="gdlr-core-twitter-content">
                      <div class="gdlr-core-flexslider flexslider gdlr-core-js-imp " data-type="carousel" data-column="1"
                        data-nav="navigation" data-nav-parent="gdlr-core-twitter-item" data-duration="1000">
                        <h6 style="color: red;"> Important Announcement
                        </h6>
                        <ul class="slides" id="ul_1dd7_0" *ngIf="annocements && annocements.length">
                          <li class="gdlr-core-item-mglr" *ngFor="let news of annocements">
                            <div class="gdlr-core-twitter-item-list" *ngIf="news">
                              <span class="gdlr-core-twitter-item-list-content">
                                <a *ngIf="news.contentType === 'EXTERNAL_LINK'" target="_blank" [href]="news.externalURL" >{{news.title}}</a>
                                <a *ngIf="news.contentType !== 'EXTERNAL_LINK'" routerLink="/news/{{news.id}}" >{{news.title}}</a>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
