import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dbt-programmes',
  templateUrl: './dbt-programmes.component.html',
  styleUrls: ['./dbt-programmes.component.scss']
})
export class DBTProgrammesComponent implements OnInit {


  public common = [
    {
      name: "Faculty Induction Programme",
      date: "12 March 2022",
      beneficiaries: "59",
      link: "https://srnmcollege.ac.in"
    }
  ];


  public physics = [
    {
      "name": "National Science Day – An Inter-Collegiate Meet 2022 in Collaboration with Tamil Nadu State Council for Science and Technology, Chennai",
      "date": "28 February 2022",
      "beneficiaries": 95
    },
    {
      "name": "India@75: Azadi Ka Amrit Mahotsav Programme\nin collaboration with Indian Association for Radiation Protection (IARP- Kalpakkam) &\nIndira Gandhi Centre for Atomic Research Kalpakkam",
      "date": "21 & 22 March 2022",
      "beneficiaries": 1975
    },
    {
      "name": "Field Visit – Bharathidasan University, Tiruchirappalli",
      "date": "08 April 2022",
      "beneficiaries": 42
    },
    {
      "name": "Two days Workshop on Characterization Techniques",
      "date": "22 & 23 April 2022",
      "beneficiaries": 108
    },
    {
      "name": "Training on the Construction of Power Supplies",
      "date": "04 June 2022",
      "beneficiaries": 41
    },
    {
      "name": "Intra-Departmental Competition",
      "date": "15 June 2022",
      "beneficiaries": 147
    }
  ];

  public nandD = [
    
    {
      "name": "Two-day’s workshop on Health and well-being",
      "date": "7&8 April 2022",
      "beneficiaries": 100
    },
    
    {
      "name": "Field Visit – food museum & Indian institute of food processing technology- Thanjavur",
      "date": "29-04-2022",
      "beneficiaries": 56
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }
  

}
