<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <h1 class="kingster-page-title">Deans</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Deans and Officials </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. A. Ranichitra, </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Computer Science
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Academic Affairs
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Latha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Chemistry
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Jayanthi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Arts
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Alwarnayaki </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Commerce
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. T. Kavitha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Chemistry
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Science
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. R. Revathy </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Maths
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Lt. Dr. B. Ajantha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Internal Affairs & Extension Activities
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Abirami </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Maths
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Nagaraj </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in History
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. B. Ravichandran </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Zoology
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Research & Development
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. A. Padmashini </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. R. Rameshkumar </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Director of Physical Education
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Student Affairs
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. R. Revathi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in History
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Saravanakumar </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Maths
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. A. Muthumani </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Associate Prof. of Commerce
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Women Empowerment, Protection & Grievances Redressal
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. M. Sakthi Saradha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Commerce
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. J. Geethamani </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Economics
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Career Counselling, Guidance Development & Placement
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Ms. P. Lakshmi Priya </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Economics
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. K. Thilaga </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. In Physics
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. K. Saravana Kumar </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Physics
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Learning & Educational Technologies & e-Governance
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Lakshmanaperumal </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Physics
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. M. Kavitha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Tamil
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Fine Arts & Culture
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Ms. M. Akshaya </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. U. Fathima Farzana </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Competency Enrichment Programmes
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. K. Buvaneswari </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Chemistry
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. S. Marithai </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. M. Sudhadevi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in English
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Coordinator of Media, Publicity & Advertising
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Sridevi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Tamil
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Preetha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Comp. Science
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Dean of Skill Development
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. C. Meenakshi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Asst. Prof. in Maths
                          </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Deputy Dean
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Grievance Redressal Committee  </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Rajaguru </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Principal & Chairman
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. R. Rameshkumar, Dean of Student Affairs </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Mr. G. Seenivasan, Joint Secretary </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Managing Committee
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. B. Ashok Kumar </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            University Nominee
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. D.K. Nathan, Controller of Examinations </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Member
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Mr. N. Surendiran, M.Com., B.L., Advocate </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Member
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Kanagavel, DeputyWarden </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Member
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Deputy Superintendent of Police </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Police
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Revenue Divisional Officer </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Revenue
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Mr. D. Janakiraman </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Reporter
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. Guru Jeganathan, Local Society </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Heads of All the Departments </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Members
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Mr. P. Gunasekaran </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Office Superintendent
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Mr. R. Rajeshkanna </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            SG Assistant
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            A. Mohana Devi, III B.Sc. N & D </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Resident Student Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            P. Sivaranjini, II M.Sc. CS </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Resident Student Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            L. Dhanalakshmi, II M.Com. </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Non-Resident Student Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            M. Keerthi Renuka, I M.Com. </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Non-Resident Student Member
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            N. Vijayakumar, III B.Com. (PA) </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 14px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ; margin-bottom: 0px;">
                            Non-Resident Student Member
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
