import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iqac-aqar-report',
  templateUrl: './iqac-aqar-report.component.html',
  styleUrls: ['./iqac-aqar-report.component.scss']
})
export class IqacAqarReportComponent implements OnInit {

  public reports: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
  }

  loadCommitee() {
    this.webService.getAQARReports().subscribe((data) => {
        this.reports = data;
    });
  }
}
