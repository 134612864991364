import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-aqar-17-18',
  templateUrl: './iqac-aqar-17-18.component.html',
  styleUrls: ['./iqac-aqar-17-18.component.scss']
})
export class IQACAQAR1718Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
