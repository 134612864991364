<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <h1 class="kingster-page-title">Life skills ( Yoga, Physical Fitness, Health and Hygiene)</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="kingster-content-container kingster-container">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper ">
          <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                  style="padding-bottom: 45px ;">
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/5.1.3/Life_Skills_2016-17.pdf" title="Life skills 2016-2017"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/5.1.3/Life_Skills_2017-18.pdf" title="Life skills 2017-2018"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/5.1.3/Life_Skills_2018-19.pdf" title="Life skills 2018-2019"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/5.1.3/Life_Skills_2019-20.pdf" title="Life skills 2019-2020"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/5.1.3/Life_Skills_2020-21.pdf" title="Life skills 2020-2021"></app-file-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  