import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.scss']
})
export class ExamResultComponent implements OnInit {

  public resultData: any;
  public viewResult: boolean = false;
  public showError: boolean = false;
  public documentVisible: boolean = false;
  public resultContent: any;
  public resultCol: any;
  public regNo: string = '';
  @ViewChild('resultHTMLContent', { read: ViewContainerRef }) resultHTMLContent: any;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
  }

  ngOnInit(): void {
    const resultCol: any = this.route.snapshot.paramMap.get('resCol');
    this.resultCol = resultCol;
    this.webService.checkPublishedResult(this.resultCol).subscribe((data: any) => {
      this.resultData = data;
      if(this.resultData.resultCol !== this.resultCol) {
        this.router.navigate(['examResults']);
      }
    }, () => {
      this.router.navigate(['examResults']);
    });
  }

  onSubmit() {
    this.showError = false;
    if(this.regNo && this.regNo !== '') {
      this.webService.checkResult(this.resultCol, this.regNo).subscribe((data: any) => {
        if(data && data.length) {
          var orderedData = _.orderBy(data, ['semester_cd','subject_order'], ['desc', 'asc']);
          this.resultContent = orderedData;
          this.viewResult = true;
          this.showError = false;
        } else {
          this.showError = true;
        }
      }, () => {
        this.showError = true;
      });
    } else {
      this.showError = true;
    }
  }

  close() {
    this.regNo = '';
    this.showError = false;
    this.viewResult = false;
  }
  

  downloadResult() {
    const pdfContent = this.resultHTMLContent.element.nativeElement.innerHTML;
    const linkSTR = `<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">`;
    const htmlSTr = linkSTR + pdfContent
    this.webService.downloadPDFFromHTML({
      "htmlString": htmlSTr,
      "fileName": "exam_result_" + this.regNo
    }).subscribe(res => {
      this.pdfViewerOnDemand.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
      this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/reresh pdf
      this.documentVisible = true;
    });
  }
  
}
