import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-research-projects',
  templateUrl: './research-projects.component.html',
  styleUrls: ['./research-projects.component.scss']
})
export class ResearchProjectsComponent implements OnInit {

  public today: any;
  public projectData: any;
  public majorOngoing: any;
  public majorCompleted: any;
  public minorOnGoing: any;
  public minorCompleted: any;
  public studentOnGoing: any;
  public studentCompleted: any;
  constructor(
    private element: ElementRef,
    public webService: WebServiceService,
  ) { }

  ngOnInit(): void {
    this.webService.getCurrentDate().subscribe((data) => {
      this.today = moment(data);
      this.webService.getResearchProjects().subscribe((projects) => {
        const majorOngoing = [];
        const majorCompleted = [];
        const minorOnGoing = [];
        const minorCompleted = [];
        const studentCompleted = [];
        const studentOnGoing = [];
        for (let i = 0; i < projects.length; i++) {
          const staff = projects[i];
          const staffDetails = {
            staffName: staff.staff.title + " " + staff.staff.lastName + " " + staff.staff.firstName + " " + staff.staff.qualification,
            staffDesignation: staff.staff.designation,
          };
          for (let j = 0; j < staff.projects.length; j++) {
            let projectData = staff.projects[j];
            projectData = _.extend(projectData, staffDetails);
            if (projectData.projectType === 'Major' || projectData.projectType === 'UGC Major') {
              if (projectData.toDate && moment(projectData.toDate).isValid) {
                var isafter = this.today.isAfter(projectData.toDate);
                if (!isafter) {
                  majorOngoing.push(projectData);
                } else {
                  majorCompleted.push(projectData);
                }
              } else {
                majorOngoing.push(projectData);
              }
            }
            if (projectData.projectType === 'Minor' || projectData.projectType === 'UGC Minor' || projectData.projectType === 'Other Projects') {
              if (projectData.toDate && moment(projectData.toDate).isValid) {
                var isafter = this.today.isAfter(projectData.toDate);
                if (!isafter) {
                  minorOnGoing.push(projectData);
                } else {
                  minorCompleted.push(projectData);
                }
              } else {
                minorOnGoing.push(projectData);
              }
            }
            if (projectData.projectType === 'Student Project') {
              if (projectData.toDate && moment(projectData.toDate).isValid) {
                var isafter = this.today.isAfter(projectData.toDate);
                if (!isafter) {
                  studentOnGoing.push(projectData);
                } else {
                  studentCompleted.push(projectData);
                }
              } else {
                studentOnGoing.push(projectData);
              }
            }
          }
        }
        this.majorOngoing = _.orderBy(majorOngoing, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
        this.majorCompleted = _.orderBy(majorCompleted, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
        this.minorOnGoing = _.orderBy(minorOnGoing, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
        this.minorCompleted = _.orderBy(minorCompleted, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
        this.studentCompleted = _.orderBy(studentCompleted, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
        this.studentOnGoing = _.orderBy(studentOnGoing, (o: any) => {
          if (o.toDate) {
            return moment(o.toDate).format('YYYYMMDD');
          } else {
            return moment(new Date()).format('YYYYMMDD');
          }
        }, ['desc']);
      });
    });
  }

  formatDate(date: any) {
    if(date) {
      return moment(date).format('Do MMM YYYY')
    } else {
      return '';
    }
  }
}
