import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-commitee',
  templateUrl: './commitee.component.html',
  styleUrls: ['./commitee.component.scss']
})
export class CommiteeComponent implements OnInit {

  public committees: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
   
  }

  loadCommitee() {
    this.webService.getCommittees().subscribe((data) => {
      const sortedArray = _.orderBy(data, (o: any) => {
        return parseInt(o.orderNo);
      }, ['asc']);
      this.committees = sortedArray;
    });
  }

  openCommiteeMembers(id: any, title: any) {
    this.router.navigate(['statutoryBodiesMem', id, title])
  }


}
