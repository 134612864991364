<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align" *ngIf="departmentData">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container" *ngIf="departmentData">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">{{departmentData.name}}</div>
      <h1 class="kingster-page-title">{{title}}</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div *ngIf="!documents || !documents.length">
                  <h4> No Data Found </h4>
                </div>
                <div *ngIf="documents && documents.length">
                  <div *ngFor="let doc of documents;">
                    <app-file-viewer [src]="webService.getBucketURL(doc.src)" title="{{doc.title}}"></app-file-viewer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>