import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-news-letters',
  templateUrl: './iqac-news-letters.component.html',
  styleUrls: ['./iqac-news-letters.component.scss']
})
export class IqacNewsLettersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
