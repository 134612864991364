<div class="d-sm-flex justify-content-between align-items-center mb-3">
  <div class="text-center text-sm-left mb-2 mb-sm-0">
    <div class="btn-group d-none d-lg-block" style="text-align: center; padding-bottom: 50px;">
      <span>
        <span class="cal-event-mark" style="background-color: #975aff;">&nbsp;</span> 
        <span class="cal-event-title"> Academic Events </span>
      </span>
      <span style="margin-left: 12px;">
        <span class="cal-event-mark" style="background-color: rgb(32, 201, 151);">&nbsp;</span> 
        <span class="cal-event-title"> Department Events </span>
      </span>
      <span style="margin-left: 12px;">
        <span class="cal-event-mark" style="background-color: #2f8be6;">&nbsp;</span> 
        <span class="cal-event-title"> Day Orders</span>
      </span>
      <span style="margin-left: 12px;">
        <span class="cal-event-mark" style="background-color: #616d89;">&nbsp;</span> 
        <span class="cal-event-title"> Academic Calander Notes</span>
      </span>
    </div>
  </div>
</div>
<div class="d-sm-flex justify-content-between align-items-center mb-3">
  <div class="text-center text-sm-left mb-2 mb-sm-0">
    <div class="btn-group d-none d-lg-block" style="text-align: center; padding-bottom: 50px;">
      <a class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        style="background-color: #3db166; height: 30px; padding: 10px; font-weight: bold; color: #fff; border-radius: 5px 0px 0px 5px; cursor: pointer;">
        Previous Month
      </a>
      <a class="btn btn-danger" mwlCalendarToday [(viewDate)]="viewDate"
        style="background-color: #192f59; height: 30px; padding: 10px; font-weight: bold; color: #fff; cursor: pointer;">
        Today
      </a>
      <a class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        style="background-color: #3db166; height: 30px; padding: 10px; font-weight: bold; color: #fff; border-radius: 0px 5px 5px 0px; cursor: pointer;">
        Next Month
      </a>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-12">
    <div class="text-center mb-2 mb-sm-0" style="text-align: center; padding-top: 20px;">
      <h6 class="text-uppercase mb-0" style="font-size: 22px;">
        <b>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</b></h6>
    </div>
  </div>
</div>
<div [ngSwitch]="view" class="mt-2">
  <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" [cellTemplate]="monthCellTemplate">
  </mwl-calendar-month-view>
</div>
<ng-template #monthCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
  let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
  let-eventClicked="eventClicked">
  <div class="fill-height">
    <div class="cal-cell-top">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div class="cal-events">
      <div class="cal-event" *ngFor="let event of day.events" [style.backgroundColor]="event.color.primary"
        [ngClass]="event?.cssClass" (mouseenter)="highlightDay.emit({event: event})"
        (mouseleave)="unhighlightDay.emit({event: event})"
        [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement" (click)="$event.stopPropagation(); eventClicked.emit({event: event})">
      </div>
    </div>
  </div>
</ng-template>
