import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-iqac-minutes-reports',
  templateUrl: './iqac-minutes-reports.component.html',
  styleUrls: ['./iqac-minutes-reports.component.scss']
})
export class IqacMinutesReportsComponent implements OnInit {

  public reports: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
  }

  loadCommitee() {
    this.webService.getIQACMinutesReports().subscribe((data) => {
      const sortedArray = _.orderBy(data, (o: any) => {
        if (o.date) {
          const date = moment(o.date, 'YYYY-MM-DD')
          return moment(date).format('YYYYMMDD');
        } else {
          return moment(new Date()).format('YYYYMMDD');
        }
      }, ['desc']);
      this.reports = sortedArray;
    });
  }

}
