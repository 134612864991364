<div
  class="kingster-page-wrapper"
  id="kingster-page-wrapper"
  style="background-color: #f3f3f3"
  *ngIf="!viewResult"
>
  <div class="kingster-content-container kingster-container" *ngIf="resultData">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px"
              >
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3
                    class="gdlr-core-title-item-title gdlr-core-skin-title"
                    style="
                      font-size: 22px;
                      font-weight: 600;
                      letter-spacing: 0px;
                      text-transform: none;
                      color: #223d71;
                      margin-right: 30px;
                    "
                  >
                    {{ resultData.title }}
                  </h3>
                  <div
                    class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px; border-bottom-width: 3px"
                  ></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px"
              >
                <div
                  class="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border"
                >
                  <div class="gdlr-core-column-60">
                    <div class="gdlr-core-center-align">
                      <input
                        type="text"
                        placeholder="Register Number*"
                        class="reg-num-input"
                        [(ngModel)]="regNo"
                      />
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="gdlr-core-column-60" *ngIf="showError">
                    <div class="gdlr-core-center-align">
                      <h5 style="color: red; font-size: 16px">
                        Invalid Register Number
                      </h5>
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="gdlr-core-column-60 gdlr-core-center-align">
                    <button
                      type="submit"
                      class="submit"
                      (click)="onSubmit()"
                      [disabled]="!regNo"
                    >
                      Submit Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="kingster-page-wrapper"
  id="kingster-page-wrapper"
  *ngIf="resultData && resultContent && resultContent.length && viewResult"
>
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
          <div
            class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"
          >
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item clearfix gdlr-core-left-align gdlr-core-title-item-caption-top"
              >
                <div
                  class="gdlr-core-title-item-title-wrap clearfix"
                  style="text-align: center"
                >
                  <h3
                    style="
                      font-size: 22px;
                      font-weight: 600;
                      letter-spacing: 0px;
                      text-transform: none;
                      color: #223d71;
                      margin-right: 30px;
                      padding-bottom: 5px;
                    "
                  >
                    HALL TICKET - {{ resultContent[0].exam_name }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px"
              >
                <div
                  class="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border"
                >
                  <div>
                    <div
                      class="result-stu-title"
                      style="display: inline-block; width: 49%"
                    >
                      <div class="result-stu">
                        <span class="lable">
                          <b> Name: </b>
                        </span>
                        <span>
                          <b> {{ resultContent[0].stu_name }} </b>
                        </span>
                      </div>
                      <div class="result-stu">
                        <span class="lable">
                          <b> Register N0.:</b>
                        </span>
                        <span>
                          {{ resultContent[0].reg_no }}
                        </span>
                      </div>
                      <div class="result-stu">
                        <span class="lable">
                          <b> Programme: </b>
                        </span>
                        <span>
                          {{ resultContent[0].course_name }}
                        </span>
                      </div>
                    </div>
                    <div
                      style="
                        display: inline-block;
                        width: 49%;
                        text-align: right;
                      "
                    >
                      <img
                        src="https://storage.srnmcollege.ac.in/srnmcweb/hall_ticket/nov_2022/{{
                          resultContent[0].reg_no
                        }}.jpg"
                        style="width: 125px; height: 150px"
                      />
                    </div>
                  </div>
                  <div style="overflow-x: auto">
                    <table style="min-width: 835px">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Session</th>
                          <th>Course Code</th>
                          <th>Course Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let result of resultContent">
                          <td>
                            {{ getDateFormat(result.exam_dt) }}
                          </td>
                          <td>
                            {{ result.sess_name }}
                          </td>
                          <td>
                            {{ result.subject_cd }}
                          </td>
                          <td style="text-align: left; padding-left: 5px">
                            {{ result.subject_name }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px"
              >
                <div
                  class="gdlr-core-input-wrap gdlr-core-large gdlr-core-full-width gdlr-core-with-column gdlr-core-no-border"
                >
                  <div class="clear"></div>
                  <div class="gdlr-core-column-60 gdlr-core-center-align">
                    <button
                      type="submit"
                      class="submit close"
                      (click)="close()"
                    >
                      Close
                    </button>
                    &nbsp;
                    <button
                      type="submit"
                      class="submit print"
                      (click)="downloadResult()"
                    >
                      Download Hall Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="printTable" #resultHTMLContent  style="visibility: hidden; width: 0px; height: 0px;">
    <div>
      <div>
        <div>
          <div class="row">
            <div
              class="col-md-12"
              style="
                text-align: center;
                padding-top: 20px;
                line-height: 25px;
                padding-bottom: 15px;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-bottom-color: #a9a9a9;
              "
            >
              <img
                src="https://storage.googleapis.com/srnmc/logo_full.png"
                style="width: 130px"
              />
              <h2
                style="
                  font-size: 26px;
                  padding-top: 10px;
                  font-weight: bold;
                  margin-bottom: 0px;
                "
              >
                Sri S.Ramasamy Naidu Memorial College (Autonomous)
              </h2>
              <h4
                style="
                  font-size: 10px;
                  padding: 0px;
                  margin: 0px;
                  line-height: 28px;
                  font-weight: bold;
                "
              >
                (A Co-educational,Autonomous and Telugu Linguistic Minority
                Institution,affliated to Madurai Kamaraj University)
              </h4>
              <h4
                style="
                  font-size: 10px;
                  padding: 0px;
                  margin: 0px;
                  font-weight: bold;
                "
              >
                (Re-Accredited with 'A++' Grade by NAAC)
              </h4>
              <h3
                style="
                  font-size: 18px;
                  padding: 0px;
                  margin: 0px;
                  padding-top: 10px;
                  font-weight: bold;
                "
              >
                Sattur, Virudhunagar (DT), Tamilnadu - 626 203
              </h3>
            </div>
          </div>
        </div>
        <div class="gdlr-core-pbf-element">
          <div style="text-align: center; margin-top: 10px">
            <h3
              style="
                font-size: 22px;
                font-weight: 600;
                letter-spacing: 0px;
                text-transform: none;
                margin-right: 30px;
                padding-bottom: 5px;
                font-weight: bold;
              "
            >
              HALL TICKET - {{ resultContent[0].exam_name }}
            </h3>
          </div>
        </div>
        <div>
          <div style="padding-bottom: 45px">
            <div>
              <div>
                <div
                  class="result-stu-title"
                  style="font-size: 16px; display: inline-block; width: 49%"
                >
                  <div class="result-stu" style="margin-bottom: 15px">
                    <span
                      class="lable"
                      style="width: 150px; display: inline-block"
                      ><b> Name: </b></span
                    ><span><b>  {{ resultContent[0].stu_name }} </b> </span>
                  </div>
                  <div class="result-stu" style="margin-bottom: 15px">
                    <span
                      class="lable"
                      style="width: 150px; display: inline-block"
                      ><b> Register No:</b></span
                    ><span> {{ resultContent[0].reg_no }} </span>
                  </div>
                  <div class="result-stu" style="margin-bottom: 15px">
                    <span
                      class="lable"
                      style="width: 150px; display: inline-block"
                      ><b> Course: </b></span
                    ><span> {{ resultContent[0].course_name }} </span>
                  </div>
                </div>
                <div
                  style="display: inline-block; width: 49%; text-align: right"
                >
                  <img
                    src="{{imageSrc}}"
                    style="width: 125px; height: 150px"
                  />
                </div>
              </div>
              <br />
              <div style="overflow-x: auto">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Session</th>
                      <th>Course Code</th>
                      <th>Course Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let result of resultContent">
                      <td style="text-align: center">{{ getDateFormat(result.exam_dt) }}</td>
                      <td style="text-align: center">{{ result.sess_name }}</td>
                      <td style="text-align: center">
                        {{ result.subject_cd }}
                      </td>
                      <td style="text-align: left; padding-left: 5px">
                        {{ result.subject_name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 11px">
        <b> Note: </b>
        <br />
        <p style="margin: 0">
          1. Students must bring their Hall Ticket daily. othenrvise, will not
          be allowed to write 2' students the examination.
        </p>
        <p style="margin: 0">
          2. Students the examination. indulging in any kind of malpractice
          during examination will lead to cance ation of all the examinations
          written by them in the ongoing semester.
        </p>
      </div>
      <div style="margin-top: 200px">
        <div style="display: inline-block; width: 50%">
          Signature of the Candidate
        </div>
        <div style="display: inline-block; width: 50%; text-align: right">
          Controller of the Examination
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Exam Result"
  [(visible)]="documentVisible"
  [maximizable]="true"
  [blockScroll]="true"
  [draggable]="false"
  [dismissableMask]="true"
  appendTo="body"
  [modal]="true"
  [baseZIndex]="200"
  [style]="{ width: '80vw' }"
>
  <ng2-pdfjs-viewer
    #pdfViewerOnDemand
    [openFile]="false"
    [viewBookmark]="false"
    [find]="false"
    pagemode="none"
  >
  </ng2-pdfjs-viewer>
</p-dialog>
