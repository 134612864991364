import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-research-scholars',
  templateUrl: './research-scholars.component.html',
  styleUrls: ['./research-scholars.component.scss']
})
export class ResearchScholarsComponent implements OnInit {

  public today: any;
  public projectData: any;
  public formData: any;
  public majorCompleted: any;
  public minorOnGoing: any;
  public minorCompleted: any;
  constructor(
    private element: ElementRef,
    public webService: WebServiceService,
  ) { }

  ngOnInit(): void {
    this.webService.getCurrentDate().subscribe((data) => {
      this.today = moment(data);
      this.webService.getResearchGuides().subscribe((guides) => {
       this.constructGrid(guides);
      });
    });
  }

  constructGrid(resdata: any) {
    const gridData: any = {};
    gridData['title'] = resdata.title;
    gridData['jsonId'] = resdata.formJson.id;
    gridData['maxEntry'] = resdata.formJson.maxEntry;
    gridData['data'] = [];
    const data = resdata.formData;
    for (let index = 0; index < data.length; index++) {
      const formData = data[index];
      const form = {
        docs: formData.docs,
      };
      _.extend(form, JSON.parse(formData.formData));
      gridData['data'].push(form);
    }
    let columns = new Map()
    const json = JSON.parse(resdata.formJson.formJSON);
    for (let index1 = 0; index1 < json.components.length; index1++) {
      const component = json.components[index1];
      if(component.input) {
        columns.set(component.key, component.label);
      }
    }
    gridData['columns'] = columns;
    this.formData = _.orderBy(gridData.data, (o: any) => {
      if (o.dateOfRegistrationAsAScholar) {
        const date = moment(o.dateOfRegistrationAsAScholar, 'DD/MM/YYYY')
        return moment(date).format('YYYYMMDD');
      } else {
        return moment(new Date()).format('YYYYMMDD');
      }
    }, ['desc']);
  }

  formatDate(date: any) {
    if(date) {
      const parsed = moment(date, 'DD/MM/YYYY');
      if(parsed.isValid()) {
        return moment(parsed).format('Do MMM YYYY');
      } else {
        return '';
      } 
    } else {
      return '';
    }
  }

}
