import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-contact-us',
  templateUrl: './research-contact-us.component.html',
  styleUrls: ['./research-contact-us.component.scss']
})
export class ResearchContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
