import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acdemic-report',
  templateUrl: './acdemic-report.component.html',
  styleUrls: ['./acdemic-report.component.scss']
})
export class AcdemicReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
