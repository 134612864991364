<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <h1 class="kingster-page-title">RTI</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="kingster-content-container kingster-container">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper ">
          <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                  style="padding-bottom: 45px ;">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                      <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify; height: 1000px;">
                        <ng2-pdfjs-viewer pdfSrc="https://storage.srnmcollege.ac.in/srnmcweb/pdf/RTI.pdf" [openFile]="false" [viewBookmark]="false"></ng2-pdfjs-viewer>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  