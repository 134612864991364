<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Research Projects </div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <span *ngIf="majorOngoing && majorOngoing.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Major Projects (On Going)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of majorOngoing"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span *ngIf="majorCompleted && majorCompleted.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Major Projects (Completed)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of majorCompleted"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span *ngIf="minorOnGoing && minorOnGoing.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Minor Projects (On Going)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of minorOnGoing"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span *ngIf="minorCompleted && minorCompleted.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Minor Projects (Completed)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of minorCompleted"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span *ngIf="studentOnGoing && studentOnGoing.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Student Projects (On Going)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of studentOnGoing"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span *ngIf="studentCompleted && studentCompleted.length">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #e63535 ;margin-right: 30px ;">
                      Student Projects (Completed)</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                  <div *ngFor="let project of studentCompleted"
                    class="gdlr-core-personnel-list-column gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px; font-weight: 700; letter-spacing: 0px; text-transform: none;">
                          {{project.staffName}} </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: 500; font-style: normal; letter-spacing: 0px;">
                          {{project.staffDesignation}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> Title: </span> {{project.title}}
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 12px; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #3db166;"> {{formatDate(project.fromDate)}} -
                            {{formatDate(project.toDate)}} </span>
                        </div>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Funding Agency: </span> {{project.agency}}
                        </div>
                        <div *ngIf="project.amount"
                          class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 14px; font-weight: bold; font-style: normal; letter-spacing: 0px;">
                          <span style="color: #223d71;"> Amount in Rs.: </span> {{project.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>