<div class="kingster-page-title-wrap kingster-style-custom kingster-center-align"
  style="background-image: url({{coverImage}})" *ngIf="title && coverImage">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-bottom-gradient"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr" style="padding-top: 400px; padding-bottom: 60px">
      <!-- <div class="kingster-page-caption" style="font-size: 21px ;font-weight: 400 ;letter-spacing: 0px ;">Graduate Program</div> -->
      <h1 class="kingster-page-title" style="
          font-size: 48px;
          font-weight: 700;
          text-transform: none;
          letter-spacing: 0px;
          color: #ffffff;
        ">
        {{title}}
      </h1>
    </div>
  </div>
</div>
<div class="gdlr-core-page-builder-body">
  <div class="gdlr-core-pbf-wrapper " style="padding: 100px 20px 30px 20px;">
    <div class="gdlr-core-pbf-background-wrap"></div>
    <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
      <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
        <div class="gdlr-core-pbf-element">
          <div class="gdlr-core-gallery-item gdlr-core-item-pdb clearfix  gdlr-core-gallery-item-style-grid">
            <div class="gdlr-core-gallery-item-holder clearfix" data-layout="fitrows">
              <span *ngFor="let image of images; index as idx;">
                <div class="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-20 gdlr-core-item-pdlr gdlr-core-item-mgb click-handle" (click)="lightbox.open(0, 'lightbox', {panelClass: 'fullscreen'})">
                  <div class="gdlr-core-gallery-list gdlr-core-media-image">
                    <img [src]="webService.getBucketURL(image)" alt="">
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
