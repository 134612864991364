import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-exam-hallticket',
  templateUrl: './exam-hallticket.component.html',
  styleUrls: ['./exam-hallticket.component.scss']
})
export class ExamHallTicketComponent implements OnInit {

  public resultData: any;
  public viewResult: boolean = false;
  public showError: boolean = false;
  public documentVisible: boolean = false;
  public resultContent: any;
  public hallTicketCol: any;
  public regNo: string = '';
  public imageSrc: any;
  @ViewChild('resultHTMLContent', { read: ViewContainerRef }) resultHTMLContent: any;
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
  }

  ngOnInit(): void {
    const hallTicketCol: any = this.route.snapshot.paramMap.get('hallTicketCol');
    this.hallTicketCol = hallTicketCol;
    this.webService.checkPublishedHallTicket(this.hallTicketCol).subscribe((data: any) => {
      this.resultData = data;
      if(this.resultData.hallTicketCol !== this.hallTicketCol) {
        this.router.navigate(['examHallTickets']);
      }
    }, () => {
      this.router.navigate(['examHallTickets']);
    });
  }

  onSubmit() {
    this.showError = false;
    if(this.regNo && this.regNo !== '') {
      this.webService.checkHallTicket(this.hallTicketCol, this.regNo).subscribe((data: any) => {
        if(data && data.length) {
          this.getBase64ImageFromUrl(`https://storage.srnmcollege.ac.in/srnmcweb/hall_ticket/nov_2022/${data[0].reg_no}.jpg`)
          .then((result: any) => {
            this.imageSrc = result;
          })
          .catch(err => console.error(err));
          const sortedArray = _.orderBy(data, (o: any) => {
            let dateFormat = moment(o.exam_dt, 'DD/MM/YYYY');
            if(!dateFormat.isValid()) {
              dateFormat = moment(o.exam_dt, 'DD-MM-YYYY')
            }
            return dateFormat.format('YYYYMMDD');
          }, ['asc']);
          this.resultContent = sortedArray;
          this.viewResult = true;
          this.showError = false;
        } else {
          this.showError = true;
        }
      }, () => {
        this.showError = true;
      });
    } else {
      this.showError = true;
    }
  }

  close() {
    this.regNo = '';
    this.showError = false;
    this.viewResult = false;
  }
  

  downloadResult() {
    const pdfContent = this.resultHTMLContent.element.nativeElement.innerHTML;
    const linkSTR = `<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">`;
    const htmlSTr = linkSTR + pdfContent
    this.webService.downloadPDFFromHTML({
      "htmlString": htmlSTr,
      "fileName": "exam_result_" + this.regNo
    }).subscribe(res => {
      this.pdfViewerOnDemand.pdfSrc = res; // pdfSrc can be Blob or Uint8Array
      this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/reresh pdf
      this.documentVisible = true;
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  getDateFormat(date: any) {
    let dateFormat = moment(date, 'DD/MM/YYYY').format('DD-MM-YYYY');
    if(dateFormat === 'Invalid date') {
      dateFormat = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
    }
    return dateFormat;
  }

}
