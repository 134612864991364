<app-banner title="Programmes Offered" align="center"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-wrapper " style="padding: 90px 0px 60px 0px;">
      <div class="gdlr-core-pbf-background-wrap" style="background-color: #efefef ;"></div>
      <div class="gdlr-core-pbf-wrapper-content ">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-element">
            <div
              class="gdlr-core-tab-item gdlr-core-js-tab gdlr-core-item-pdb gdlr-core-left-align gdlr-core-tab-style1-horizontal gdlr-core-item-pdlr">
              <div class="gdlr-core-tab-item-wrap">
                <div class="gdlr-core-tab-item-title-wrap clearfix gdlr-core-title-font">
                  <div class="gdlr-core-tab-item-title gdlr-core-active" data-tab-id="1">UG</div>
                  <div class="gdlr-core-tab-item-title" data-tab-id="2">PG</div>
                  <div class="gdlr-core-tab-item-title" data-tab-id="3">M.Phil</div>
                  <div class="gdlr-core-tab-item-title" data-tab-id="4">Ph.D</div>
                </div>
                <div class="gdlr-core-tab-item-content-wrap clearfix">
                  <div class="gdlr-core-tab-item-content  gdlr-core-active" data-tab-id="1"
                    style="background-color: rgb(255, 255, 255); ">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                      <div class="gdlr-core-title-item-title-wrap ">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">UG
                          Programmes<span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h3>
                      </div>
                    </div>
                    <div class="gdlr-core-pbf-element">
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> B.A. Tamil </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> B.A. English </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> B.A. History </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">B.Com </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">B.Com. Professional
                              Accounting </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">B.Com. Computer Application
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.B.A
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Mathematics
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Physics
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Chemistry
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Zoology
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Nutrition and Dietetics
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              B.Sc. Computer Science
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-tab-item-content " data-tab-id="2"
                    style="background-color: rgb(255, 255, 255); ">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                      <div class="gdlr-core-title-item-title-wrap ">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">PG
                          Programmes
                          <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                        </h3>
                      </div>
                    </div>
                    <div class="gdlr-core-pbf-element">
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.A. Tamil </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.A. English </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.A. History </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Com </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Sc. Mathematics </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Sc. Physics
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              M.Sc. Chemistry
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">
                              M.Sc. Computer Science
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-tab-item-content " data-tab-id="3"
                    style="background-color: rgb(255, 255, 255); ">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                      <div class="gdlr-core-title-item-title-wrap ">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">
                          M.Phil. Programmes<span
                            class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                        </h3>
                      </div>
                    </div>
                    <div class="gdlr-core-pbf-element">
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.Phil. English </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.Phil. Commerce </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> M.Phil. Mathematics </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Phil. Physics </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Phil. Chemistry </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">M.Phil. Computer Science
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-tab-item-content " data-tab-id="4"
                    style="background-color: rgb(255, 255, 255); ">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top">
                      <div class="gdlr-core-title-item-title-wrap ">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 700 ;text-transform: none ;color: #314e85 ;">
                          Ph.D. Programmes<span
                            class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span></h3>
                      </div>
                    </div>
                    <div class="gdlr-core-pbf-element">
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> Ph.D. Tamil </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> Ph.D. English
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title"> Ph.D. History </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">Ph.D. Commerce </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">Ph.D. Mathematics </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">Ph.D. Physics
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-title-item-caption-top"
                        style="padding-bottom: 40px;">
                        <div class="gdlr-core-course-item-list">
                          <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display:block;"
                            routerLink="/departments">
                            <span class="gdlr-core-course-item-title gdlr-core-skin-title">Ph.D. Computer Science
                            </span>
                            <span style="float: right;">
                              <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>