import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.scss']
})
export class AdmissionComponent implements OnInit, AfterViewInit {

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    setTimeout(() =>{
      const gdlr_core_js = jQuery(this.element.nativeElement).find('.gdlr-core-js-tab');
      jQuery(this.element.nativeElement).gdlr_core_tab( gdlr_core_js );
    }, 500);
  }

}
