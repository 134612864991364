<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Research Centres</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top "
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Research Centres</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(30)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> Department of Tamil</span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list">
                  <a (click)="navigate(35)" class="gdlr-core-course-item-link doc-list-item"
                    style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> Department of English </span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list">
                  <a (click)="navigate(45)" class="gdlr-core-course-item-link doc-list-item"
                    style="cursor: pointer; display: block;">
                    <span class="gdlr-core-course-item-title gdlr-core-skin-title"> Department of History </span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(87)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">Department of Commerce </span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(70)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">Department of Chemistry
                    </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(81)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">Department of Computer Science
                    </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(52)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">Department of Mathematics
                    </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a (click)="navigate(63)"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">Department of Physics </span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>