import { Component, ElementRef, OnInit } from '@angular/core';
import {WebServiceService} from './../web-service.service';
import * as moment from 'moment';
import * as _ from 'lodash';
declare var jQuery: any;
@Component({
  selector: 'app-important-anoncement',
  templateUrl: './important-anoncement.component.html',
  styleUrls: ['./important-anoncement.component.scss']
})
export class ImportantAnoncementComponent implements OnInit {

  annocements: Array<any> = [];
  constructor(
    public webService: WebServiceService,
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    this.webService.getNewsByType('IMPORTANT_ANNONCEMENT').subscribe((data) => {
      const filterDate = _.filter(data, (news: any)=> {
        var today = moment().format('YYYY-MM-DD').toString();
        var date = moment(news.endDate);
        var now = moment(today);
        if (now <= date) {
          return true;
        } else {
          return false;
        }
      });
      this.annocements = filterDate;
      setTimeout(() => {
        var gdlr_core_js = jQuery(this.element.nativeElement).find('.gdlr-core-js-imp');
        jQuery(this.element.nativeElement).gdlr_core_title_divider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_flexslider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_sly( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_isotope( gdlr_core_js );
      }, 500);
    });
  }
}
