<app-banner title="Departments" align="center"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Aided Departments</h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div *ngIf="!aidedDepartments || !aidedDepartments.length" style="text-align: center;">
                    <h4> No Data Found </h4>
                  </div>
                </div>
                <div *ngIf="aidedDepartments && aidedDepartments.length">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style="padding-bottom: 40px ;">
                      <div *ngFor="let aidedDepartment of aidedDepartments;" class="gdlr-core-course-item-list"><a
                          class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;" (click)="openDepartment(aidedDepartment.id)"><span
                            class="gdlr-core-course-item-title gdlr-core-skin-title" >{{aidedDepartment.name}}</span><i
                            class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        UnAided Departments</h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div *ngIf="!unAidedDepartments || !unAidedDepartments.length" style="text-align: center;">
                    <h4> No Data Found </h4>
                  </div>
                </div>
                <div *ngIf="unAidedDepartments && unAidedDepartments.length">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style="padding-bottom: 40px ;">
                      <div *ngFor="let unAidedDepartment of unAidedDepartments;" class="gdlr-core-course-item-list">
                        <a
                          class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;" (click)="openDepartment(unAidedDepartment.id)">
                          <span
                            class="gdlr-core-course-item-title gdlr-core-skin-title">{{unAidedDepartment.name}}</span>
                            <i
                            class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                          </a>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
