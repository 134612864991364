import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';


@Component({
  selector: 'app-non-teachin-staffs',
  templateUrl: './non-teachin-staffs.component.html',
  styleUrls: ['./non-teachin-staffs.component.scss']
})
export class NonTeachinStaffsComponent implements OnInit, OnChanges {

  public departmentData: any;
  public nonTeachingStaffData: any;
  public type: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
  }

  ngOnInit(): void {
    this.loadCommitee();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadCommitee();
  }

  loadCommitee() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.webService.nonTeachingStaffInfo(this.type).subscribe((data) => {
      const sortedArray = _.orderBy(data, (o: any) => {
        if(o.orderNo === 0) {
          o.orderNo = undefined;
        }
        return parseInt(o.orderNo);
      }, ['asc']);
      this.nonTeachingStaffData = sortedArray;
    });
  }

  openFacultyMembers(id: any, title: any) {
    this.router.navigate(['departmentProfiles', id, title])
  }
  
  departmentDocs(type: any) {
    this.router.navigate(['departmentDocs', this.departmentData.id, type])
  }

  getUserName(email: string) {
    const username = email.split('@');
    return username[0];
  }

}
