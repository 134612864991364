import { WebServiceService } from './../web-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dbt-practicals',
  templateUrl: './dbt-practicals.component.html',
  styleUrls: ['./dbt-practicals.component.scss']
})
export class DBTPracticalsComponent implements OnInit {


  public physics = [
    {
      "name": "Inverse Square law setup",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Acoustical Studies of liquids – Ultrasonic Interferometer",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Thermal conductivity of a bad conductor – Lee’s Disc Method",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Refractive index of liquids by a hollow prism – Spectrometer",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Solar cell efficiency calculation setup",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Four Probe setup",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Fibre Optic Experiment Setup",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Planck’s constant setup",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "B-H Curve",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Curie temperature",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "PCB Board Preparation",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "PN junction Characteristics of a diode",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Voltage Tripler",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Glucose concentration setup",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Dielectric studies of liquids",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Dielectric studies of solids",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Hall Effect",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Refractive index of liquids – Abbe’s refractometer",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Monostable Multivibrator – IC 741",
      "class": "III Year",
      "regulation": 2023
    }
  ];

  public chemistry = [
    {
      "name": "MS Office Practical Course",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Qualitative analysis of Na/K in the given water sample using Flame Photometer (Demo Experiment)",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Qualitative analysis of metals used in pyrotech industries.",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Determination of melting point of a given organic Compound",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Determination of rate of corrosion by weight loss method",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Verification of Freundlich adsorption isotherm by using oxalic acid in activated charcoal",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Extraction of pure solvent from crude solvent/solution",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Synthesis of Biodiesel",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of copper in sample by EDTA method",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of Iodine Value of Oil",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of Acid value (Acid Number) of given oil",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Clinical Analysis of Blood Samples",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of unknown concentration of the given solution through Standard Curve Method using UV-Visible Spectrophotometer",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Laboratory Preparation of Aspirin",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Laboratory Preparation of Paracetamol",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of Ni as Ni(DMG)2 through complexometric analysis by gravimetric analysis",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Computational Chemistry Practical Course",
      "class": "III Year",
      "regulation": 2023
    }
  ];

  public zoology = [
    {
      "name": "Handling of microscope Micrometry",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Sterilization techniques of glassware and laboratory equipment.",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Media Preparation for microbial analysis",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Isolation of bacteria from the Soil, Air, and Water",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Antimicrobial activity of Medicinal plants",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Plant tissue culture techniques",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Measurement of Blood pressure and Heartbeat",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Thin Layer Chromatography techniques",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Separation of Plant pigments by Column Chromatography",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Separation of Protein by SDS-PAGE",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Culture of Edible and Ornamental Fishes",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Analysis of Biotic and Abiotic factors in the water reservoirs",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Synthesis of Nanoparticles from Plants (Interdepartmental work with Physics Dept.)",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Project work",
      "class": "III Year",
      "regulation": 2023
    }
  ];

  public cs = [
    {
      "name": "<b>C Programming</b><br><br> * To Calculate Body Mass Index (BMI) Value.<br><br> * To Convert Blood glucose level in mmol/L to mg/dl and mg/dl to mmol/L. <br><br> * To count the number of characters and find the frequency of a character in DNA Sequence.<br><br> * To convert DNA Sequence to RNA Sequence.<br><br> * To Create Structure with the following members: Patient Name, Patient ID, Age, A1Ctest, Fasting Blood Sugar Test, Glucose Tolerance Test. Diagnosis the sugar level. <br><br> * To create a file with the following fields Patient Name, Patient ID, Age, Systolic Pressure (mm Hg) and Diastolic Pressure(mm Hg). Categorize the patients based on the Blood Pressure.",
      "class": "I year",
      "regulation": 2022
    },
    {
      "name": "<b>Java Programming</b><br><br> * To print DNA sequence<br><br> * To count DNA Nucleotide<br><br> * To complementing a Strand of DNA<br><br> * To Transcribing DNA to RNA<br><br> * To create a file named “health-details” with the following informations<br><br> * CBC (Complete Blood Count) – Red Blood Cells, White Blood cells, haemoglobin(HB), Hematocrit(HCT) and Platelets",
      "class": "I year",
      "regulation": 2022
    },
    {
      "name": "<b>Python Programming</b><br><br> * Reverse transcribe a DNA sequence<br><br> * Translating DNA Sequences into Protein Sequences<br><br> * Split a DNA sequence into codons<br><br> * To read a FASTA file, and return a list of the sequences.",
      "class": "II year",
      "regulation": 2022
    },
    {
      "name": "<b>Bio Image Processing</b><br><br> * Read and Display Images<br><br> * Perform Arithmetic operations of image processing<br><br> * Perform Logical operations on images<br><br> * Perform Geometrical operations on images<br><br> * Perform color conversions on the given image. <br><br> * Display color Image of a tumour cell, find its complement and convert to gray scale image and binary.<br><br> * Blood Cell Counting.<br><br> * Implementation of Transformations on a CT Image<br><br> * Computate the mean, Standard Deviation, Correlation coefficient of the given medical Image <br><br> * Histogram equalization image for the given medical image.<br><br> * Implementation of Image Smoothening Filters(Mean and Median filtering of an Image) for the given neuron image<br><br> * Implementation of various Edge Detection algorithms for the given lung image using Gradient Filters.<br><br> * Perform morphological operations (Erosions & Dilation) on natural scenery.<br><br> * Brain tumour Extraction .",
      "class": "III Year",
      "regulation": 2022
    }
  ];

  public nandD = [
    {
      "name": "Fruits and vegetables processing",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Preparation of bakery and confectionery products",
      "class": "I Year",
      "regulation": 2023
    },
    {
      "name": "Moisture estimation",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Energy estimation",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of minerals",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of fibre",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of nitrogen",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of fat",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of sugars",
      "class": "II Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of blood glucose",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Estimation of erythrocytes",
      "class": "III Year",
      "regulation": 2023
    },
    {
      "name": "Minor projects",
      "class": "III Year",
      "regulation": 2023
    }
  ];

  constructor(
    public web: WebServiceService
  ) { }

  ngOnInit(): void {
  }
  

}
