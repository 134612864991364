import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import {WebServiceService} from './../web-service.service';

declare var jQuery: any;
@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit, AfterViewInit {
  
  carouselBanner: any;
  sliderImages:any =  [];
  constructor(
    public webService: WebServiceService,
    private element: ElementRef
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.webService.getHomeSlideImages().subscribe((data) => {
      this.sliderImages = data;
    });
  }
}
