import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  public aidedDepartments: any;
  public unAidedDepartments: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadDepartments();
  }

  loadDepartments() {
    this.webService.getAllDepartments().subscribe((data) => {
      const aided = _.filter(data, {type: 'AIDED'});
      const unAided = _.filter(data, {type: 'UNAIDED'});
      const sortedArrayAided = _.orderBy(aided, (o: any) => {
        return parseInt(o.orderNo);
      }, ['asc']);
      const sortedArrayUnAided = _.orderBy(unAided, (o: any) => {
        return parseInt(o.orderNo);
      }, ['asc']);
      this.aidedDepartments = sortedArrayAided;
      this.unAidedDepartments = sortedArrayUnAided;
    });
  }

  openDepartment(id: any) {
    this.router.navigate(['department', id])
  }

}
