<div id="aboutContainer">
 <app-banner title="About SRNMC" align="left"></app-banner>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper " style="padding: 40px 0px 30px 0px;">
        <div class="gdlr-core-pbf-background-wrap"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 29px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">History of
                    Sri S. Ramasamy Naidu Memorial College</h3>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                  <div class="gdlr-core-pbf-background-wrap"></div>
                  <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js " style="max-width: 900px ;">
                    <div class="gdlr-core-pbf-element">
                      <div
                        class="gdlr-core-blockquote-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-small-size"
                        style="padding-bottom: 40px ;">
                        <div class="gdlr-core-blockquote gdlr-core-info-font">
                          <div class="gdlr-core-blockquote-item-quote gdlr-core-quote-font gdlr-core-skin-icon">“</div>
                          <div class="gdlr-core-blockquote-item-content-wrap">
                            <div class="gdlr-core-blockquote-item-content gdlr-core-skin-content">
                              <p>Education is the kindling of a flame, not the filling of a vessel.</p>
                            </div>
                            <div class="gdlr-core-blockquote-item-author gdlr-core-skin-caption"><span
                                class="gdlr-core-blockquote-item-author-name">Socrates</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px; text-transform: none; text-align: justify;">
                  <p>Sri S.Ramasamy Naidu, familiarly known as "SR" was a dedicated educationalist and freedom fighter.
                    His realization that political freedom will be of no value unless there is freedom from illiteracy
                    led him to work tirelessly for the great cause of education. Thanks to his keen interest and
                    initiative today, this part of Tamilnadu is blessed with a number of educational institutions. </p>
                  <p>Four decades ago, some great philanthropists of this region founded our College in memory of this
                    visionary leader, in order to realize his noble ambition. It was incepted at Nagalapuram village in
                    1970 with Thiru.V.Venugopala Krishnasamy Naidu as its Founder President. The Mission of the College
                    was to serve the interests of the rural poor, help them have the benefit of excellent education and
                    find employment, and thereby ameliorate their lifestyles. </p>
                  <p>In 1972, the College was shifted to Sattur. The College became a University-affiliated first-grade
                    institute offering a degree course in B.A. Branch-I-History. Then the urgent need for a more
                    spacious institution was deeply felt. Hence a forty-eight-acre site was purchased. Thanks to the
                    generosity of its countless benefactors over the years, it is possible for the College to have a
                    number of magnificent buildings now. In this regard, the College gratefully remembers the
                    munificence of a great philanthropist, Late Sri.Sathu.T.Ramasamy Naicker, the founder of Sri Jaya
                    Vilas (P) Ltd., Aruppukottai.</p>
                  <p> At present the College is offering 14 Undergraduate courses, 8 Postgraduate courses, 6 M.Phil.
                    Courses and 6 Ph.D. programmes. </p>
                  <p>The UGC conferred Autonomous Status to the college in the academic year 2010-2011 and the same was
                    extended in the year 2016-2017. The National Assessment and Accreditation Council (NAAC) accredited
                    the college with "B++" Grade in 2005, reaccredited it with "A" Grade with 3.22 CGPA in 2012 and now
                    again reaccredited it with "A" Grade with 3.38 CGPA in 2017. These crown achievements have spurred
                    the institution towards academic transcendence and social service. </p>
                  <p>Since its inception, this institution of higher learning has been fulfilling the educational needs
                    of the people of this area in a commendable way.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper " style="padding: 660px 0px 30px 0px;">
        <div class="gdlr-core-pbf-background-wrap">
          <div class="gdlr-core-pbf-background"
            style="background-image: url(./../../assets/images/slide/DSC_0025.JPG) ;background-size: cover ;background-position: center ;background-attachment: fixed ;">
          </div>
        </div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container"></div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper " style="padding: 80px 0px 40px 0px;" data-skin="Column Service" id="logoSRNMC">
        <div class="gdlr-core-pbf-background-wrap" style="background-color: #1b2945 ;"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
              <div class="gdlr-core-title-item-title-wrap clearfix">
                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                  style="font-size: 29px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">Logo of the
                  College</h3>
              </div>
              <br>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-15 gdlr-core-column-first">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      style="padding-bottom: 30px;">
                      <div class="gdlr-core-column-service-media gdlr-core-media-image" style="margin-bottom: 31px;">
                        <img src="./../../assets/images/book.png" alt="" width="43" height="45" title="col-icon-2" />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                            style="font-size: 21px ;font-weight: 700 ;text-transform: none ;">Open Book</h3>
                        </div>
                        <div class="gdlr-core-column-service-content" style="font-size: 16px ;text-transform: none ;">
                          <p>The Open Book represents knowledge.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-15">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      style="padding-bottom: 30px;">
                      <div class="gdlr-core-column-service-media gdlr-core-media-image" style="margin-bottom: 33px;">
                        <img src="./../../assets/images/torch.png" alt="" width="40" height="43" title="col-icon-3" />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                            style="font-size: 21px ;font-weight: 700 ;text-transform: none ;">Flaming Torch</h3>
                        </div>
                        <div class="gdlr-core-column-service-content" style="font-size: 16px ;text-transform: none ;">
                          <p>A bright scarlet flame in a golden yellow handle represents human effort.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-15">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      style="padding-bottom: 30px;">
                      <div class="gdlr-core-column-service-media gdlr-core-media-image" style="margin-bottom: 35px;">
                        <img src="./../../assets/images/moto.png" alt="" width="41" height="41" title="col-icon-1" />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                            style="font-size: 21px ;font-weight: 700 ;text-transform: none ;">Motto</h3>
                        </div>
                        <div class="gdlr-core-column-service-content" style="font-size: 16px ;text-transform: none ;">
                          <p>The motto of the College is "Love, Wisdom and Service". The three objects highlighted in
                            the
                            emblem represent the following.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-15">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                      style="padding-bottom: 30px;">
                      <div class="gdlr-core-column-service-media gdlr-core-media-image" style="margin-bottom: 29px;">
                        <img src="./../../assets/images/lotus.png" alt="" width="47" height="47" title="col-icon-4" />
                      </div>
                      <div class="gdlr-core-column-service-content-wrapper">
                        <div class="gdlr-core-column-service-title-wrap">
                          <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                            style="font-size: 21px ;font-weight: 700 ;text-transform: none ;">Lotus</h3>
                        </div>
                        <div class="gdlr-core-column-service-content" style="font-size: 16px ;text-transform: none ;">
                          <p>Scarlet petals, opal blue water with streaks of green and rich green leaves against a
                            silver
                            white background represent blessing of the Goddess of Learning</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper " id="div_983a_21">
        <div class="gdlr-core-pbf-background-wrap" id="div_983a_22"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js " id="visionSRNMC">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " id="div_983a_23"
                data-sync-height="height-1" data-sync-height-center>
                <div class="gdlr-core-pbf-background-wrap" id="div_983a_24"></div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-left-align">
                      <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                        id="div_983a_25"><img src="./../../assets/images/icon-1.png" alt="" width="65" height="65"
                          title="about-icon-1" /></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="div_983a_26">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_983a_4">Vision</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div class="gdlr-core-text-box-item-content" id="div_983a_27">
                        <p style="text-align: justify;">To make higher education available to the students from
                          socially, economically and
                          educationally backward families and to increase their employment prospects.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-30">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-right"
                data-sync-height="height-1">
                <div class="gdlr-core-pbf-background-wrap">
                  <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" id="div_983a_28"
                    data-parallax-speed="0.2"></div>
                </div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gdlr-core-pbf-wrapper " id="div_983a_29">
        <div class="gdlr-core-pbf-background-wrap" id="div_983a_30"></div>
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-left"
                data-sync-height="height-2">
                <div class="gdlr-core-pbf-background-wrap">
                  <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" id="div_983a_31"
                    data-parallax-speed="0.2"></div>
                </div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"></div>
              </div>
            </div>
            <div class="gdlr-core-pbf-column gdlr-core-column-30">
              <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " id="div_983a_32"
                data-sync-height="height-2" data-sync-height-center>
                <div class="gdlr-core-pbf-background-wrap" id="div_983a_33"></div>
                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-left-align">
                      <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                        id="div_983a_34"><img src="./../../assets/images/icon-2.png" alt="" width="67" height="58"
                          title="about-icon-2" /></div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      id="div_983a_35">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_983a_5">Mission</h3>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div class="gdlr-core-text-box-item-content" id="div_983a_36">
                        <ul style="text-align: justify;">
                          <li>
                            <p> To offer the rural students opportunities that will bring to light their inherent
                              talents.
                            </p>
                          </li>
                          <li>
                            <p> To help them realize their potential worth To empower women, and make them self -
                              reliant.
                            </p>
                          </li>
                          <li>
                            <p> To promote the employment prospects of rural students. </p>
                          </li>
                          <li>
                            <p> To mold them into self-confident and socially responsible Citizens. </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gdlr-core-pbf-wrapper" id="div_1dd7_44">
      <div class="gdlr-core-pbf-background-wrap"> </div>
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-column gdlr-core-column-40 gdlr-core-column-first">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " id="div_1dd7_45" data-sync-height="height-2">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-blog-item gdlr-core-item-pdb clearfix  gdlr-core-style-blog-widget">
                    <div class="gdlr-core-block-item-title-wrap  gdlr-core-left-align gdlr-core-item-mglr"
                      id="div_1dd7_46">
                      <div class="gdlr-core-block-item-title-inner clearfix">
                        <h3 class="gdlr-core-block-item-title" id="h3_1dd7_10">
                          Autonomy</h3>
                        <div class="gdlr-core-block-item-title-divider" id="div_1dd7_47"> </div>
                      </div>
                      <br>
                      <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style="line-height: 35px; text-align: justify;"> <strong> Sri S. Ramasamy Naidu
                          Memorial College </strong> became an autonomous college
                        in 2010. It continues to be an affiliated college of the
                        <strong> Madurai Kamaraj University </strong> and is
                        autonomous in the sense that it is free to frame its own
                        courses of studies and to adopt innovative methods of
                        teaching and evaluation. The university confers the degrees
                        to the students after passing the examinations held by the
                        college and the name of the college will be indicated in the
                        degrees by the <strong> Madurai Kamaraj University
                        </strong>.
                      </span>
                    </div>
                    <div>
                      <img src="./../../assets/images/slide/IMG_2282.jpeg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column gdlr-core-column-20" id="gdlr-core-column-8">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-right"
              id="div_1dd7_48" data-sync-height="height-2">
              <div class="gdlr-core-pbf-background-wrap">
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js" id="div_1dd7_49"
                  data-parallax-speed="0"> </div>
              </div>
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="div_1dd7_50">
                    <div class="gdlr-core-title-item-left-icon" id="div_1dd7_51"> <img
                        src="./../../assets/images/col-icon-3.png" alt="" width="30" height="33" title="about-icon-2" />
                    </div>
                    <div class="gdlr-core-title-item-left-icon-wrap">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_15">College Profile
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-66469">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Name and Address of the College
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Sri S.Ramasamy Naidu Memorial College, <br>
                        Sadayampatti, Sattur, Virudhunagar Dist. Tamilnadu.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Year of Establishment
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        1970
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        UGC recognition
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Under 2 (f) and 12 B
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Affiliating University
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Madurai Kamaraj University
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Status of the College
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Autonomous and Linguistic Minority College
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Financial Category
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Grant-in-aid and Self-Financing
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Type of College
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        Co-Educational
                      </p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    id="gdlr-core-title-item-id-42777">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_17">
                        Area of the Campus
                      </h3>
                      <br>
                      <p class="gdlr-core-column-service-content" style="color: white;">
                        48.54 Acres
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>