<div class="kingster-page-title-wrap  kingster-style-custom kingster-center-align"
  style="background-image: url(./../../assets/images/slide/IMG_2282.jpeg) ;">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-bottom-gradient"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr" style="padding-top: 400px ;padding-bottom: 60px ;">
      <div class="kingster-page-caption" *ngIf="type" style="text-transform: capitalize;">{{type}}</div>
      <h1 class="kingster-page-title"
        style="font-size: 48px ;font-weight: 700 ;text-transform: none ;letter-spacing: 0px ;color: #ffffff ;">
        Administrative Staff </h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper ">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 40px 0px 0px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                <div *ngIf="!nonTeachingStaffData || !nonTeachingStaffData.length" style="text-align: center;">
                  <h3> No Faculties Added </h3>
                </div>
                <div *ngIf="nonTeachingStaffData && nonTeachingStaffData.length">
                  <div *ngFor="let staffData of nonTeachingStaffData;"
                    class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div
                        class="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-hover-element gdlr-core-zoom-on-hover">
                        <a routerLink="/profile/{{getUserName(staffData.email)}}"><img
                            [src]="webService.getUserImage(staffData)" alt="" width="500" height="500"
                            title="personnel-1"></a>
                        <div class="gdlr-core-hover-opacity"></div>
                      </div>
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;"><a
                            routerLink="/profile/{{getUserName(staffData.email)}}">
                            {{staffData.title}} {{staffData.lastName}}
                            {{staffData.firstName}} <small style="font-size: 18px;"> {{staffData.qualification}} </small> </a>
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                          {{staffData.designation}}</div>
                        <div class="gdlr-core-personnel-list-social" style="padding-top: 10px;">
                          <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                            style="padding-bottom: 0px ;">
                            <a [href]="webService.transform(staffData.facebookLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="facebook"
                              style="font-size: 18px ;color: #50bd77 ;" *ngIf="staffData.facebookLink">
                              <i class="fa fa-facebook"></i>
                            </a>
                            <a [href]="webService.transform(staffData.linkedInLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="linkedin"
                              style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;  margin-left: 14px"
                              *ngIf="staffData.linkedInLink"><i class="fa fa-linkedin"></i></a>
                            <a [href]="webService.transform(staffData.twitterLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="twitter"
                              style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;  margin-left: 14px"
                              *ngIf="staffData.twitterLink"><i class="fa fa-twitter"></i>
                            </a>
                          </div>
                        </div>
                        <a class="gdlr-core-personnel-list-button gdlr-core-button"
                          routerLink="/profile/{{getUserName(staffData.email)}}">More Detail</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>