<p-dialog header="{{title}}" [(visible)]="documentVisible" [maximizable]="true" [blockScroll]="true" [draggable]="false"
  [dismissableMask]="true" appendTo="body" [modal]="true" [baseZIndex]="200" [style]="{width: '80vw'}">
  <ng2-pdfjs-viewer #pdfViewerOnDemand [openFile]="false" [viewBookmark]="false"></ng2-pdfjs-viewer>
</p-dialog>
<p-dialog header="{{title}}" [(visible)]="imageVisible" [maximizable]="true" [blockScroll]="true" [draggable]="false"
  appendTo="body" [modal]="true">
  <div>
    <img [src]="src" height="100%" width="100%" />
  </div>
</p-dialog>
<div class="gdlr-core-course-item-list">
  <a class="gdlr-core-course-item-link doc-list-item" (click)="openDocument()" *ngIf="type === 'document'">
    <span class="gdlr-core-course-item-title gdlr-core-skin-title">
      {{title}}
    </span>
    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
  </a>
  <a class="gdlr-core-course-item-link doc-list-item" (click)="openImageDocument()" *ngIf="type === 'image'">
    <span class="gdlr-core-course-item-title gdlr-core-skin-title">
      {{title}}
    </span>
    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
  </a>
  <a class="gdlr-core-course-item-link doc-list-item" href="{{src}}" target="_blank" *ngIf="type === 'other'">
    <span class="gdlr-core-course-item-title gdlr-core-skin-title">
      {{title}}
    </span>
    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
  </a>
</div>