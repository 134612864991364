<app-banner title="SRNMC Gallery" align="center"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div>
      <iframe width="100%" height="550px" src="https://www.youtube.com/embed/RxyMi4mI4Mw" title="Video Tour of SRNMC"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="gdlr-core-pbf-wrapper" style="padding: 50px 0px 30px 0px">
      <div class="gdlr-core-pbf-background-wrap"></div>
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-element">
            <div class="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix gdlr-core-portfolio-item-style-grid"
              style="padding-bottom: 10px">
              <div class="gdlr-core-portfolio-item-holder clearfix" data-layout="fitrows">
                <div *ngFor="let album of infaAlbums"
                  class="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-column-30">
                  <span *ngIf="album && album.coverImage">
                    <div class="gdlr-core-portfolio-grid gdlr-core-left-align gdlr-core-style-normal click-handle"
                      (click)="openAlubm(album.id)">
                      <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-icon">
                        <div class="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                          <img [src]="album.coverImage" width="700" height="450" alt="" />
                          <span
                            class="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                            <span class="gdlr-core-image-overlay-content" style="margin-top: -39.5px">
                              <span class="gdlr-core-portfolio-title gdlr-core-title-font" style="
                                  font-size: 18px;
                                  font-weight: 400;
                                  letter-spacing: 1px;
                                  text-transform: none;
                                ">
                                <a (click)="openAlubm(album.id)">
                                  {{album.title}}
                                </a>
                              </span>
                              <span class="gdlr-core-portfolio-icon-wrap">
                                <a class="gdlr-core-lightgallery gdlr-core-js" (click)="openAlubm(album.id)"
                                  data-lightbox-group="gdlr-core-img-group-1">
                                  <i class="gdlr-core-portfolio-icon arrow_expand"></i>
                                </a>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
                        <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title" style="
                            font-size: 21px;
                            font-weight: 600;
                            letter-spacing: 0px;
                            text-transform: none;
                          ">
                          <a (click)="openAlubm(album.id)">
                            {{album.title}}
                          </a>
                        </h3>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>