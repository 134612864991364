import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { PrincipalComponent } from './principal/principal.component';
import { HomeComponent } from './home/home.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { ImportantAnoncementComponent } from './important-anoncement/important-anoncement.component';
import { ImportantLinksComponent } from './important-links/important-links.component';
import { AboutSrnmcComponent } from './about-srnmc/about-srnmc.component';
import { NaacReportComponent } from './naac-report/naac-report.component';
import { AcdemicReportComponent } from './acdemic-report/acdemic-report.component';
import { HostelComponent } from './hostel/hostel.component';
import { LibraryComponent } from './library/library.component';
import { ExamCellComponent } from './exam-cell/exam-cell.component';
import { ExamRulesComponent } from './exam-rules/exam-rules.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { DepartmentsComponent } from './departments/departments.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentFacultyComponent } from './department-faculty/department-faculty.component';
import { DepartmentSyllabusTimeTableComponent } from './department-syllabus-time-table/department-syllabus-time-table.component';
import { WebServiceService } from './web-service.service';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { AcademicCalendarComponent } from './academic-calendar/academic-calendar.component';
import { ProfileComponent } from './profile/profile.component';
import { EventComponent } from './event/event.component';
import { NewsComponent } from './news/news.component';
import { CommiteeComponent } from './commitee/commitee.component';
import { CommiteeMembersComponent } from './commitee-members/commitee-members.component';
import { ExamDocumentsComponent } from './exam-documents/exam-documents.component';
import { BoardOfManagementComponent } from './board-of-management/board-of-management.component';
import { PlacementComponent } from './placement/placement.component';
import { DeansComponent } from './deans/deans.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { AngularWeatherWidgetModule } from 'angular2-weather-widget';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { NirfComponent } from './nirf/nirf.component';
import { AlbumImagesComponent } from './album-images/album-images.component';
import { RTIComponent } from './rti/rti.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { ReaserchComponent } from './reaserch/reaserch.component';
import { ResearchProjectsComponent } from './research-projects/research-projects.component';
import { IqacComponent } from './iqac/iqac.component';
import { IqacCommiteeMemberComponent } from './iqac-commitee-member/iqac-commitee-member.component';
import { IqacMinutesReportsComponent } from './iqac-minutes-reports/iqac-minutes-reports.component';
import { IqacAqarReportComponent } from './iqac-aqar-report/iqac-aqar-report.component';
import { AdmissionComponent } from './admission/admission.component';
import { DialogModule } from 'primeng/dialog';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule, UserTrackingService, ScreenTrackingService } from '@angular/fire/analytics';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { ToastrModule } from "ngx-toastr";

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderHttpModule
 } from "ngx-ui-loader";
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component';
import { ExamHallTicketListComponent } from './exam-hallticket-list/exam-hallticket-list.component';
import { ExamHallTicketComponent } from './exam-hallticket/exam-hallticket.component';
import { ExamResultComponent } from './exam-result/exam-result.component';
import { ResearchPolicyComponent } from './research-policy/research-policy.component';
import { ResearchCommitteeComponent } from './research-committee/research-committee.component';
import { ResearchCentresComponent } from './research-centres/research-centres.component';
import { ResearchGuidesComponent } from './research-guides/research-guides.component';
import { ResearchSeedMoneyComponent } from './research-seed-money/research-seed-money.component';
import { ResearchFundingAgenciesComponent } from './research-funding-agencies/research-funding-agencies.component';
import { ResearchTravelGrantComponent } from './research-travel-grant/research-travel-grant.component';
import { ResearchContactUsComponent } from './research-contact-us/research-contact-us.component';
import { ResearchScholarsComponent } from './research-scholars/research-scholars.component';
import { ResearchSeedMoneyProjectsComponent } from './research-seed-money-projects/research-seed-money-projects.component';
import { NonTeachinStaffsComponent } from './non-teachin-staffs/non-teachin-staffs.component';
import { BannerComponent } from './banner/banner.component';
import { ResearchDownloadFormsComponent } from './research-download-forms/research-download-forms.component';
import { IqacMenuComponent } from './iqac-menu/iqac-menu.component';
import { ResearchMenuComponent } from './research-menu/research-menu.component';
import { IqacStackholderFeedbackComponent } from './iqac-stackholder-feedback/iqac-stackholder-feedback.component';
import { IqacFeedbackActionTakenReportComponent } from './iqac-feedback-action-taken-report/iqac-feedback-action-taken-report.component';
import { IqacStrategicPlanComponent } from './iqac-strategic-plan/iqac-strategic-plan.component';
import { IqacNewsLettersComponent } from './iqac-news-letters/iqac-news-letters.component';
import { IqacBestPracticesComponent } from './iqac-best-practices/iqac-best-practices.component';
import { IqacInstitutionalDistinctivenessComponent } from './iqac-institutional-distinctiveness/iqac-institutional-distinctiveness.component';
import { IqacCodeOfConductComponent } from './iqac-code-of-conduct/iqac-code-of-conduct.component';
import { ResearchEthicsComponent } from './research-ethics/research-ethics.component';
import { IctToolsComponent } from './ict-tools/ict-tools.component';
import { TrainingComponent } from './training/training.component';
import { OrginizationStructureComponent } from './orginization-structure/orginization-structure.component';
import { IQACAQAR1617Component } from './iqac-aqar-16-17/iqac-aqar-16-17.component';
import { IQACAQAR1718Component } from './iqac-aqar-17-18/iqac-aqar-17-18.component';
import { IQACAQAR1819Component } from './iqac-aqar-18-19/iqac-aqar-18-19.component';
import { IQACAQAR1920Component } from './iqac-aqar-19-20/iqac-aqar-19-20.component';

import { TrainingSoftSkillsComponent } from './training-soft-skill/training-soft-skill.component';
import { TrainingLifeSkillsComponent } from './training-life-skill/training-life-skill.component';
import { TrainingCommunicationSkillsComponent } from './training-communication-skill/training-communication-skill.component';
import { TrainingTrendsSkillsComponent } from './training-trends-skill/training-trends-skill.component';
import { DbtComponent } from './dbt/dbt.component';
import { DBTMenuComponent } from './dbt-menu/dbt-menu.component';
import { DBTMembersComponent } from './dbt-members/dbt-members.component';
import { DBTEquipmentComponent } from './dbt-equipment/dbt-equipment.component';
import { DBTPracticalsComponent } from './dbt-practicals/dbt-practicals.component';
import { DBTProgrammesComponent } from './dbt-programmes/dbt-programmes.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#975AFF",
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  logoUrl: `${environment.storageUrl}srnmc/logo_full.png`,
  logoSize: 150
};
@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    MenuComponent,
    FooterComponent,
    MobileMenuComponent,
    PrincipalComponent,
    HomeComponent,
    HomeSliderComponent,
    ImportantAnoncementComponent,
    ImportantLinksComponent,
    AboutSrnmcComponent,
    NaacReportComponent,
    AcdemicReportComponent,
    HostelComponent,
    LibraryComponent,
    ExamCellComponent,
    ExamRulesComponent,
    NirfComponent,
    DepartmentsComponent,
    DepartmentComponent,
    DepartmentFacultyComponent,
    DepartmentSyllabusTimeTableComponent,
    EventsCalendarComponent,
    AcademicCalendarComponent,
    ProfileComponent,
    EventComponent,
    NewsComponent,
    CommiteeComponent,
    CommiteeMembersComponent,
    ExamDocumentsComponent,
    BoardOfManagementComponent,
    PlacementComponent,
    DeansComponent,
    InfrastructureComponent,
    AlbumImagesComponent,
    ReaserchComponent,
    ResearchProjectsComponent,
    IqacComponent,
    IqacCommiteeMemberComponent,
    IqacMinutesReportsComponent,
    IqacAqarReportComponent,
    AdmissionComponent,
    FileViewerComponent,
    ExamResultListComponent,
    ExamResultComponent,
    ResearchPolicyComponent,
    ResearchCommitteeComponent,
    ResearchCentresComponent,
    ResearchGuidesComponent,
    ResearchSeedMoneyComponent,
    ResearchFundingAgenciesComponent,
    ResearchTravelGrantComponent,
    ResearchContactUsComponent,
    ResearchScholarsComponent,
    ResearchSeedMoneyProjectsComponent,
    NonTeachinStaffsComponent,
    BannerComponent,
    ResearchDownloadFormsComponent,
    IqacMenuComponent,
    ResearchMenuComponent,
    IqacStackholderFeedbackComponent,
    IqacFeedbackActionTakenReportComponent,
    IqacStrategicPlanComponent,
    IqacNewsLettersComponent,
    IqacBestPracticesComponent,
    IqacInstitutionalDistinctivenessComponent,
    IqacCodeOfConductComponent,
    ResearchEthicsComponent,
    IctToolsComponent,
    TrainingComponent,
    OrginizationStructureComponent,
    RTIComponent,
    IQACAQAR1617Component,
    IQACAQAR1718Component,
    IQACAQAR1819Component,
    IQACAQAR1920Component,
    TrainingSoftSkillsComponent,
    TrainingTrendsSkillsComponent,
    TrainingCommunicationSkillsComponent,
    TrainingLifeSkillsComponent,
    DbtComponent,
    DBTMenuComponent,
    DBTMembersComponent,
    DBTEquipmentComponent,
    DBTPracticalsComponent,
    DBTProgrammesComponent,
    ExamHallTicketListComponent,
    ExamHallTicketComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    IvyCarouselModule,
    AngularWeatherWidgetModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    BrowserAnimationsModule,
    GalleryModule,
    LightboxModule,
    DialogModule,
    PdfJsViewerModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true, exclude: ['https://www.google-analytics.com/g/collect'] }),
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    WebServiceService,
    UserTrackingService,
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
