import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-research-guides',
  templateUrl: './research-guides.component.html',
  styleUrls: ['./research-guides.component.scss']
})
export class ResearchGuidesComponent implements OnInit {
  constructor(
    private router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  navigate(id: any) {
    this.router.navigate(['profile', id]);
  }

}
