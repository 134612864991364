import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dbt',
  templateUrl: './dbt.component.html',
  styleUrls: ['./dbt.component.scss']
})
export class DbtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
