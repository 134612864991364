<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">IQAC</div>
      <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      STRATEGIC PLAN 2022-2032</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <!-- content start -->
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p style=" margin-bottom: 5px;">Named after Sri.S.Ramasamy Naidu, a renowned freedom fighter and
                      great educationist, this
                      institution had a very humble beginning in the rented building of a Ginning factory in Nagalapuram
                      village in the year 1970.The Prime objective of the College is to educate the rural poor and guide
                      them towards excellence. The motto of the College is</p>
                    <ul>
                      <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                          class="gdlr-core-icon-list-content" style="font-size: 16px ; ">Love</span>
                      </li>
                      <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                          class="gdlr-core-icon-list-content" style="font-size: 16px ; ">Wisdom</span>
                      </li>
                      <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                          class="gdlr-core-icon-list-content" style="font-size: 16px ; ">Service</span>
                      </li>
                    </ul>
                    <p style=" margin-bottom: 10px;">The founders envisioned the emergence of rural communities and
                      enlightened them with good quality Education. It was their conviction that wisdom was realizable
                      through service emanating from the love of humanity. So the goal was set. But the location of the
                      college did not facilitate its speedy realization. Lack of sufficient transport facilities made
                      the institution inaccessible to the prospective students. This resulted in the transfer of the
                      college to the village limits of sadayampatti near sattur.The mission remained the same to bring
                      higher education within the reach of the poor masses of this rural region.</p>
                    <p style=" margin-bottom: 5px;"> Over these forty seven years since its establishment, this
                      institution has achieved remarkable growth from a rented shed to elegant buildings and from
                      Pre-University courses to a number of postgraduate and research programs.Its growth was not
                      smooth. During the early stages, the institution struggled desperately to pay its staff. There
                      were occasions when a well-wisher of the institution sold his agricultural produce at throw away
                      prices and donated the money to the Institution to save it from extinction. Venerable elders like
                      him used to travel by cycle to a number of villages collecting money to sustain this institution.
                      Then came the philanthropy of Sri Jayavilas group of industries to ensure the institution’s steady
                      growth. It is a remarkable coincidence that this institution founded to serve the poor villagers
                      came to be sustained by the very same villagers. This naturally has made the institution retain
                      its humility all through its growth. Never letting go of teamwork and endurance, the college
                      received its first recognition in 2005, when NAAC awarded ‘B++’ grade. Feathers to the Hat were
                      added in the year 2010-11 when Autonomy was conferred by the UGC and the same was extended in the
                      year 2016-17. Another glorious achievement is the reaccreditation of the institution with ‘A’
                      Grade in 2012 with a CGPA of 3.22. These achievements have inspired us to apply for a third cycle
                      of re-accreditation with a good score of 3.38. As we march towards Golden Jubilee in 2020, the
                      college has a tremendous growth in all the sectors with good quality measures.
                      </p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Vision </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p style=" margin-bottom: 5px;">To make higher Education available to the students from socially,
                      economically and educationally backward families and to increase their employment prospects.</p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Mission </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                style="padding-bottom: 25px ; text-align: justify;">
                <ul>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">To offer the rural students opportunities that will bring to light
                        their inherent talents.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">To help them realize their potential worth to empower women and make
                        them self-reliant.
                      </span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">To promote the employment prospects of rural students.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">To mould them into self-confident and socially responsible citizens.
                      </span></div>
                  </li>
                </ul>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Quality Policy </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                style="padding-bottom: 25px ; text-align: justify;">
                <p style="margin-bottom: 15px; font-size: 16px;">The quality policy of SRNMC adheres to the vision and
                  mission of the college </p>
                <ul>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">Serving society by providing quality Higher Education with ethical,
                        moral and constitutional values thereby contributing to the National development.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">Creating a nurturing and holistic environment to facilitate every
                        student to realize their full potential in both academic and non-academic spheres and fostering
                        global competencies.
                      </span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">Improving the effectiveness of Quality Management system through
                        continuous upgradation, Commitment, teamwork and promoting the use of technology.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 16px ;">To ensure Integrity, transparency and resource efficiency through
                        autonomy, good governance and empowerment with a quest for Excellence.
                      </span></div>
                  </li>
                </ul>
                <p style="margin-bottom: 15px; font-size: 16px;">SRNMC has drafted out its strategic plan which draws
                  out an action plan for achieving the institution's Mission. The strategic planning process was
                  initiated by the IQAC to give a strategic direction for growth. The strategic plan of 2022-2032 was
                  framed based on the basis of initiatives, assessment of progress and the target goals. A Strategic
                  Plan Advisory Committee (SPAC) was constituted in the year 2021 with the Members from Board of
                  management, Institutional leadership, IQAC coordinator, Planning and Evaluation committee members,
                  Faculty members and student representatives. The main objective of the committee was the strategic
                  planning based on the support operations, Feedback Analysis, Suggestions and improvement based on SWOC
                  matrix and implementation and analysis of the plans. </p>
                  <div style="text-align: center;">
                    <img src="assets/images/strategic_plan1.jpeg" style="width: 60%;" />
                  </div>
                <p style="margin-bottom: 15px; font-size: 16px;">The tactical Plan of SRNMC is focusing on three
                  milestones as becoming a Degree Granting Institution, obtaining the status of Deemed to be University
                  and achieving the Institution of Eminence.</p>
                  <div style="text-align: center;">
                    <img src="assets/images/strategic_plan2.jpeg" style="width: 60%;" />
                  </div>
                  <div style="text-align: center;">
                    <img src="assets/images/strategic_plan3.jpeg" style="width: 50%;" />
                  </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 1: Academic Excellence </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>
                              1.1
                            </td>
                            <td>
                              Curriculum focussing on Multidisciplinary approach with refinement in the choice based
                              credit system.
                            </td>
                            <td>
                              Development of framework on the basis of NEP2020 and revision of curriculum to meet
                              specified Learning Outcome Based curriculum Framework by Curriculum Development Cell
                              (CDC).
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.2
                            </td>
                            <td>
                              Effective implementation of the National Framework on Fostering Social Responsibilityand
                              Community Engagement.
                            </td>
                            <td>
                              Development of modules in curriculum based on social values and including society oriented
                              real time projects for assessment.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.3
                            </td>
                            <td>
                              Life skills-Jeevan Kausal scheme
                            </td>
                            <td>
                              Development of modules based communication skills, interpersonal skills, time management,
                              team work, flexibility, problem solving, professional skills, decision making skills,
                              leadership abilities and universal values among others with assessment based on real time
                              projects
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.4
                            </td>
                            <td>
                              Vocational subjects in mainstream Education
                            </td>
                            <td>
                              Development of curriculum based on vocational subjects in academic mainstream Education
                              with the choice of the subject based on Student’s interest.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.5
                            </td>
                            <td>
                              Innovative Pedagogy in curriculum.
                            </td>
                            <td>
                              Development of Pedagogy with more emphasis on communication, discussion, debate, research
                              and opportunities for cross-disciplinary and inter-disciplinary thinking and internal
                              assessments based on oral presentations.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.6
                            </td>
                            <td>
                              Promotion of Universal Human Values and lifelong learning.
                            </td>
                            <td>
                              Refinement and continuous improvement in the modules of value and environmental education
                              by making it more activity based.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.7
                            </td>
                            <td>
                              To offer Open Distance Learning (ODL) through Online Mode and suitable integration into
                              the curriculum for Blended Learning
                            </td>
                            <td>
                              Training the teachers for the implementation of blended learning approach and
                              implementation of ODL with regulations and procedures.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1.8
                            </td>
                            <td>
                              Launch of E-content (MOOCs courses)
                            </td>
                            <td>
                              Training Faculty members to develop E-content courses
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 2: Flexible and Optimal Learning Environment for students </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>
                              2.1
                            </td>
                            <td>
                              Bilingual Programmes
                            </td>
                            <td>
                              Creation of Policy document and SOP for the Bilingual Programmes to facilitate students to
                              have better understanding and higher order thinking.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2.2
                            </td>
                            <td>
                              Increasing the number of Scholarship by the Management
                            </td>
                            <td>
                              Allocation of more funds to increase the number of beneficiaries and meritorious students.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2.3
                            </td>
                            <td>
                              Industry-Academia Linkages
                            </td>
                            <td>
                              Increasing the number of Industry linkages through MoUs.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2.4
                            </td>
                            <td>
                              Additional Internship/Industry training
                            </td>
                            <td>
                              SOP to identify and drive students towards their interest and needs and revamping
                              curriculum by including additional Internship/Industry training.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2.5
                            </td>
                            <td>
                              Enhancing Student Exchanges between Reputed Indian and Global Institutions
                            </td>
                            <td>
                              Establishing collaboration with institutions of Eminence globally. </td>
                          </tr>
                          <tr>
                            <td>
                              2.6
                            </td>
                            <td>
                              Structured Mentor-Mentee System
                            </td>
                            <td>
                              Recruitment of a greater number of teachers improvement in student-teacher ratio. </td>
                          </tr>
                          <tr>
                            <td>
                              2.7
                            </td>
                            <td>
                              Outcome-based grading system
                            </td>
                            <td>
                              Aligning with the evaluation reforms as in the guidelines framed by the UGC</td>
                          </tr>
                          <tr>
                            <td>
                              2.8
                            </td>
                            <td>
                              Digital Infrastructure
                            </td>
                            <td>
                              Upgrading ICT infrastructure with more automization technologies</td>
                          </tr>
                          <tr>
                            <td>
                              2.9
                            </td>
                            <td>
                              Consultancy services and corporate training
                            </td>
                            <td>
                              Creation of strategies and resources for creating a greater number of consultancy services
                              and corporate training.</td>
                          </tr>
                          <tr>
                            <td>
                              2.10
                            </td>
                            <td>
                              Entrepreneurship
                            </td>
                            <td>
                              Developing strategies to motivate and encourage Entrepreneurship among students by giving
                              opportunities for revenue generation.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 3: Research and Innovation </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>3.1</td>
                            <td>International collaborative Research</td>
                            <td>Programmes to explore platforms that encourage and fund research and to enhance
                              the good quality publications.</td>
                          </tr>
                          <tr>
                            <td>3.2</td>
                            <td>Professional development of teachers</td>
                            <td>Faculty Development Programmes with International Researchers and Experts and
                              providing Incentives for faculty members through rewards/ awards/ promotion/ others.</td>
                          </tr>
                          <tr>
                            <td>3.3</td>
                            <td>Strengthening Research</td>
                            <td>Proposal to construct new Research Labs including Artificial Intelligence Labs.
                            </td>
                          </tr>
                          <tr>
                            <td>3.4</td>
                            <td>Institutional Projects</td>
                            <td>Funding for more number of Projects through Institutional seed money Projects.
                            </td>
                          </tr>
                          <tr>
                            <td>3.5</td>
                            <td>To explore avenues to investigate for unique and innovative
                              technologies and research.</td>
                            <td>Igniting the spark of research among Faculty members and students through
                              teaching, research labs, exchange programmes and professional development Programs.</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 4: Infrastructure and Learning Resources </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>4.1</td>
                            <td>Upgradation of Digital Library</td>
                            <td>Establishing enhanced digital library with a greater number of resources and
                              modern technologies.</td>
                          </tr>
                          <tr>
                            <td>4.2</td>
                            <td>Institutional Database Management System to collect, process and
                              submission of data for regulatory bodies and accreditation/ranking agencies.</td>
                            <td>Implementing Integrated Solution for all the College Database management with
                              cloud servers.</td>
                          </tr>
                          <tr>
                            <td>4.3</td>
                            <td>Improving the Integration of IT into Examination system in all the
                              Departments.</td>
                            <td>Establishing an IT block for Examination with upgraded software technologies,
                              AI labs and biometric systems.</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 5: Strengthening Governance and Quality Management</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>5.1</td>
                            <td>Establishment of Committees to strengthen Governance and Quality
                              Management.</td>
                            <td>Developing Policies and Procedures and framing the SOPs to establish committees
                              to strengthen Governance and Quality Management Council as a part of IQAC.</td>
                          </tr>
                          <tr>
                            <td>5.2</td>
                            <td>Quest for Excellence</td>
                            <td>Establishing a Committee for Benchmarking to measure and evaluate the achieved
                              goals and to strategize plans to achieve further based on SWOC analysis.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 6: Improvisation of Life skills and Healthy Environment </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>

                          <tr>
                            <td>6.1</td>
                            <td>Promoting Professional, Emotional, Intellectual and social
                              competency among students</td>
                            <td>Proposal to have a Life skill trainer to inculcate universal human values among
                              students.</td>
                          </tr>
                          <tr>
                            <td>6.2</td>
                            <td>To create Healthy wellbeing and citizenship Health</td>
                            <td>Proposal to boost up sports activities by implementing traditional Indian games
                              for men and women as a compulsory hour in a week.</td>
                          </tr>
                          <tr>
                            <td>6.3</td>
                            <td>Health status of Girl students</td>
                            <td>Proposal to track a record of the health status of girl students, malnutrition
                              and self-hygienic awareness.</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 25px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Plan 7: Environmental Sustenance </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content"
                    style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <div style="overflow-x: auto;">
                      <table style="min-width: 835px;">
                        <tbody>
                          <tr>
                            <th style="width: 10%;"> Key Goals</th>
                            <th style="width: 40%;"> Functional Targets</th>
                            <th style="width: 40%;"> Deployment Particulars</th>
                          </tr>
                          <tr>
                            <td>7.1</td>
                            <td>Enhancing Green Environment</td>
                            <td>Developing SOPs for the discharge of the hazardous chemicals and effective
                              usage of the recyclable resources</td>
                          </tr>
                          <tr>
                            <td>7.2</td>
                            <td>Switching over to alternate energy harvesting techniques in phased
                              manner</td>
                            <td>Increasing the output of alternate energy facilities.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- content end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-iqac-menu></app-iqac-menu>
  </div>
</div>