import { Component, OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { WebServiceService } from './../web-service.service';
@Component({
  selector: 'app-important-links',
  templateUrl: './important-links.component.html',
  styleUrls: ['./important-links.component.scss']
})
export class ImportantLinksComponent implements OnInit {

  public dayOrder: any;
  constructor(
    private element: ElementRef,
    public webService: WebServiceService,
  ) { }

  ngOnInit(): void {
    var localTime = moment().format('YYYY-MM-DD');
    this.webService.getDayOrderEventDataByDate(localTime).subscribe((dayOrder) => {
        this.dayOrder = dayOrder;
    });
  }

}
