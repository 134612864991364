import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-department-faculty',
  templateUrl: './department-faculty.component.html',
  styleUrls: ['./department-faculty.component.scss']
})
export class DepartmentFacultyComponent implements OnInit {

  public departmentData: any;
  public departmentStaffData: any;
  public id: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
   
  }

  loadCommitee() {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getDepartmentProfile(this.id).subscribe((data) => {
      this.departmentData = data;
    });
    this.webService.getDepartmentProfileStaffInfo(this.id).subscribe((data) => {
      const sortedArray = _.orderBy(data, (o: any) => {
        return parseInt(o.departmentStaff.orderNo);
      }, ['asc']);
      this.departmentStaffData = sortedArray;
    });
  }

  openFacultyMembers(id: any, title: any) {
    this.router.navigate(['departmentProfiles', id, title])
  }
  
  departmentDocs(type: any) {
    this.router.navigate(['departmentDocs', this.departmentData.id, type])
  }

  getUserName(email: string) {
    const username = email.split('@');
    return username[0];
  }
}
