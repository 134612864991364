import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-of-management',
  templateUrl: './board-of-management.component.html',
  styleUrls: ['./board-of-management.component.scss']
})
export class BoardOfManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
