<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <h1 class="kingster-page-title">Training</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div class="gdlr-core-course-item-list">
                  <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;"
                    routerLink="/training-soft-skill">
                    <span class="gdlr-core-course-item-title gdlr-core-skin-title">Soft skills</span>
                    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                  </a>
                </div>
                <div class="gdlr-core-course-item-list">
                  <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;"
                    routerLink="/training-communication-skill">
                    <span class="gdlr-core-course-item-title gdlr-core-skin-title">Language and Communication
                      skills</span>
                    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                  </a>
                </div>
                <div class="gdlr-core-course-item-list">
                  <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;"
                    routerLink="/training-life-skill">
                    <span class="gdlr-core-course-item-title gdlr-core-skin-title">Life skills ( Yoga, Physical Fitness,
                      Health and Hygiene)x1</span>
                    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                  </a>
                </div>
                <div class="gdlr-core-course-item-list">
                  <a class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer;"
                    routerLink="/training-trends-skill">
                    <span class="gdlr-core-course-item-title gdlr-core-skin-title">Awareness of trends in
                      technology</span>
                    <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
                  </a>
                </div>
                <app-file-viewer src="https://naac.srnmcollege.ac.in/SSR2021/criteria-5/QnM/QnM_5.1.4/5_1_4_L1_info.pdf"
                  title="Career Guidance Training"></app-file-viewer>
                <app-file-viewer src="https://naac.srnmcollege.ac.in/SSR2021/criteria-5/QnM/QnM_5.1.4/5_1_4_L1_info.pdf"
                  title="Industry Connect Training"></app-file-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>