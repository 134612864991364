export const environment = {
  production: true,
  apiUrl: 'https://service.srnmcollege.ac.in/api/v1/',
  appUrl: 'https://newweb.srnmcollege.ac.in',
  storageUrl: 'https://storage.srnmcollege.ac.in/',
  firebase: {
    apiKey: "AIzaSyAP5-IUivZ0jAjzi6PyX6QDYE1TNV8ZI-U",
    authDomain: "srnmc-service-1609591077401.firebaseapp.com",
    projectId: "srnmc-service-1609591077401",
    storageBucket: "srnmc-service-1609591077401.appspot.com",
    messagingSenderId: "373281767496",
    appId: "1:373281767496:web:6e9cb6741f47c1e0c7987d",
    measurementId: "G-2CLVY3VF3F"
  }
};
