<app-banner title=" Controller of Examinations" align="center"></app-banner>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper " style="margin: 0px 0px 30px 0px;">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 35px 0px 20px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>The office of “The Controller of Examinations” has been established from the month
                    of June 2010 since the conferment of Autonomous status to the institution. The
                    college has necessary infrastructure for conducting all examinations. It has all the
                    facilities such as computers, printers, photocopy machines and adequate staff to
                    conduct examinations and publish the results. It is equipped with CCTV and well
                    protected. The CoE office is vested with the responsibility of organizing and
                    conducting End Semester Examinations for all Academic Programmes offered by the
                    College. Every academic year, End Semester Examinations are conducted in the
                    months of November and April. The CoE Office is responsible for the announcement
                    of Examination, preparation of question papers, conduction of examinations,
                    evaluation of answer scripts, declaration of results and issuing of grade sheets. The
                    Examination Cell is headed by the Controller of Examinations. </p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Objectives </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
              style="padding-bottom: 25px ;">
              <ul>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 18px ; text-align: justify;">To improve the quality of examination and evaluation so as to ensure the credibility
                      of the Examination system.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 18px ; text-align: justify;">To ensure that all components of the Examination system follow the regulations
                      approved by the Academic Council of the college to ensure transperancy and quality.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 18px ; text-align: justify;">To improve the examinations and evaluation system of the college and to make them
                      flexible enough to adopt the latest trends in Science, Engineering and Technology.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 18px ; text-align: justify;">To publish the
                      results within a short span of time.</span></div>
                </li>
              </ul>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    COE Administration Members</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-line-height gdlr-core-column-extend-left">
              <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr D.K. Nathan </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Controller of Examinations</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr T. Kavitha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Additional Controller of Examinations</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr S.
                            Nithya </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Controller of Examinations</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Evaluation Process</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>Final Evaluation is on the basis of both Continuous Internal Assessment and
                    External End Semester Examinations</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <td>
                            Continuous Internal Assessment (CIA)
                          </td>
                          <td>
                            25 Marks
                          </td>
                        </tr>
                        <tr>
                          <td>External End Semester Examinations</td>
                          <td>75 Marks</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>100 Marks</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                  <b>Continuous Internal Assessment (CIA)</b>
                  <p style="text-align: justify;"> * For theory papers of all courses the weightage of Internal Assessment shall be
                    25% of the total marks. </p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="text-transform: none ;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                          <td>
                            3 Tests (Average of 3 tests)
                          </td>
                          <td>
                            20 Marks
                          </td>
                        </tr>
                        <tr>
                          <td>Assignment</td>
                          <td>2.5 Marks</td>
                        </tr>
                        <tr>
                          <td>Seminar/Quiz</td>
                          <td>2.5 Marks</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>25 Marks</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                  <p style="text-align: justify;"> * For practical papers of all courses the weightage of Internal Assessment shall be
                    40% of the total marks.</p>
                  <p style="text-align: justify;"> * There is no minimum for pass in the Internal Assessment.</p>
                  <p style="text-align: justify;"> * A student must write at least two internal tests for inclusion of Assignment /
                    Seminar marks.</p>
                  <p style="text-align: justify;"> * An absentee for all the three tests of a course can’t appear for End Semester
                    examinations although he/she has required attendance or paid fee.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Retest for CIA</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                  <p style="text-align: justify;"> * Exemptions may be given for writing retests on the ground that the student
                    represents the college in Sports, NSS, NCC, etc. and/or on medical grounds
                    with prior approval of the concerned HOD(s) and the Principal.</p>
                  <p style="text-align: justify;"> * A student who has failed to write atleast two/three tests of CIA will have to
                    appear for the same in the subsequent semester with the prior permission of
                    the Principal and the HoD.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    External End Semester Examinations - Evaluation </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div
              class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
              style="padding-bottom: 25px ;">
              <ul>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">Question papers for all programmes are set by external examiners.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">Each question paper is assigned a question Code.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">For UG Courses a minimum of 36% in the End Semester Examination and a
                      minimum of 40% of the aggregate of both Internal and External examinations
                      will be required for a pass in each paper.
                    </span>
                  </div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">For PG Courses a minimum of 45% in the End of Semester Examination and
                      a minimum of 50% of the aggregate of both Internal and External
                      examinations will be required for a pass in each paper.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">The Central Evaluation system is followed.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">For all UG programmes, single evaluation is done by external examiners only.
                    </span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">For all PG programmes, double evaluations are done by external examiners.
                    </span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">A difference in marks amounting to 10 and above will be subject to third
                      evaluation. </span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">Evaluation of practical examinations is made jointly by one internal and one
                      external examiner.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">M.Phil dissertations are evaluated separately by one External Examiner and
                      one internal examiner.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">Viva Voce examination is conducted jointly by one external and one internal
                      examiner.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">The results are published with the approval of the Awards Committee,
                      consisting of two University nominees and Chairmen of the Boards of studies.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">UG Students can apply for revaluation.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">Once the student leaves the college after completing the course and has
                      arrears to clear, he/she is considered as a Private Candidate. They can write
                      the examination with the same syllabus they have studied.</span></div>
                </li>
                <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                    class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                      class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                      style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                  <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                      style="font-size: 16px ; text-align: justify;">If the student leaves the college before completing the course and wants to
                      continue after sometime, he/she has to re-register his/her name and take the
                      examination in the revised syllabus of the paper prescribed for the subsequent batch of candidates, in force at the time of their reappearance. In
                      the event of removal of that paper consequent to change of regulation and/or
                      curriculum after the three- year period, the candidate shall have to take up an
                      equivalent paper in the revised syllabus as suggested by the Chairman, Board
                      of Studies concerned.</span></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div id="text-23" class="widget widget_text kingster-widget">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">COE Contact Info
                    </h3>
                    <p>
                      <!-- <span style="color: #3db166; font-size: 18px; font-weight: 600;">Dr V.KANAGAVEL</span> -->
                      <br>
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">The
                        Controller of
                        Examination</span>
                      <br />
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                        <br />
                        <span style="color: #3db166; font-size: 14px; font-weight: 500;">Email</span>
                        <br />
                        <p><span style="font-size: 15px;">coe@srnmcollege.ac.in</span>
                          <br />
                          <span style="font-size: 15px;">controllersrnmc@gmail.com</span></p>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>