<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Research Policy</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Research policy of Sri S. Ramasamy Naidu Memorial College, Sattur</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p> Aims of the Research Policy</p>
                  <ul>
                    <li>
                      To create and sustain a research culture among its teachers, staff and students for enriching
                      their professional competence. </li>
                    <li> To develop and promote the scientific temper and research aptitudes of all learners for
                      realising
                      the vision and mission of the college.</li>
                    <li>To motivate the stakeholders for contributing to the national development by initiating a fund
                      and
                      plan for facilitating their participation in research and related activities.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Scope of the Research Policy</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p style="font-weight: 600;">i. The scope of the research policy shall apply to all the researchers of
                    the college. </p>
                  <ul>
                    <li>
                      All staff, aided and unaided who are actively involved in teaching, research and consultancy.
                    </li>
                    <li> Ph.D., & M.Phil., research scholars, UG and PG students registered with the college.
                    </li>
                    <li>All mentors, research supervisors and subject experts who are associated with any of the
                      research activities of the college.
                    </li>
                  </ul>
                  <p style="font-weight: 600;">ii. The policy shall apply to all the research and related activities of
                    the college which include. </p>
                  <ul>
                    <li>
                      Research activities including the basic, strategic and applied research activities undertaken
                      either to fulfil the requirements of academic degrees or to find the solution to the problems with
                      new initiatives and future scope.

                    </li>
                    <li> Knowledge assemblage for the academic developments in any knowledge domain such as writing of
                      textbooks, chapters of textbooks, monographs, developing/updating curriculum, etc.,

                    </li>
                    <li>Scholarly activities proposed to develop knowledge boundaries through analysis, synthesis and
                      interpretation of ideas in students’ research projects as part of the curriculum for enriching it.

                    </li>
                    <li>
                      Creative activities involving the generation of new ideas, innovations, hypotheses, images,
                      performances or artefacts, including design in any field of knowledge which leads to the
                      development of new knowledge, understanding or expertise;
                    </li>
                    <li>
                      Publication, presentation and communication of the research outcomes and related activities.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Objectives of the Research Policy</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p style="font-weight: 600;"> The objectives of the research policy as follow </p>
                  <ul>
                    <li>
                      To encourage original quality based and applied research in the fields of science, literature,
                      commerce and all related and other relevant fields.

                    </li>
                    <li> To establish MOUs with industries and business organizations to identify potential areas of
                      research, surveys and other basic research enquiry.
                    </li>
                    <li>To encourage faculty members to get research projects from National Funding agencies like SERB,
                      DBT, DST, ICSSR, UGC and other such institutions.
                    </li>
                    <li>
                      To depute faculty members to various workshops, symposia, seminars and conferences in their
                      respective fields as well as in areas of contemporary importance.
                    </li>
                    <li> To ensure publications in quality journals, indexed in Scopus/Web of Science and /or with high
                      impact factor.
                    </li>
                    <li>To serve as a facilitator by providing professional guidance, technical support and
                      recommendations for financial assistance.
                    </li>
                    <li>
                      To inculcate the research culture in the college and to ensure its quality, integrity and ethics
                      in research.
                    </li>
                    <li>
                      To establish research centres within the college.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Guidelines to Research Scholars</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p style="font-weight: 600;"> Eligibility for admission to Ph.D programme:</p>
                  <ol>
                    <li>
                      <b>Eligibility</b>
                      <ul>
                        <li>
                          Candidates possessing PG or M.Phil degree with not less than 55% (relaxation is applicable as
                          per the guidelines of Govt. of Tamil Nadu/Madurai Kamaraj University (MKU)) in discipline
                          concerned or as equivalent thereto and Certificate of Pass in Common Entrance Test issued by
                          MKU.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Admission</b>
                      <ul>
                        <li>
                          Candidates aspiring for admission to Ph.D., programme in our College shall submit his/her
                          application along with the necessary certificates to the Principal and the same shall be
                          forwarded by the willing supervisor and Head of the Department concerned.
                        </li>
                        <li>
                          Call letter will be issued to the candidate to appear before the Research Advisory Committee
                          of the concerned Department for verification of certificates on the prescribed date and
                          provisional admission shall be intimated subsequently to the eligible candidates.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Registration</b>
                      <ul>
                        <li>
                          Candidates who have provisionally admitted for the research programme shall register their
                          Ph.D., programme either as Full Time or as Part Time scholars based on their eligibility as
                          per the regulations of MKU.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Miscellaneous</b>
                      <ul>
                        <li>
                          Other regulations related to Course work, duration research (minimum/maximum period, extension
                          and relaxation thereof), change of guide, change of title, cancellation of registration,
                          requirements after registration, Presentation of Seminars, Research publications,
                          pre-submission presentation, submission of synopsis, List of examiners, submission of thesis
                          and plagiarism check will be followed as per the guidelines of MKU.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Guidelines to Researchers/Research Supervisors</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p style="font-weight: 600;"> The researchers/research supervisors are asked to </p>
                  <ul>
                    <li>
                      Submit the application for guideship recognition/research proposals/ progress as well as final
                      project reports to the funding agencies or any other Government/Non-Government organization
                      through CRD.
                    </li>
                    <li> Instruct the research scholars to complete course work, submission of.
                    </li>
                    <li>Motivate the research scholars to publish their research articles or review articles in the peer
                      reviewed journals.
                    </li>
                    <li>
                      Use the approved affiliation in all communications.
                    </li>
                    <li> Check the plagiarism in the research articles and thesis.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Financial Assistance and Incentives
                  </h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <ol>
                    <li>
                      <b>Conferences/Seminars/Workshops
                      </b>
                      <ul>
                        <li>
                          Financial assistance will be provided (Complete/Partial) to Departments/Faculty for conducting
                          International/National/State Level Conferences or Seminars or Workshops.
                        </li>
                        <li>
                          Registration fee/ Travel assistance will be provided (Complete/Partial) for presenting papers
                          in Reputed International/National/State Level Conferences/Seminars by the management for
                          Faculty members, Research Scholars and Students. He/she should be submitted the
                          invitation/acceptance letter for the paper presentation along with the requisition letter.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Seed Money </b>
                      <ul>
                        <li>
                          Seed money will be provided to Faculty members by the Management to support the preparation of
                          research proposals and carrying out preliminary research.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Research Projects</b>
                      <ul>
                        <li>
                          Incentives will be provided to the faculty members who received grants from various funding
                          agencies for his/her Major/Minor/Student Research Project.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <b>Publications</b>
                      <ul>
                        <li>
                          Incentives will be provided to the researchers (faculty members/Research scholars) who publish
                          their research findings in reputed peer reviewed journals (Scopus/Web of Science/UGC Care
                          list). If the Research Paper is jointly published by two or more staff members of our College,
                          the incentive amount may be shared by the staff members.
                        </li>
                        <li>
                          Text books written by staff members of our College and published by any reputed publisher in
                          India or abroad will be eligible for the incentive of <b> Rs. 2,000/-. </b>
                        </li>
                        <li>
                          A cash incentive of <b> Rs. 1,000/- </b> will be granted to the faculty members who have a
                          contribution
                          of Chapter(s) in Edited Book published by any reputed publisher in India or abroad. The book
                          should bear the ISBN recognition. If the Book is jointly published by two or more faculty
                          members of the College, the incentive amount will be shared by them.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>