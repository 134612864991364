<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <h1 class="kingster-page-title">Organization Structure</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="kingster-content-container kingster-container">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper ">
          <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                  style="padding-bottom: 45px;">
                   <img src="assets/images/org_struct.png" style="width: 100%;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  