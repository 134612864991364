<div class="kingster-mobile-header-wrap">
  <div class="kingster-mobile-header kingster-header-background kingster-style-slide kingster-sticky-mobile-navigation "
    id="kingster-mobile-header">
    <div class="kingster-mobile-header-container kingster-container clearfix">
      <div class="kingster-logo  kingster-item-pdlr">
        <div class="kingster-logo-inner">
          <a class="" href="index.html"><img src="../../assets/images/logo.png" alt="" /></a>
        </div>
      </div>
      <div class="kingster-mobile-menu-right">
        <div class="kingster-mobile-menu"><a
            class="kingster-mm-menu-button kingster-mobile-menu-button kingster-mobile-button-hamburger"
            href="#kingster-mobile-menu"><span></span></a>
          <div class="kingster-mm-menu-wrap kingster-navigation-font" id="kingster-mobile-menu" data-slide="right">
            <ul id="menu-main-navigation" class="m-menu">
              <li class="menu-item">
                <a routerLink="/" class="sf-with-ul-pre">Home</a>
              </li>
              <li class="menu-item">
                <a href="https://events.srnmcollege.ac.in" target="_blank" class="sf-with-ul-pre">Events @ SRNMC</a>
              </li>
              <li class="menu-item menu-item-has-children">
                <a href="#">Academics</a>
                <ul class="sub-menu">
                  <li class="menu-item menu-item-has-children">
                    <a href="#">About SRNMC</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/about/history">History of SRNMC</a></li>
                      <li class="menu-item"><a routerLink="/about/vision">Vision and Mission</a></li>
                      <li class="menu-item"><a routerLink="/about/profile">Profile of SRNMC</a></li>
                      <li class="menu-item"><a routerLink="/about/logo">Official Logo of the College</a></li>
                      <li class="menu-item"><a routerLink="/about/profile">Autonomy</a></li>
                      <li class="menu-item"><a routerLink="/naac">NAAC</a></li>
                      <li class="menu-item"><a routerLink="/annualReport">Annual Report</a></li>
                      <li class="menu-item"><a routerLink="/nirf">NIRF Report</a></li>
                      <li class="menu-item"><a routerLink="/organizationStructure">Organization Structure</a></li>
                      <li class="menu-item"><a routerLink="/ictTools">ICT Tools</a></li>
                    </ul>
                  </li>
                  <li class="menu-item menu-item-has-children">
                    <a href="#">Administration</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/principal">Principal</a></li>
                      <li class="menu-item"><a routerLink="/boardOfManagement">Board of Management</a></li>
                      <li class="menu-item"><a routerLink="/statutoryBodies">Statutory Bodies</a></li>
                      <li class="menu-item"><a routerLink="/deans">Deans</a></li>
                      <li class="menu-item"><a routerLink="/dbt">DBT</a></li>
                      <li class="menu-item"><a routerLink="/hostel">Hostel</a></li>
                      <li class="menu-item"><a routerLink="/library">Library</a></li>
                      <li class="menu-item"><a routerLink="/placement">Placement</a></li>
                      <li class="menu-item"><a routerLink="/training">Training</a></li>
                      <li class="menu-item"><a routerLink="/rti" >RTI</a></li>
                    </ul>
                  </li>
                  <li class="menu-item menu-item-has-children">
                    <a href="#">Administrative Staff</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/nonTeachingFaculty/AIDED"> Aided Administrative Staff </a>
                      </li>
                      <li class="menu-item"><a routerLink="/nonTeachingFaculty/UNAIDED"> Un Aided Administrative Staff
                        </a></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="menu-item menu-item-has-children">
                <a href="#">Examination</a>
                <ul class="sub-menu">
                  <li class="menu-item" data-size="60"><a routerLink="/exam">Controller of Examinations</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/examRegulation">Regulation</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/coeDocs/EXAM_FORMS">Forms</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/coeDocs/TIME_TABLE">Time Tables</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/examResults">Results</a></li>
                  <!-- <li class="menu-item" data-size="60"><a routerLink="/results">Hall Tickets</a></li> -->
                  <li class="menu-item" data-size="60"><a routerLink="/coeDocs/QUESTIONS">Question Papers</a></li>
                  <li class="menu-item" data-size="60"><a
                      href="https://storage.srnmcollege.ac.in/srnmcweb/exam_decoder/decoder_setup.exe"
                      target="_blank">Decoder Download</a></li>
                </ul>
              </li>
              <li class="menu-item">
                <a routerLink="/departments" class="sf-with-ul-pre">Departments</a>
              </li>
              <li class="menu-item">
                <a routerLink="/programmes" class="sf-with-ul-pre">Programmes</a>
              </li>
              <li class="menu-item menu-item-has-children">
                <a href="#">Research</a>
                <ul class="sub-menu">
                  <li class="menu-item" data-size="60"><a routerLink="/research">Research</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchPolicy">Research Policy</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchCommittee"> Research Committee </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchCentres"> Research Centres </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchGuides"> Research Guides </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchScholars"> Research Scholars </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchProjects"> Research Projects </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchSeedMoney"> Seed Money </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchSeedMoneyProjects"> Seed Money Projects
                    </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchFundingAgencies"> Funding Agencies </a>
                  </li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchTravelGrant"> Travel Grant </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchEthics">Research Ethics Committee</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchDownloadForms"> Download forms </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/researchContactUs"> Contact us </a></li>
                </ul>
              </li>
              <li class="menu-item">
                <a routerLink="/infrastructure" class="sf-with-ul-pre">Infrastructure</a>
              </li>
              <li class="menu-item">
                <a routerLink="/academicCalander" class="sf-with-ul-pre">Academic Calendar</a>
              </li>
              <li class="menu-item">
                <a routerLink="/nirf" class="sf-with-ul-pre">NIRF</a>
              </li>
              <li class="menu-item menu-item-has-children">
                <a href="#">IQAC</a>
                <ul class="sub-menu">
                  <li class="menu-item" data-size="60"><a routerLink="/iqac">Objectives & Functions of IQAC</a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/iqacCommiteeMember">Core Commitee Members </a>
                  </li>
                  <li class="menu-item" data-size="60"><a routerLink="/iqacMinutesReport"> Minutes AND Action Taken
                      Reports </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/aqarReport"> AQAR Reports </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/stackholderFeedback"> Stakeholder Feedback </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/feedbackActionTakenReport"> Feedback Action Taken Report </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/strategicPlan"> Strategic Plan and Deployment </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/newsLetters"> News Letters </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/bestPractices"> Best Practices </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/institutionalDistinctiveness"> Institutional Distinctiveness </a></li>
                  <li class="menu-item" data-size="60"><a routerLink="/codeOfConduct"> Code of conducts </a></li>
                </ul>
              </li>
              <li class="menu-item">
                <a routerLink="/naac" class="sf-with-ul-pre">NAAC</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
