<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-wrapper " style="padding: 70px 0px 40px 0px;">
      <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
        <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                    <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                      style="border-width: 0px;">
                      <a class="gdlr-core-lightgallery gdlr-core-js "
                        href="http://max-themes.net/demos/kingster/upload/personnel-1.jpg"> 
                        <img
                        src="../assets/images/slide/principal.JPG" width="700"
                          height="450" alt="" /> <span class="gdlr-core-image-overlay "> <i
                            class="gdlr-core-image-overlay-icon gdlr-core-size-22 fa fa-search"> </i> </span> 
                        </a>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-personnel-info-item gdlr-core-item-pdb gdlr-core-item-pdlr clearfix"
                    style="padding-bottom: 17px ;">
                    <div class="gdlr-core-personnel-info-item-list-wrap">
                      <div class="kingster-personnel-info-list kingster-type-social-shortcode">
                        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                          style="padding-bottom: 0px ;">
                          <a href="#" target="_blank" class="gdlr-core-social-network-icon" title="facebook"
                            style="font-size: 18px ;color: #50bd77 ;">
                            <i class="fa fa-facebook"> </i>
                          </a>
                          <a href="#" target="_blank" class="gdlr-core-social-network-icon" title="linkedin"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"> <i class="fa fa-linkedin">
                            </i>
                          </a>
                          <a href="#url" target="_blank" class="gdlr-core-social-network-icon" title="twitter"
                            style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;"> <i class="fa fa-twitter"> </i>
                          </a> </div>
                      </div>
                      <div class="kingster-personnel-info-list kingster-type-email"> <i
                          class="kingster-personnel-info-list-icon fa fa-envelope-open"> </i>nsganeshram@gmail.com
                      </div>
                      <!-- <div class="kingster-personnel-info-list kingster-type-phone"> <i class="kingster-personnel-info-list-icon fa fa-phone"> </i>+1-2351-2361-355</div> -->
                      <div class="kingster-personnel-info-list kingster-type-location"> <i
                          class="kingster-personnel-info-list-icon fa fa-location-arrow"> </i>Sri
                        Ram Nagar, Kovilpatti</div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border" href="#"
                      style="font-size: 13px ;padding: 11px 24px 15px 27px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;">
                      <span class="gdlr-core-content">Download CV</span> <i
                        class="gdlr-core-pos-right fa fa-file-pdf-o"> </i> </a> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gdlr-core-pbf-column gdlr-core-column-40">
            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
              style="margin: 0px 0px 20px 0px;padding: 0px 0px 0px 0px;">
              <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 34px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #161616 ;">
                        Dr P.Rajaguru</h3>
                    </div> <span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                      style="font-size: 20px ;font-style: normal ;color: #6c6c6c ;">Principal</span>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                    style="padding-bottom: 6px ;">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 21px ;text-transform: none ;color: #3db166 ;">
                      <p>If you&#8217;re an educational professional who are looking to progress
                        into management and consultancy, or an educational planning or
                        development role, this is the best degree for you.</p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                    style="margin-bottom: 55px ;">
                    <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                      style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                        Biography</h3>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                    style="padding-bottom: 20px ;">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 17px ;letter-spacing: 0px ;text-transform: none ;">
                      <p>Accounting can be divided into several fields including financial
                        accounting, management accounting, external auditing, tax accounting and
                        cost accounting. Accounting information systems are designed to support
                        accounting functions and related activities. Financial accounting
                        focuses on the reporting of an organization&#8217;s financial
                        information, including the preparation of financial statements, to the
                        external users of the information, such as investors.</p>
                      <p>Currently, Professor John is focusing his research on individual investor
                        behavior and company incentives to better understand how disclosure
                        affects stock returns and to uncover possible mispricing. Nevertheless,
                        John is conducting an new way of examination of analyst access to
                        information, and he is investigating market reactions to natural issues
                        and the impact that would occur on individual firms.</p>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                    style="margin-bottom: 45px ;">
                    <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                      style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                        Education</h3>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                    <ul>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 4px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                            style="color: #6b6b6b ;font-size: 16px ;width: 16px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">Ph.D.,
                            Accounting & Finance, University of Strathclyde, Scotland</span>
                        </div>
                      </li>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 4px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                            style="color: #6b6b6b ;font-size: 16px ;width: 16px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">M.S.F., Finance,
                            Boston College, Chestnut Hill, MA, 2006</span> </div>
                      </li>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 4px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                            style="color: #6b6b6b ;font-size: 16px ;width: 16px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">M.B.A.,
                            Taxation, Golden Gate University, San Francisco, CA, 1983</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                    style="margin-bottom: 55px ;">
                    <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                      style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                        Publications</h3>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                    <ul>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-hand-o-right"
                            style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">The primary
                            market value innovations of newly public firms</span> </div>
                      </li>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-hand-o-right"
                            style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">Auditor Tenure
                            and Quality of Financial Report</span> </div>
                      </li>
                      <li class=" gdlr-core-skin-divider" style="margin-bottom: 13px ;"> <span
                          class="gdlr-core-icon-list-icon-wrap" style="margin-top: 1px ;"> <i
                            class="gdlr-core-icon-list-icon fa fa-hand-o-right"
                            style="color: #6b6b6b ;font-size: 22px ;width: 22px ;"> </i> </span>
                        <div class="gdlr-core-icon-list-content-wrap"> <span class="gdlr-core-icon-list-content"
                            style="font-size: 17px ;text-transform: none ;">Unexpected Fees
                            and the Prediction of Material Weaknesses </span> </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align"
                    style="margin-bottom: 55px ;">
                    <div class="gdlr-core-divider-line gdlr-core-skin-divider"
                      style="border-color: #3db166 ;border-bottom-width: 3px ;"> </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 20px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #464646 ;">
                        Skills</h3>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-skill-bar-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-size-small gdlr-core-type-rectangle">
                    <div class="gdlr-core-skill-bar">
                      <div class="gdlr-core-skill-bar-head gdlr-core-title-font"> <span
                          class="gdlr-core-skill-bar-title">Business Planning</span> <span
                          class="gdlr-core-skill-bar-right">90%</span> </div>
                      <div class="gdlr-core-skill-bar-progress">
                        <div class="gdlr-core-skill-bar-filled gdlr-core-js" data-width="90">
                        </div>
                      </div>
                    </div>
                    <div class="gdlr-core-skill-bar">
                      <div class="gdlr-core-skill-bar-head gdlr-core-title-font"> <span
                          class="gdlr-core-skill-bar-title">Business Law</span> <span
                          class="gdlr-core-skill-bar-right">70%</span> </div>
                      <div class="gdlr-core-skill-bar-progress">
                        <div class="gdlr-core-skill-bar-filled gdlr-core-js" data-width="70">
                        </div>
                      </div>
                    </div>
                    <div class="gdlr-core-skill-bar">
                      <div class="gdlr-core-skill-bar-head gdlr-core-title-font"> <span
                          class="gdlr-core-skill-bar-title">Lecture Skill</span> <span
                          class="gdlr-core-skill-bar-right">100%</span> </div>
                      <div class="gdlr-core-skill-bar-progress">
                        <div class="gdlr-core-skill-bar-filled gdlr-core-js" data-width="100">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
