import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-institutional-distinctiveness',
  templateUrl: './iqac-institutional-distinctiveness.component.html',
  styleUrls: ['./iqac-institutional-distinctiveness.component.scss']
})
export class IqacInstitutionalDistinctivenessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
