import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-iqac-commitee-member',
  templateUrl: './iqac-commitee-member.component.html',
  styleUrls: ['./iqac-commitee-member.component.scss']
})
export class IqacCommiteeMemberComponent implements OnInit {

  public committeeMembers: any;
  constructor(
    public webService: WebServiceService,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {}

  loadCommitee() {
    this.webService.getIQACMembers().subscribe((data) => {
      const sortedData = _.orderBy(data, (o: any) => {
        return parseInt(o.orderNo);
      }, ['asc']);
      this.committeeMembers = sortedData;
    });
  }
}
