import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-travel-grant',
  templateUrl: './research-travel-grant.component.html',
  styleUrls: ['./research-travel-grant.component.scss']
})
export class ResearchTravelGrantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
