import { Component, ElementRef, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exam-documents',
  templateUrl: './exam-documents.component.html',
  styleUrls: ['./exam-documents.component.scss']
})
export class ExamDocumentsComponent implements OnInit {

  public typeTitle: any;
  public type: any;
  public documents: any;
  constructor(
    public webService: WebServiceService,
    private route: ActivatedRoute,
  ) { 
    this.route.url.subscribe(url =>{
      this.loadCOEDocument();
    });
    
  }

  ngOnInit(): void {
   
  }

  loadCOEDocument() {
    this.type = this.route.snapshot.paramMap.get('type');
    if(this.type === 'TIME_TABLE') {
      this.typeTitle = 'Time Table'
    }
    if(this.type === 'EXAM_FORMS') {
      this.typeTitle = 'Forms'
    }
    if(this.type === 'QUESTIONS') {
      this.typeTitle = 'Questions'
    }
    this.webService.getCOEDocument(this.type).subscribe((data) => {
      this.documents = data;
    });
  }

}
