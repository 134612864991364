<div class="kingster-page-title-wrap  kingster-style-custom kingster-center-align"
  style="background-image: url(./../../assets/images/slide/IMG_2282.jpeg) ;" *ngIf="departmentData">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-bottom-gradient"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr" style="padding-top: 400px ;padding-bottom: 60px ;">
      <div class="kingster-page-caption">Faculty Members</div>
      <h1 class="kingster-page-title" *ngIf="departmentData"
        style="font-size: 48px ;font-weight: 700 ;text-transform: none ;letter-spacing: 0px ;color: #ffffff ;">
        {{departmentData.name}}</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="gdlr-core-page-builder-body">
    <div class="gdlr-core-pbf-sidebar-wrapper ">
      <div class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
        <div
          class="gdlr-core-pbf-sidebar-content  gdlr-core-column-40 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-left"
          style="padding: 40px 0px 0px 0px;">
          <div class="gdlr-core-pbf-sidebar-content-inner" data-skin="Personnel">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                <div *ngIf="!departmentStaffData || !departmentStaffData.length" style="text-align: center;">
                  <h3> No Faculties Added </h3>
                </div>
                <div *ngIf="departmentStaffData && departmentStaffData.length">
                  <div *ngFor="let staffData of departmentStaffData;"
                    class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                    <div class="gdlr-core-personnel-list clearfix">
                      <div
                        class="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-hover-element gdlr-core-zoom-on-hover">
                        <a routerLink="/profile/{{getUserName(staffData.staffData.email)}}"><img
                            [src]="webService.getUserImage(staffData.staffData)" alt="" width="500" height="500"
                            title="personnel-1"></a>
                        <div class="gdlr-core-hover-opacity"></div>
                      </div>
                      <div class="gdlr-core-personnel-list-content-wrap">
                        <h3 class="gdlr-core-personnel-list-title"
                          style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;"><a
                            routerLink="/profile/{{getUserName(staffData.staffData.email)}}">
                            {{staffData.staffData.title}} {{staffData.staffData.lastName}}
                            {{staffData.staffData.firstName}} <small style="font-size: 16px;">{{staffData.staffData.qualification}}</small> </a>
                        </h3>
                        <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                          style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                          {{staffData.staffData.designation}}</div>
                        <div class="gdlr-core-personnel-list-social" style="padding-top: 10px;">
                          <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                            style="padding-bottom: 0px ;">
                            <a [href]="webService.transform(staffData.staffData.facebookLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="facebook"
                              style="font-size: 18px ;color: #50bd77 ;" *ngIf="staffData.staffData.facebookLink">
                              <i class="fa fa-facebook"></i>
                            </a>
                            <a [href]="webService.transform(staffData.staffData.linkedInLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="linkedin"
                              style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;  margin-left: 14px"
                              *ngIf="staffData.staffData.linkedInLink"><i class="fa fa-linkedin"></i></a>
                            <a [href]="webService.transform(staffData.staffData.twitterLink)" target="_blank"
                              class="gdlr-core-social-network-icon" title="twitter"
                              style="font-size: 18px ;color: #50bd77 ;margin-left: 14px ;  margin-left: 14px"
                              *ngIf="staffData.staffData.twitterLink"><i class="fa fa-twitter"></i>
                            </a>
                          </div>
                        </div>
                        <a class="gdlr-core-personnel-list-button gdlr-core-button"
                          routerLink="/profile/{{getUserName(staffData.staffData.email)}}">More Detail</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="gdlr-core-pbf-sidebar-right gdlr-core-column-extend-right  kingster-sidebar-area gdlr-core-column-20 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
          style="padding: 35px 0px 30px 0px;">
          <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
            <div id="text-23" class="widget widget_text kingster-widget">
              <div class="textwidget">
                <div class="gdlr-core-widget-box-shortcode "
                  style="color: #ffffff ;padding: 30px 45px;background-color: #192f59 ;">
                  <div class="gdlr-core-widget-box-shortcode-content" *ngIf="departmentData">
                    <h3 style="font-size: 20px; color: #fff; margin-bottom: 25px;">Department Contact Info</h3>
                    <p>
                      <span style="color: #3db166; font-size: 18px; font-weight: 600;">{{departmentData.name}}</span>
                      <br />
                      <span style="font-size: 15px;">
                        Sri S.R.N.M College, <br />
                        Sattur, <br />
                        Virudhunagar (DT), <br />
                        Tamil Nadu, India.
                        <br>
                        <b>Pin:</b> 626 203
                      </span></p>
                    <span *ngIf="departmentData && departmentData.primaryContactNumber">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Primary Contact </span>
                      <br />
                      <p><span style="font-size: 15px;">{{departmentData.primaryContactNumber}} </span></p>
                    </span>
                    <span *ngIf="departmentData && departmentData.secondaryContactNumber">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Secondary Contact </span>
                      <br />
                      <p><span style="font-size: 15px;">{{departmentData.secondaryContactNumber}} </span></p>
                    </span>
                    <span *ngIf="departmentData.email">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Email</span>
                      <p><span style="font-size: 15px;">{{departmentData.email}}</span></p>
                    </span>
                    <span *ngIf="departmentData.departmentURL">
                      <span style="color: #3db166; font-size: 14px; font-weight: 500;">Department Site</span>
                      <p><span style="font-size: 15px;">{{departmentData.departmentURL}}</span></p>
                    </span>
                    <span
                      *ngIf="departmentData.twitterLink || departmentData.linkedInLink || departmentData.facebookLink">
                      <span class="gdlr-core-space-shortcode" style="margin-top: 40px ;"></span>
                      <h3 style="font-size: 20px; color: #fff; margin-bottom: 15px;">Social Info</h3>
                      <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                        style="padding-bottom: 0px ;">
                        <span *ngIf="departmentData.facebookLink">
                          <a [href]="webService.transform(departmentData.facebookLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="facebook" style="color: #3db166 ;"
                            rel="noopener">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </span>
                        <span *ngIf="departmentData.linkedInLink">
                          <a [href]="webService.transform(departmentData.linkedInLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="linkedin" style="color: #3db166 ;"
                            rel="noopener">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </span>
                        <span *ngIf="departmentData.twitterLink">
                          <a [href]="webService.transform(departmentData.twitterLink)" target="_blank"
                            class="gdlr-core-social-network-icon" title="twitter" style="color: #3db166 ;"
                            rel="noopener">
                            <i class="fa fa-twitter"></i>
                          </a>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>