<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Funding Agencies</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Funding Agencies</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>
                    The links to the homepages of various funding agencies providing the details of R&D
                    proposals/formats and related details are provided here:
                  </p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.dst.gov.in/call-for-proposals" class="gdlr-core-course-item-link doc-list-item"
                    style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> Department of Science and Technology
                      (DST) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="https://www.ugc.ac.in/ugc_schemes/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> University Grants Commission (UGC)
                    </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="https://www.aicte-india.org/schemes/research-innovations-development-schemes"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> All India Council for Technical
                      Education (AICTE)
                      Board </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://csirhrdg.res.in/resg/Res_grants.htm" class="gdlr-core-course-item-link doc-list-item"
                    style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> The Council of Scientific & Industrial
                      Research (CSIR) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="https://www.drdo.gov.in/drdo/English/index.jsp?pg=thrustareas.jsp"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> Defence Research and Development
                      Organization (DRDO) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="https://brns.res.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title"> Board of Research in Nuclear Sciences
                      (BRNS) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.dbtindia.nic.in/funding-mechanism/call/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Department of Biotechnology </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.scienceandtech.cmpdi.co.in/index.php"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Ministry of Coal & Mines </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.icmr.nic.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indian Council of Medical Research </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.moes.gov.in/content/brief-extramural-support"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Ministry of Earth Sciences-Extramural support </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="https://www.isro.gov.in/sponsored-research-respond"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Department of Space Indian Space Research Organisation (ISRO)Sponsored Research (RESPOND)
                    </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://meity.gov.in/content/schemes-policies" class="gdlr-core-course-item-link doc-list-item"
                    style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Ministry of Electronics and Information Technology (MEIT) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.mnre.gov.in/schemes/r-d/rd-formats/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Ministry of New and Renewable Energy (MNRE) </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank"
                    href="http://www.cpri.in/r-a-d-schemes/research-scheme.html"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Central Power Research Institute, Ministry of Power (CPRI, MoP)</span><span
                      style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="https://inae.in/mentoring-schemes-2/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indian National Academy of Engineering (INAE)</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.iusstf.org/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indo-US Science and Technology Forum (IUSSTF)</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.tanscst.nic.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Tamil Nadu State Council for Science and Technology </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.iuac.res.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Inter University Accelerator Centre, New Delhi </span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.nbhm.dae.gov.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      National Board for Higher Mathematics</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://icssr.org/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indian Council for Social Science Research</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://ichr.ac.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indian Council for Historical Research</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
              <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-title-item-caption-top"
                style="padding-bottom: 40px;">
                <div class="gdlr-core-course-item-list"><a target="_blank" href="http://www.insaindia.res.in/"
                    class="gdlr-core-course-item-link doc-list-item" style="cursor: pointer; display: block;"><span
                      class="gdlr-core-course-item-title gdlr-core-skin-title">
                      Indian National Science Academy</span><span style="float: right;"><i
                        class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>