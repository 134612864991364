<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Research Ethics Committee</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Code of Ethics</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p> Sri S. Ramasamy Naidu Memorial College has a well-defined Research policy with professional
                    standards. It adheres to research ethics of the UGC. Academic Integrity is followed with research
                    standards adhering to the values of Honesty, trust, honesty, fairness, respect, responsibility and
                    legality. Research Community should follow the legal norms related to the conduct and publication of
                    research in terms of copyrights respecting Intellectual Property Rights of Third Parties, Terms and
                    Conditions in Regulating Research resources and the laws of the State and the Country. In order to
                    ensure the ethical values, the Ethics Committee has the following roles and responsibilities:</p>
                  <h6 style="color: #223d71 ; font-weight: 600 ;"> Roles and Responsibilities </h6>
                  <ul>
                    <li> To inculcate ethical values and research policies of the Institution. </li>
                    <li> To utilize the resources and equipment in accordance with the terms and conditions of the
                      Research Centres. </li>
                    <li>To ensure that correct data and research results should be published in Journals or Conference
                      Proceedings.</li>
                    <li>To ensure that the researchers strictly adhere to the regulations of the research ethics and if
                      plagiarism, falsification, fabrication, or misrepresentation of reports are observed, it will be
                      reported to the Head of the Institution for necessary action.</li>
                    <li>To ensure that Proper acknowledgement/Credit is given to Funding Agencies /Collaborative
                      research activities.</li>
                    <li>To ensure that safety measures are practised in all the Research activities</li>
                    <li> To ensure that the Laboratory wastages are disposed properly.</li>
                    <li> To maintain transparency in the use and disbursement of funds in the Research centres. </li>
                    <li> To ensure that the Research Supervisors have consistently monitored the Plagiarism Report of
                      the research findings before the submission of the articles/theses.</li>
                    <li>To ensure that the amendments by the UGC/University/Institutions regarding the research ethics
                      are adhered by the researchers. </li>
                    <li>To provide guidance and academic support to Scholars on ethical issues in respect of teaching,
                      research and other academic activities.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Research Ethics Committee</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <div style="overflow-x: auto;">
                    <table style="min-width: 835px;">
                      <tbody>
                        <tr>
                            <th style="width: 10%;"> S.No</th>
                            <th style="width: 40%;"> Name </th>
                            <th style="width: 40%;"> Designation </th>
                          </tr>
                        <tr>
                          <td>1</td>
                          <td>Dr. P. Rajaguru</td>
                          <td>Principal/Chairperson</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Dr P.Sundara Venkatesh</td>
                          <td>Dean, Research and Development</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Dr A.Rajeshkanna</td>
                          <td>IQAC Coordinator</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Dr P.Rajaguru</td>
                          <td>Controller of Examinations</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Dr S.Nattar</td>
                          <td>College Librarian</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Dr P.Thamilmaran</td>
                          <td>Head &amp; Associate Professor Department of Physics</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Dr K.Krishnaveni</td>
                          <td>Head &amp; Associate Professor Department of Computer Science</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Dr R.Poongothai</td>
                          <td>Head &amp; Associate Professor Department of Tamil</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Dr S.Brinda Umamaheswari</td>
                          <td>Head &amp; Associate Professor Department of History</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Dr V.Kanagavel</td>
                          <td>Head &amp; Assistant Professor Department of Commerce</td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Dr V.Thiripurasundari</td>
                          <td>Assistant Professor<br>Department of Mathematics</td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Dr S.Jayanthi</td>
                          <td>Assistant Professor<br>Department of English</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>