import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-research-centres',
  templateUrl: './research-centres.component.html',
  styleUrls: ['./research-centres.component.scss']
})
export class ResearchCentresComponent implements OnInit {

  constructor(
    private router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  navigate(id: any) {
    this.router.navigate(['department', id]);
  }

}
