<div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="kingster-content-container kingster-container" *ngIf="eventData">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper ">
          <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                  style="padding-bottom: 45px ;">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                      <div class="gdlr-core-pbf-sidebar-content-inner">
                        <div class="gdlr-core-pbf-element">
                          <div
                            class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                            <div class="gdlr-core-title-item-title-wrap clearfix">
                              <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                style="font-size: 27px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;">
                                {{eventData.title}}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="gdlr-core-pbf-element" *ngIf="eventData && eventData.description">
                          <div
                            class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                            <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ;">
                              <span [innerHTML]="eventData.description"></span>
                            </div>
                          </div>
                        </div>
                        <span *ngIf="eventData && eventData.documents && eventData.documents.length">
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                              style="padding-bottom: 40px ;">
                              <div class="gdlr-core-title-item-title-wrap clearfix">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                  style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                                  Documents</h3>
                                <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                                  style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                              </div>
                            </div>
                          </div>
                          <div class="gdlr-core-pbf-element">
                            <div
                              class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                              style="padding-bottom: 45px ;">
                              <div *ngFor="let doc of eventData.documents;">
                                <app-file-viewer [src]="webService.getBucketURL(doc.src)" title="{{doc.title}}"></app-file-viewer>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  