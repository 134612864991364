<header
  class="kingster-header-wrap kingster-header-style-plain  kingster-style-menu-right kingster-sticky-navigation kingster-style-fixed"
  data-navigation-offset="75px">
  <div class="kingster-header-background"></div>
  <div class="kingster-header-container  kingster-container">
    <div class="kingster-header-container-inner clearfix">
      <div class="kingster-logo  kingster-item-pdlr">
        <div class="kingster-logo-inner">
          <a class="" routerLink="/">
            <img src="../../assets/images/logo.png" alt="" />
          </a>
        </div>
      </div>
      <div class="kingster-navigation kingster-item-pdlr clearfix ">
        <div class="kingster-main-menu" id="kingster-main-menu">
          <ul id="menu-main-navigation-1" class="sf-menu">
            <li class="menu-item menu-item-home menu-item-has-children kingster-normal-menu">
              <a routerLink="/" class="sf-with-ul-pre">Home</a>
            </li>
            <li class="menu-item menu-item-has-children kingster-mega-menu">
              <a class="sf-with-ul-pre">Academics</a>
              <div class="sf-mega sf-mega-full megaimg">
                <ul class="sub-menu">
                  <li class="menu-item menu-item-has-children" data-size="15">
                    <a class="sf-with-ul-pre">About SRNMC</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/about/history">History of SRNMC</a></li>
                      <li class="menu-item"><a routerLink="/about/vision">Vision and Mission</a></li>
                      <li class="menu-item"><a routerLink="/about/profile">Profile of SRNMC</a></li>
                      <li class="menu-item"><a routerLink="/about/logo">Official Logo of the College</a></li>
                      <li class="menu-item"><a routerLink="/about/profile">Autonomy</a></li>
                      <li class="menu-item"><a routerLink="/naac">NAAC</a></li>
                      <li class="menu-item"><a routerLink="/annualReport">Annual Report</a></li>
                      <li class="menu-item"><a routerLink="/nirf">NIRF Report</a></li>
                      <li class="menu-item"><a routerLink="/organizationStructure">Organization Structure</a></li>
                      <li class="menu-item"><a routerLink="/ictTools">ICT Tools</a></li>
                    </ul>
                  </li>
                  <li class="menu-item menu-item-has-children" data-size="15"><a
                      class="sf-with-ul-pre">Administration</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/principal">Principal</a></li>
                      <li class="menu-item"><a routerLink="/boardOfManagement">Board of Management</a></li>
                      <li class="menu-item"><a routerLink="/statutoryBodies">Statutory Bodies</a></li>
                      <li class="menu-item"><a routerLink="/deans">Deans</a></li>
                      <li class="menu-item"><a routerLink="/dbt">DBT</a></li>
                      <li class="menu-item"><a routerLink="/hostel">Hostel</a></li>
                      <li class="menu-item"><a routerLink="/library">Library</a></li>
                      <li class="menu-item"><a routerLink="/placement">Placement</a></li>
                      <li class="menu-item"><a routerLink="/training">Training</a></li>
                      <li class="menu-item"><a routerLink="/rti">RTI</a></li>
                    </ul>
                  </li>
                  <li class="menu-item menu-item-has-children" data-size="15">
                    <a  class="sf-with-ul-pre">Administrative Staff</a>
                    <ul class="sub-menu">
                      <li class="menu-item"><a routerLink="/nonTeachingFaculty/AIDED"> Aided Administrative Staff </a></li>
                      <li class="menu-item"><a routerLink="/nonTeachingFaculty/UNAIDED"> Un Aided Administrative Staff </a></li>
                    </ul>
                  </li>
                  <li class="menu-item" data-size="15">
                    <div class="kingster-mega-menu-section-content"><img src="../../assets/images/logo.png"
                        id="img_fd84_0" alt="" />
                      <span id="span_fd84_0">Academic programmes in the college are 34+. The college has 3500+ students, 135+ faculty
                        members & 48.54 acres of campus </span>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="menu-item menu-item-has-children kingster-normal-menu">
              <a class="sf-with-ul-pre">Examination</a>
              <ul class="sub-menu">
                <li class="menu-item" data-size="60"><a routerLink="/exam">Controller of Examinations</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/examRegulation">Regulation</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/coeDocs/EXAM_FORMS">Forms</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/coeDocs/TIME_TABLE">Time Tables</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/examResults">Results</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/examHallTickets">Hall Tickets</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/coeDocs/QUESTIONS">Question Papers</a></li>
                <li class="menu-item" data-size="60"><a
                    href="https://storage.srnmcollege.ac.in/srnmcweb/exam_decoder/decoder_setup.exe"
                    target="_blank">Decoder Download</a></li>
              </ul>
            </li>
            <li class="menu-item menu-item-home menu-item-has-children kingster-normal-menu">
              <a routerLink="/departments" class="sf-with-ul-pre">Departments</a>
            </li>
            <li class="menu-item menu-item-home menu-item-has-children kingster-normal-menu">
              <a routerLink="/programmes" class="sf-with-ul-pre">Programmes</a>
            </li>
            <li class="menu-item menu-item-home menu-item-has-children kingster-normal-menu">
              <a routerLink="/research" class="sf-with-ul-pre">Research</a>
            </li>
            <li class="menu-item menu-item-home menu-item-has-children kingster-normal-menu">
              <a routerLink="/infrastructure" class="sf-with-ul-pre">Infrastructure</a>
            </li>
            <!-- <li class="menu-item menu-item-has-children kingster-normal-menu">
              <a  class="sf-with-ul-pre">IQAC</a>
              <ul class="sub-menu">
                <li class="menu-item" data-size="60"><a routerLink="/iqacCell">IQAC Cell</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/iqacMembers">IQAC Members</a></li>
                <li class="menu-item" data-size="60"><a routerLink="/iqacReports">IQAC Reports</a></li>
                <li class="menu-item"><a routerLink="/naac"> NAAC</a></li>
                <li class="menu-item"><a routerLink="/annualReport"> Annual Report</a></li>
                <li class="menu-item"><a routerLink="/nirfReport"> NIRF Reports </a></li>
                <li class="menu-item" data-size="60"><a routerLink="/iqacContactUs">Contact IQAC</a></li>
              </ul>
            </li> -->
          </ul>
          <div class="kingster-navigation-slide-bar" id="kingster-navigation-slide-bar"></div>
        </div>
      </div>
    </div>
  </div>
</header>