import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exam-hallticket-list',
  templateUrl: './exam-hallticket-list.component.html',
  styleUrls: ['./exam-hallticket-list.component.scss']
})
export class ExamHallTicketListComponent implements OnInit {

  public resultData: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
  ) { 
  }

  ngOnInit(): void {
    this.webService.getPublishedHallTickets().subscribe((data: any) => {
      this.resultData = data;
    });
  }

  openResult(result: any) {
      this.router.navigate(['hallTicket', result.hallTicketCol])
  }

}
