import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class WebServiceService {

  public BUCKET_URL = `${environment.storageUrl}srnmc/`;

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer
  ) {}

  public getUserImage(userData: any) {
    let userImage = "";
    if(userData.userPhotoUrl && userData.userPhotoUrl !== "") {
      userImage = this.BUCKET_URL + userData.userPhotoUrl;
    }

    if(userImage === "" && userData.googlePhotoUrl && userData.googlePhotoUrl !== "") {
      userImage = userData.googlePhotoUrl;
    }

    if(userImage === "") {
      userImage = "assets/user-avathar.png";
    }
    return userImage;
  }
  
  public getAlumniImage(userData: any) {
    let userImage = "";
    if(userData.profilePic && userData.profilePic !== "") {
      userImage = this.BUCKET_URL + userData.profilePic;
    }

    if(userImage === "") {
      userImage = "assets/user-avathar.png";
    }
    return userImage;
  }
  
  public getDepartmentCover(department: any) {
    let userImage = "";
    if(department.coverImg && department.coverImg !== "") {
      userImage = this.BUCKET_URL + department.coverImg;
    }

    if(userImage === "") {
      userImage = "assets/images/slide/IMG_2282.jpeg";
    }
    return userImage;
  }

  public getBucketURL(src: string) {
    let userImage = "";
    userImage = this.BUCKET_URL + src;
    return userImage;
  }

  public transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  public safeHtml(html: any) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  public getHomeSlideImages() {
    return this.http.get<any>(`${environment.apiUrl}getHomeSlideImages`);
  }

  public getCommittees() {
    return this.http.get<any>(`${environment.apiUrl}getCommittees`);
  }

  public getCommitteeMembers(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getCommitteeMembers`, {id});
  } 

  public getCOEDocument(type: any) {
    return this.http.post<any>(`${environment.apiUrl}getCOEDocument`, {type});
  }

  public getInfraAlbums() {
    return this.http.get<any>(`${environment.apiUrl}getInfraAlbums`);
  }
  
  public getInfraAlbum(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getInfraAlbum`, {id});
  }

  public getNewsData(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getNewsData`, {id});
  }
  
  public getNewsByType(type: any) {
    return this.http.post<any>(`${environment.apiUrl}getNewsByType`, {type});
  }
  
  public getDepartmentNews(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getDepartmentNews`, {id});
  }

  public getDayOrderEventDataByDate(startDate: any) {
    return this.http.post<any>(`${environment.apiUrl}getDayOrderEventDataByDate`, {startDate});
  }

  public getDepartmentEvents(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getApprovedDepartmentEventData`, {id});
  }

  public getAcademicEvents() {
    return this.http.get<any>(`${environment.apiUrl}getAcademicEventData`);
  }
  
  public getAllDepartmentEvents() {
    return this.http.get<any>(`${environment.apiUrl}getAllApprovedDepartmentEvents`);
  }

  public getDayOrders() {
    return this.http.get<any>(`${environment.apiUrl}getDayOrderData`);
  }

  public getCalendarNotes() {
    return this.http.get<any>(`${environment.apiUrl}getCalendarNoteData`);
  }

  public getEvenData(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getEventData`, {id});
  }

  public getEventAlbums(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getEventAlbums`, {id});
  }
 
  public getDepartmentProfile(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getDepartmentProfileInfo`, {id});
  }
 
  public getDepartmentProfileStaffInfo(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getDepartmentProfileStaffInfo`, {id});
  }

  public getAllStaffFormForSite(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getAllStaffFormForSite`, {id});
  }

  public getAllDepartments() {
    return this.http.get<any>(`${environment.apiUrl}getAllDepartmentProfiles`);
  }

  public nonTeachingStaffInfo(category: string) {
    return this.http.post<any>(`${environment.apiUrl}nonTeachingStaffInfo`, {category});
  }
  
  public getUserProfileByEmail(email: string) {
    return this.http.post<any>(`${environment.apiUrl}getUserProfileByEmail`, {email});
  }


  public getStaffExpInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffExpInfo`, {staffId});
  } 
  
  public getStaffEduInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffEduInfo`, {staffId});
  } 
  
  public getStaffProjectInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffProjectInfo`, {staffId});
  } 
  
  public getStaffPublicationInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffPublicationInfo`, {staffId});
  } 

  public getStaffPresentationInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffPresentationInfo`, {staffId});
  } 

  public getStaffOrganizedInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffOrganizedInfo`, {staffId});
  } 
 
  public getStaffParticipationInfo(staffId: any) {
    return this.http.post<any>(`${environment.apiUrl}getStaffParticipationInfo`, {staffId});
  } 
  
  public getAlumniByDeptId(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getAlumniByDeptId`, {id});
  } 
  
  public getIQACMembers() {
    return this.http.get<any>(`${environment.apiUrl}getIQACMembers`);
  }

  public getAQARReports() {
    return this.http.get<any>(`${environment.apiUrl}getAQARReports`);
  }

  public getIQACMinutesReports() {
    return this.http.get<any>(`${environment.apiUrl}getIQACMinutesReports`);
  }

  public getPublishedResults() {
    return this.http.get<any>(`${environment.apiUrl}getPublishedResults`);
  }

  public getPublishedHallTickets() {
    return this.http.get<any>(`${environment.apiUrl}getPublishedHallTickets`);
  }
  
  public getResearchGuides() {
    return this.http.get<any>(`${environment.apiUrl}getResearchGuides`);
  }
 
  public getResearchProjects() {
    return this.http.get<any>(`${environment.apiUrl}getResearchProjects`);
  }
  
  public getSeedMoneyProjects() {
    return this.http.get<any>(`${environment.apiUrl}getSeedMoneyProjects`);
  }

  public getDepartmentData(id: any) {
    return this.http.post<any>(`${environment.apiUrl}getDepartmentData`, {id});
  }

  public getCurrentDate() {
    return this.http.get<any>(`${environment.apiUrl}getCurrentDate`);
  }

  public checkPublishedResult(resultCol: any) {
    return this.http.post<any>(`${environment.apiUrl}checkPublishedResult`, {resultCol});
  } 
  
  public checkPublishedHallTicket(hallTicketCol: any) {
    return this.http.post<any>(`${environment.apiUrl}checkPublishedHallTicket`, {hallTicketCol});
  } 
  
  public checkResult(resultCol: any, regNo: any) {
    return this.http.post<any>(`${environment.apiUrl}checkResult`, {resultCol, regNo});
  } 
 
  public checkHallTicket(hallTicketCol: any, regNo: any) {
    return this.http.post<any>(`${environment.apiUrl}checkHallTicket`, {hallTicketCol, regNo});
  } 
 
  public getAllAlumni() {
    return this.http.get<any>(`${environment.apiUrl}getAllAlumni`);
  }

  public downloadFile(url: any): any {
    return this.http.get(url, {
        responseType: 'blob',
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  public downloadPDFFromHTML(data: any) {
    return this.http.post(`${environment.apiUrl}downloadPDFFromHTML`, data, {
      responseType: 'blob'
    }).pipe(
      map((result: any) => {
        return result;
      })
    );;
  }

  public saveAdmissionEnquiry(data: any) {
    return this.http.post<any>(`${environment.apiUrl}saveAdmissionEnquiry`, data);
  } 

}

