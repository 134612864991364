<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <h1 class="kingster-page-title">Academic Annual Report</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class="kingster-content-container kingster-container">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper ">
          <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                  style="padding-bottom: 45px ;">
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2021-2022.pdf" title="Annual Report 2021-2022"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2020-2021.pdf" title="Annual Report 2020-2021"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2019-2020.pdf" title="Annual Report 2019-2020"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2018-2019.pdf" title="Annual Report 2018-2019"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2017-2018.pdf" title="Annual Report 2017-2018"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual-Report-2016-2017.pdf" title="Annual Report 2016-2017"></app-file-viewer>
                  <!-- <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual%20Report%202010-2011.pdf" title="Annual Report 2015-2016"></app-file-viewer> -->
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/ANNUAL%20REPORT%20-%202014%20%E2%80%93%202015.pdf" title="Annual Report 2014-2015"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/ANNUAL%20REPORT%202013-14.pdf" title="Annual Report 2013-2014"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/ANNUAL%20REPORT%202012-13.pdf" title="Annual Report 2012-2013"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/ANNUAL%20REPORT%20%E2%80%93%202011-2012.pdf" title="Annual Report 2011-2012"></app-file-viewer>
                  <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/annual_report/Annual%20Report%202010-2011.pdf" title="Annual Report 2010-2011"></app-file-viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  