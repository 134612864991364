import { RTIComponent } from './rti/rti.component';
import { OrginizationStructureComponent } from './orginization-structure/orginization-structure.component';
import { IctToolsComponent } from './ict-tools/ict-tools.component';
import { TrainingComponent } from './training/training.component';
import { ResearchEthicsComponent } from './research-ethics/research-ethics.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutSrnmcComponent } from './about-srnmc/about-srnmc.component';
import { AcademicCalendarComponent } from './academic-calendar/academic-calendar.component';
import { AcdemicReportComponent } from './acdemic-report/acdemic-report.component';
import { BoardOfManagementComponent } from './board-of-management/board-of-management.component';
import { CommiteeMembersComponent } from './commitee-members/commitee-members.component';
import { CommiteeComponent } from './commitee/commitee.component';
import { DeansComponent } from './deans/deans.component';
import { DepartmentFacultyComponent } from './department-faculty/department-faculty.component';
import { DepartmentSyllabusTimeTableComponent } from './department-syllabus-time-table/department-syllabus-time-table.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentsComponent } from './departments/departments.component';
import { ExamCellComponent } from './exam-cell/exam-cell.component';
import { ExamDocumentsComponent } from './exam-documents/exam-documents.component';
import { ExamRulesComponent } from './exam-rules/exam-rules.component';
import { EventComponent } from './event/event.component';
import { HomeComponent } from './home/home.component';
import { HostelComponent } from './hostel/hostel.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { LibraryComponent } from './library/library.component';
import { NaacReportComponent } from './naac-report/naac-report.component';
import { NewsComponent } from './news/news.component';
import { NirfComponent } from './nirf/nirf.component';
import { PlacementComponent } from './placement/placement.component';
import { PrincipalComponent } from './principal/principal.component';
import { ProfileComponent } from './profile/profile.component';
import { AlbumImagesComponent } from './album-images/album-images.component';
import { ReaserchComponent } from './reaserch/reaserch.component';
import { ResearchProjectsComponent } from './research-projects/research-projects.component';
import { IqacComponent } from './iqac/iqac.component';
import { IqacCommiteeMemberComponent } from './iqac-commitee-member/iqac-commitee-member.component';
import { IqacMinutesReportsComponent } from './iqac-minutes-reports/iqac-minutes-reports.component';
import { IqacAqarReportComponent } from './iqac-aqar-report/iqac-aqar-report.component';
import { AdmissionComponent } from './admission/admission.component';
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component';
import { ExamResultComponent } from './exam-result/exam-result.component';
import { ResearchPolicyComponent } from './research-policy/research-policy.component';
import { ResearchCommitteeComponent } from './research-committee/research-committee.component';
import { ResearchCentresComponent } from './research-centres/research-centres.component';
import { ResearchGuidesComponent } from './research-guides/research-guides.component';
import { ResearchScholarsComponent } from './research-scholars/research-scholars.component';
import { ResearchSeedMoneyComponent } from './research-seed-money/research-seed-money.component';
import { ResearchFundingAgenciesComponent } from './research-funding-agencies/research-funding-agencies.component';
import { ResearchTravelGrantComponent } from './research-travel-grant/research-travel-grant.component';
import { ResearchContactUsComponent } from './research-contact-us/research-contact-us.component';
import { ResearchSeedMoneyProjectsComponent } from './research-seed-money-projects/research-seed-money-projects.component';
import { NonTeachinStaffsComponent } from './non-teachin-staffs/non-teachin-staffs.component';
import { ResearchDownloadFormsComponent } from './research-download-forms/research-download-forms.component';
import { IqacStrategicPlanComponent } from './iqac-strategic-plan/iqac-strategic-plan.component';
import { IqacNewsLettersComponent } from './iqac-news-letters/iqac-news-letters.component';
import { IqacBestPracticesComponent } from './iqac-best-practices/iqac-best-practices.component';
import { IqacCodeOfConductComponent } from './iqac-code-of-conduct/iqac-code-of-conduct.component';
import { IqacInstitutionalDistinctivenessComponent } from './iqac-institutional-distinctiveness/iqac-institutional-distinctiveness.component';
import { IqacFeedbackActionTakenReportComponent } from './iqac-feedback-action-taken-report/iqac-feedback-action-taken-report.component';
import { IqacStackholderFeedbackComponent } from './iqac-stackholder-feedback/iqac-stackholder-feedback.component';
import { IQACAQAR1617Component } from './iqac-aqar-16-17/iqac-aqar-16-17.component';
import { IQACAQAR1718Component } from './iqac-aqar-17-18/iqac-aqar-17-18.component';
import { IQACAQAR1819Component } from './iqac-aqar-18-19/iqac-aqar-18-19.component';
import { IQACAQAR1920Component } from './iqac-aqar-19-20/iqac-aqar-19-20.component';

import { TrainingSoftSkillsComponent } from './training-soft-skill/training-soft-skill.component';
import { TrainingLifeSkillsComponent } from './training-life-skill/training-life-skill.component';
import { TrainingCommunicationSkillsComponent } from './training-communication-skill/training-communication-skill.component';
import { TrainingTrendsSkillsComponent } from './training-trends-skill/training-trends-skill.component';
import { DbtComponent } from './dbt/dbt.component';
import { DBTMembersComponent } from './dbt-members/dbt-members.component';
import { DBTEquipmentComponent } from './dbt-equipment/dbt-equipment.component';
import { DBTPracticalsComponent } from './dbt-practicals/dbt-practicals.component';
import { DBTProgrammesComponent } from './dbt-programmes/dbt-programmes.component';

import { ExamHallTicketListComponent } from './exam-hallticket-list/exam-hallticket-list.component';
import { ExamHallTicketComponent } from './exam-hallticket/exam-hallticket.component';

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'principal', component: PrincipalComponent},
  {path: 'about', component: AboutSrnmcComponent},
  {path: 'about/:type', component: AboutSrnmcComponent},
  {path: 'naac', component: NaacReportComponent},
  {path: 'nirf', component: NirfComponent},
  {path: 'annualReport', component: AcdemicReportComponent},
  {path: 'hostel', component: HostelComponent},
  {path: 'exam', component: ExamCellComponent},
  {path: 'examRegulation', component: ExamRulesComponent},
  {path: 'library', component: LibraryComponent},
  {path: 'departments', component: DepartmentsComponent},
  {path: 'department/:id', component: DepartmentComponent},
  {path: 'departmentProfiles/:id', component: DepartmentFacultyComponent},
  {path: 'departmentDocs/:id/:type', component: DepartmentSyllabusTimeTableComponent},
  {path: 'nonTeachingFaculty/:type', component: NonTeachinStaffsComponent},
  {path: 'profile/:id', component: ProfileComponent},
  {path: 'event/:id', component: EventComponent},
  {path: 'albums/:id', component: AlbumImagesComponent},
  {path: 'academicCalander', component: AcademicCalendarComponent},
  {path: 'statutoryBodies', component: CommiteeComponent},
  {path: 'statutoryBodiesMem/:id/:title', component: CommiteeMembersComponent},
  {path: 'coeDocs/:type', component: ExamDocumentsComponent},
  {path: 'news/:id', component: NewsComponent},
  {path: 'boardOfManagement', component: BoardOfManagementComponent},
  {path: 'deans', component: DeansComponent},
  {path: 'placement', component: PlacementComponent},
  {path: 'infrastructure', component: InfrastructureComponent},
  {path: 'iqac', component: IqacComponent},
  {path: 'iqacCommiteeMember', component: IqacCommiteeMemberComponent},
  {path: 'iqacMinutesReport', component: IqacMinutesReportsComponent},
  {path: 'aqarReport', component: IqacAqarReportComponent},
  {path: 'stackholderFeedback', component: IqacStackholderFeedbackComponent},
  {path: 'feedbackActionTakenReport', component: IqacFeedbackActionTakenReportComponent},
  {path: 'strategicPlan', component: IqacStrategicPlanComponent},
  {path: 'newsLetters', component: IqacNewsLettersComponent},
  {path: 'bestPractices', component: IqacBestPracticesComponent},
  {path: 'institutionalDistinctiveness', component: IqacInstitutionalDistinctivenessComponent},
  {path: 'codeOfConduct', component: IqacCodeOfConductComponent},
  {path: 'programmes', component: AdmissionComponent},
  {path: 'examResults', component: ExamResultListComponent},
  {path: 'examHallTickets', component: ExamHallTicketListComponent},
  {path: 'result/:resCol', component: ExamResultComponent},
  {path: 'hallTicket/:hallTicketCol', component: ExamHallTicketComponent},
  {path: 'research', component: ReaserchComponent},
  {path: 'researchProjects', component: ResearchProjectsComponent},
  {path: 'researchPolicy', component: ResearchPolicyComponent},
  {path: 'researchCommittee', component: ResearchCommitteeComponent},
  {path: 'researchCentres', component: ResearchCentresComponent},
  {path: 'researchGuides', component: ResearchGuidesComponent},
  {path: 'researchScholars', component: ResearchScholarsComponent},
  {path: 'researchSeedMoney', component: ResearchSeedMoneyComponent},
  {path: 'researchSeedMoneyProjects', component: ResearchSeedMoneyProjectsComponent},
  {path: 'researchFundingAgencies', component: ResearchFundingAgenciesComponent},
  {path: 'researchTravelGrant', component: ResearchTravelGrantComponent},
  {path: 'researchContactUs', component: ResearchContactUsComponent},
  {path: 'researchDownloadForms', component: ResearchDownloadFormsComponent},
  {path: 'researchEthics', component: ResearchEthicsComponent},
  {path: 'training', component: TrainingComponent},
  {path: 'ictTools', component: IctToolsComponent},
  {path: 'organizationStructure', component: OrginizationStructureComponent},
  {path: 'rti', component: RTIComponent},
  {path: 'aqar-2016-2017', component: IQACAQAR1617Component},
  {path: 'aqar-2017-2018', component: IQACAQAR1718Component},
  {path: 'aqar-2018-2019', component: IQACAQAR1819Component},
  {path: 'aqar-2019-2020', component: IQACAQAR1920Component},
  {path: 'training-soft-skill', component: TrainingSoftSkillsComponent},
  {path: 'training-communication-skill', component: TrainingCommunicationSkillsComponent},
  {path: 'training-life-skill', component: TrainingLifeSkillsComponent},
  {path: 'training-trends-skill', component: TrainingTrendsSkillsComponent},
  {path: 'dbt', component: DbtComponent},
  {path: 'dbt-members', component: DBTMembersComponent},
  {path: 'dbt-equipment-purchased', component: DBTEquipmentComponent},
  {path: 'dbt-practicals', component: DBTPracticalsComponent},
  {path: 'dbt-programmes', component: DBTProgrammesComponent},
  {path: '**', component: HomeComponent},
  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
