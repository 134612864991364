<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Academic</div>
      <h1 class="kingster-page-title">Statutory Bodies</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div *ngIf="!committees || !committees.length">
                  <h4> No Data Found </h4>
                </div>
                <div *ngIf="committees && committees.length">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style="padding-bottom: 40px ;">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                          Statutory Bodies</h3>
                        <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                          style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style="padding-bottom: 40px ;">
                      <div *ngFor="let committee of committees;" class="gdlr-core-course-item-list">
                        <a
                          class="gdlr-core-course-item-link doc-list-item"
                          (click)="openCommiteeMembers(committee.id, committee.name)"><span
                            class="gdlr-core-course-item-title gdlr-core-skin-title">{{committee.name}}</span><i
                            class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
