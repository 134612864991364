<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">DBT</div>
      <h1 class="kingster-page-title">Department of Biotechnology's Star College</h1>
    </div>
  </div>
</div>

<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      About DBT’s Star College Scheme</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>The Department of Biotechnology, Ministry of Science & Technology, New Delhi had started the Star College Scheme in 2008 to support Colleges and Universities that are offering undergraduate education. The main objective of the scheme was to improve critical thinking and encourage the ‘hands-on’ experimental work at the undergraduate level in basic science subjects. The support provided under the scheme strengthens the physical infrastructure in laboratories, library, and teaching aids.  It also promotes networking with neighbouring institutes.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      DBT Star College scheme @ Sri SRNMC</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p>Basic Science Departments (Mathematics, Physics, Chemistry, Zoology, Computer Science, and Nutrition and Dietetics) of Sri SRNMC were selected under the DBT Star scheme in 2021 under the strengthening component of undergraduate education.</p>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Objectives</h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
            class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
            style="padding-bottom: 25px ; text-align: justify;">
            <ul>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To strengthen the academic and physical infrastructure for achieving excellence in teaching and training.
                  </span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To enhance the quality of the learning and teaching process to stimulate original thinking through ‘hands–on’ exposure to experimental work and participation in summer schools.
                  </span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To promote networking and strengthen ties with neighbouring institutions and other laboratories.
                  </span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To conduct specialized training programmes for faculty improvement for optimizing technical capabilities.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To increase capabilities of core instrumentation resources by procuring new equipment and upgrading existing facilities.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To provide access and exposure to students to research laboratories and industries in the country.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To help in devising standard curricula and Standard Operating Procedure (SOP) / kits for practicals.</span></div>
              </li>
              <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                  class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                    class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                    style="color: #192f59 ;font-size: 16px ;width: 18px ;"></i></span>
                <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                    style="font-size: 16px ;">To provide better library facilities to students and teachers.</span></div>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    <app-dbt-menu></app-dbt-menu>
  </div>
</div>