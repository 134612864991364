<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Statutory Bodies</div>
      <h1 class="kingster-page-title">{{title}}</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div *ngIf="!committeeMembers || !committeeMembers.length">
                  <h4> No Data Found </h4>
                </div>
                <div *ngIf="committeeMembers && committeeMembers.length">
                  <div class="gdlr-core-pbf-element">
                    <div
                      class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style="padding-bottom: 40px ;">
                      <div class="gdlr-core-title-item-title-wrap clearfix">
                        <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                          style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                          Statutory Bodies</h3>
                        <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                          style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                      </div>
                    </div>
                  </div>
                  <div class="gdlr-core-pbf-element">
                    <div *ngFor="let committeeMember of committeeMembers;"
                      class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                      <div
                        class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                        <div class="gdlr-core-personnel-list clearfix">
                          <div class="gdlr-core-personnel-list-content-wrap">
                            <h3 class="gdlr-core-personnel-list-title"
                              style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                              {{committeeMember.name}}</h3>
                            <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                              style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                              {{committeeMember.designation}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
