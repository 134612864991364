import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public staffData: any;
  public staffId: any;
  public departmentStaffData: any;
  public id: any;
  public education:any = [];
  public experience: any= [];
  public projectData: any=  {};
  public publicationData: any = {};
  public presentationData: any = {};
  public participationData: any = {};
  public organizedData: any = {};
  public showEmail = false;
  public formRawData: any = [];
  public gridData: any = [];
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadStaff();
  }

  ngOnInit(): void {
  }

  loadStaff() {
    const email = this.id + '@srnmcollege.ac.in';
    this.showEmail = false;
    this.webService.getUserProfileByEmail(email).subscribe((data) => {
      this.staffData = data;
      this.staffId = data.loginID;
      this.loadEducation();
      this.loadExperience();
      this.loadProjects();
      this.loadPublications();
      this.loadPresentation();
      this.loadParticipation();
      this.loadOrganized();
    });
  }

  //Education
  loadEducation() {
    this.webService.getStaffEduInfo(this.staffId).subscribe(data => {
      const sortedArray = _.orderBy(data, [(o: any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return moment(new Date()).format('YYYYMMDD');
        }
      }, (o :any) => {
        if(o.fromDate) {
          return moment(o.fromDate).format('YYYYMMDD');
        } else {
          return moment(new Date()).format('YYYYMMDD');
        }
      }], ['desc', 'desc']);
      this.education = sortedArray;
    });
  }

  public sortByKeys(object: any) {
    const keys = Object.keys(object);
    const sortedKeys = _.sortBy(keys);
    return _.fromPairs(
      _.map(sortedKeys, (key: any) => {
        let value = object[key];
        if (typeof object[key] === "object" && !(object[key] instanceof Array)) {
          value = this.sortByKeys(value);
        }
        return [key, value];
      })
    );
  };

  //Experience
  loadExperience() {
    this.webService.getStaffExpInfo(this.staffId).subscribe(data => {
      const sortedArray = _.orderBy(data, [(o: any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return moment(new Date()).format('YYYYMMDD');
        }
      }, (o :any) => {
        if(o.fromDate) {
          return moment(o.fromDate).format('YYYYMMDD');
        } else {
          return moment(new Date()).format('YYYYMMDD');
        }
      }], ['desc', 'desc']);
      this.experience = sortedArray;
    });
  }

   //Projects
   loadProjects() {
    this.webService.getStaffProjectInfo(this.staffId).subscribe(data => {
      const orderdData = _.orderBy(data , (o :any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return true;
        }
      }, ['desc']);
      const grouped: any = _.mapValues(_.groupBy(orderdData, 'projectType'),
                      (datalist: any) => datalist.map((data: any) => _.omit(data, 'projectType')));
      this.projectData = grouped;
    });
  }
   
  //Projects
  loadPublications() {
    this.webService.getStaffPublicationInfo(this.staffId).subscribe(data => {
      const orderdData = _.orderBy(data , ['type', (o :any) => {
        const toYear = parseInt(o.year);
        return toYear
      }], ['asc', 'desc']);
      const grouped: any = _.mapValues(_.groupBy(orderdData, 'level'),
            (datalist: any) => datalist.map((data: any) => _.omit(data, 'level')));
      this.publicationData = grouped;
    });
  }

  //Projects
  loadPresentation() {
    this.webService.getStaffPresentationInfo(this.staffId).subscribe(data => {
      const orderdData = _.orderBy(data , ['type', (o :any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return true;
        }
      }], ['asc', 'desc']);
      const grouped: any = _.mapValues(_.groupBy(orderdData, 'level'),
            (datalist: any) => datalist.map((data: any) => _.omit(data, 'level')));
      this.presentationData = grouped;
    });
  }

   //Projects
   loadParticipation() {
    this.webService.getStaffParticipationInfo(this.staffId).subscribe((data: any) => {
      const orderdData = _.orderBy(data ,['type', (o :any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return true;
        }
      }, (o :any) => {
        if(o.fromDate) {
          return moment(o.fromDate).format('YYYYMMDD');
        } else {
          return true;
        }
      }], ['asc', 'desc']);
      const grouped: any = _.mapValues(_.groupBy(orderdData, 'level'),
          (datalist: any) => datalist.map((data: any) => _.omit(data, 'level')));
      this.participationData = grouped;
    });
  }

   //Projects
   loadOrganized() {
    this.webService.getStaffOrganizedInfo(this.staffId).subscribe(data => {
      const orderdData = _.orderBy(data , ['type', (o :any) => {
        if(o.toDate) {
          return moment(o.toDate).format('YYYYMMDD');
        } else {
          return true;
        }
      }], ['asc', 'desc']);
      const grouped: any = _.mapValues(_.groupBy(orderdData, 'level'),
            (datalist: any) => datalist.map((data: any) => _.omit(data, 'level')));
      this.organizedData = grouped;
    });

    this.webService.getAllStaffFormForSite(this.staffId).subscribe((resdata) => {
      this.constructForms(resdata);
    });
  }

  constructForms(resdata: any) {
    const localGridData = [];
    if (resdata && resdata.length) {
      for (let index = 0; index < resdata.length; index++) {
        const gridData: any = {};
        const formData: any = resdata[index];
        if (formData.formData && formData.formData.length) {
          this.formRawData = this.formRawData.concat(formData.formData);
        }
        gridData['title'] = formData.title;
        gridData['jsonId'] = formData.formJson.id;
        gridData['maxEntry'] = formData.formJson.maxEntry;
        gridData['displayExporting'] = formData.formJson.displayExporting;
        gridData['description'] = formData.formJson.description;
        gridData['data'] = [];
        const data = formData.formData;
        for (let index = 0; index < data.length; index++) {
          const formData = data[index];
          const form = {
            id: formData.id,
            docs: formData.docs,
          };
          _.extend(form, JSON.parse(formData.formData));
          gridData['data'].push(form);
        }
        let columns = new Map()
        const json = JSON.parse(formData.formJson.formJSON);
        for (let index1 = 0; index1 < json.components.length; index1++) {
          const component = json.components[index1];
          if(component.input) {
            columns.set(component.key, component.label);
          }
        }
        gridData['columns'] = columns;
        localGridData.push(gridData);
      }
      for (let index = 0; index < localGridData.length; index++) {
        let gridData = localGridData[index];
        const orderGirdData = _.orderBy(gridData.data , (o :any) => {
          let orderData = o[gridData.columns.keys().next().value];
          const parsed:any = moment(orderData, 'DD/MM/YYYY');
          const intParsed:any = parseInt(orderData);
          if(parsed.isValid()) {
            return moment(parsed).format('YYYYMMDD');
          } else if(!isNaN(intParsed)) {
            return intParsed;
          } else {
            return undefined;
          }
        }, ['desc']);
        gridData['data'] = orderGirdData;
        this.gridData.push(gridData);
      }
    }
  }

  formatDate(date: any) {
    if(date) {
      return moment(date).format('Do MMM YYYY')
    } else {
      return '';
    }
  }

  formatMonth(date: any) {
    if(date) {
      return moment(date).format('MMM YYYY')
    } else {
      return '';
    }
  }

  emailResolved() {
    this.showEmail = true;
  }

  isEmpty(object: any): boolean {
    return _.isEmpty(object);
  }

  asIsOrder(a: any, b: any) {
    return 1;
  }
}
