import { Component, OnInit, Input } from '@angular/core';
import { isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { WebServiceService } from './../web-service.service';

const colors: any = {
  academic_event: {
    primary: '#975aff',
  },
  department_event: {
    primary: '#20c997',
  },
  dayOrder: {
    primary: '#2f8be6',
  },
  note: {
    primary: '#616d89',
  }
};


@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss']
})
export class EventsCalendarComponent implements OnInit {
  public view: any = 'month';
  public viewDate: Date = new Date();
  refresh: Subject < any > = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  @Input() type: string = '';
  @Input() departmentId: number = 0;
  constructor(
    private router: Router,
    public webService: WebServiceService,
  ) {}
  ngOnInit(): void {
    this.loadCalendarData();
  }

  loadCalendarData() {
    this.events = [];
    if(this.type === 'DEPARTMENT') {
      this.webService.getDepartmentEvents(this.departmentId).subscribe((departmentData) => {
        const departmentEventData: CalendarEvent[] = [];
        departmentData.forEach((data: any) => {
          const noteEvent: CalendarEvent = {
            start: new Date(data.startDate),
            end: new Date(data.endDate),
            title: data.title,
            color: colors.department_event,
            allDay: true,
            meta: 'DEPARTMENT_EVENT',
            id: data.id
          };
          departmentEventData.push(noteEvent);
        });
        this.events = this.events.concat(departmentEventData);
      });
    }

    if(this.type === 'ACADEMIC') {
      this.webService.getCalendarNotes().subscribe((notesData) => {
          const notesEventData: CalendarEvent[] = [];
          notesData.forEach((data: any) => {
            const noteEvent: CalendarEvent = {
              start: new Date(data.startDate),
              title: data.calendarNote,
              color: colors.note,
              allDay: true,
              meta: 'CALENDAR_NOTE',
              id: data.id
            };
            notesEventData.push(noteEvent);
          });
          this.events = this.events.concat(notesEventData);
      });

      this.webService.getDayOrders().subscribe((dayOrderData) => {
        const dayOrderEventData: CalendarEvent[] = [];
        dayOrderData.forEach((data: any) => {
          let title = "Day Order - ";
          title = title + (data.dayOrder === 'HOLIDAY' ? data.dayOrder + ": " + data.dayOrderLeaveReason : data.dayOrder);
          const noteEvent: CalendarEvent = {
            start: new Date(data.startDate),
            title,
            color: colors.dayOrder,
            allDay: true,
            meta: 'DAY_ORDER_EVENT',
            id: data.id
          };
          dayOrderEventData.push(noteEvent);
        });
        this.events = this.events.concat(dayOrderEventData);
      });

      this.webService.getAcademicEvents().subscribe((academicData) => {
        const academicEventData: CalendarEvent[] = [];
        academicData.forEach((data: any) => {
          const noteEvent: CalendarEvent = {
            start: new Date(data.startDate),
            end: new Date(data.endDate),
            title: data.title,
            color: colors.academic_event,
            allDay: true,
            meta: 'ACDEMIC_EVENT',
            id: data.id
          };
          academicEventData.push(noteEvent);
        });
        this.events = this.events.concat(academicEventData);
      });

      this.webService.getAllDepartmentEvents().subscribe((departmentData) => {
        const departmentEventData: CalendarEvent[] = [];
        departmentData.forEach((data: any) => {
          const noteEvent: CalendarEvent = {
            start: new Date(data.startDate),
            end: new Date(data.endDate),
            title: data.title,
            color: colors.department_event,
            id: data.id,
            meta: 'DEPARTMENT_EVENT'
          };
          departmentEventData.push(noteEvent);
        });
        this.events = this.events.concat(departmentEventData);
      });
    }
  }

  dayClicked({date,events}: {date: Date;events: CalendarEvent[]}): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
   if(action === 'Clicked') {
     if(event.meta === 'DEPARTMENT_EVENT' || event.meta === 'ACDEMIC_EVENT') {
       this.router.navigate(['event', event.id]);
     }
   }
  }

}
