import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-dbt-menu',
  templateUrl: './dbt-menu.component.html',
  styleUrls: ['./dbt-menu.component.scss']
})
export class DBTMenuComponent implements OnInit {

  currentRoute: any;
  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.currentRoute = event.url;     
      console.log(this.currentRoute);     
    });
  }

}
