<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">IQAC</div>
      <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      About IQAC </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>This Cell was established and functions on the basis of the guidelines set forth by National
                      Assessment and Accreditation Council (NAAC). It works towards improving and maintaining the
                      quality of education, identifying and suggesting new ways of using teaching aids, developing
                      suitable infrastructure and offering suggestions for new self-financing courses. The IQAC has been
                      diligently involved in helping the Management and Principal and other committees by offering
                      valuable suggestions and measures in order to have consistency in maintaining the quality of
                      education. Thus, IQAC is an effective and efficient internal co-ordinating and monitoring
                      mechanism.</p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Objective </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                  <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify;">
                    <p>The primary aim of the IQAC is to develop a system for conscious, consistent and catalytic action
                      to improve the academic and administrative performance of the institution.</p>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Functions </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                style="padding-bottom: 25px ; text-align: justify;">
                <ul>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ; ">Development and application of quality benchmarks/parameters for the
                        various academic and administrative activities of the institution.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Dissemination of information on the various quality parameters of
                        higher education.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Organisation of workshops, seminars on quality related themes and
                        promotion of quality circles.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Documentation of the various programmes/activities leading to quality
                        improvement.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Preparation of the Annual Quality Assurance Report (AQAR) to be
                        submitted to NAAC based on the quality parameters.</span></div>
                  </li>
                </ul>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      Strategies </h3>
                    <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                      style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                  </div>
                </div>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      IQAC shall evolve mechanisms and procedures for:
                    </h4>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                style="padding-bottom: 25px ; text-align: justify;">
                <ul>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Ensuring timely, efficient and progressive performance of academic,
                        administrative and financial tasks.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Optimization and integration of modern methods of teaching, learning
                        and evaluation.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">Ensuring the adequacy, maintenance and functioning of the support
                        structure.</span></div>
                  </li>
                </ul>
              </div>
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                  style="padding-bottom: 40px ;">
                  <div class="gdlr-core-title-item-title-wrap clearfix">
                    <h4 class="gdlr-core-title-item-title gdlr-core-skin-title "
                      style="font-size: 16px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                      IQAC will facilitate / contribute:
                    </h4>
                  </div>
                </div>
              </div>
              <div
                class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-style-1"
                style="padding-bottom: 25px ; text-align: justify;">
                <ul>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">To a heightened level of clarity and focus in institutional
                        functioning towards quality enhancement and facilitate internalization of the quality
                        culture.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">To the enhancement and integration among the various activities of the
                        institution and institutionalize many good practices.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">To provide a sound basis for decision-making to improve institutional
                        functioning.
                      </span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">To act as a change agent in the institution.</span></div>
                  </li>
                  <li class=" gdlr-core-skin-divider clearfix" style="margin-bottom: 18px ;"><span
                      class="gdlr-core-icon-list-icon-wrap gdlr-core-left" style="margin-top: 5px ;"><i
                        class="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                        style="color: #192f59 ;font-size: 18px ;width: 18px ;"></i></span>
                    <div class="gdlr-core-icon-list-content-wrap"><span class="gdlr-core-icon-list-content"
                        style="font-size: 18px ;">To better inter-communication.</span></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-iqac-menu></app-iqac-menu>
  </div>
</div>
