import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-about-srnmc',
  templateUrl: './about-srnmc.component.html',
  styleUrls: ['./about-srnmc.component.scss']
})
export class AboutSrnmcComponent implements OnInit, AfterViewInit {

  constructor(
    private route: ActivatedRoute,
  ) {
   }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const type = this.route.snapshot.paramMap.get('type');
    if(!type) {
      let el: any = document.getElementById("aboutSRNMC");
      let aboutContainerEl: any = document.getElementById("aboutContainer");
      el.scrollIntoView({ behavior: 'smooth' });
      aboutContainerEl.scrollTop -= 50;
    }
    if (type === 'history') {
      let el: any = document.getElementById("aboutSRNMC");
      let aboutContainerEl: any = document.getElementById("aboutContainer");
      el.scrollIntoView({ behavior: 'smooth' });
      aboutContainerEl.scrollTop -= 50;
    }
    if (type === 'logo') {
      let el: any = document.getElementById("logoSRNMC");
      let aboutContainerEl: any = document.getElementById("aboutContainer");
      el.scrollIntoView({ behavior: 'smooth' });
      aboutContainerEl.scrollTop -= 50;
    }
    if (type === 'vision') {
      let el: any = document.getElementById("visionSRNMC");
      let aboutContainerEl: any = document.getElementById("aboutContainer");
      el.scrollIntoView({ behavior: 'smooth' });
      aboutContainerEl.scrollTop -= 50;
    }
    if (type === 'profile') {
      let el: any = document.getElementById("div_1dd7_44");
      let aboutContainerEl: any = document.getElementById("aboutContainer");
      el.scrollIntoView({ behavior: 'smooth' });
      aboutContainerEl.scrollTop -= 50;
    }
  }

}
