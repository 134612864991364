import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  
  public newsData: any;
  public id: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    public route: ActivatedRoute,
  ) { 
      this.loadNews();
  }

  ngOnInit(): void {
   
  }

  loadNews() {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getNewsData(this.id).subscribe((data) => {
      this.newsData = data;
    });
  }
}
