<div class=" kingster-sidebar-left kingster-column-15 kingster-line-height kingster-line-height side-iqac-menu">
    <div class="kingster-sidebar-area kingster-item-pdlr">
      <div id="text-5" class="widget widget_text kingster-widget">
        <div class="textwidget side-menu">
          <ul>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/research' }" routerLink="/research">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title" style="text-transform: uppercase;">
                  RESEARCH Overview
                </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchPolicy' }" routerLink="/researchPolicy">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title" style="text-transform: uppercase;">
                  Research Policy </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchCommittee' }"  routerLink="/researchCommittee">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title" style="text-transform: uppercase;">
                  Research Committee </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchCentres' }" routerLink="/researchCentres">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Research Centres </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchGuides' }" routerLink="/researchGuides">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Research Guides </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchScholars' }" routerLink="/researchScholars">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Research Scholars </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchProjects' }" routerLink="/researchProjects">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Research Projects </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchSeedMoney' }" routerLink="/researchSeedMoney">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title" style="text-transform: uppercase;">SEED
                  MONEY</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchSeedMoneyProjects' }" routerLink="/researchSeedMoneyProjects">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title" style="text-transform: uppercase;">SEED
                  MONEY PROJECTS</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchFundingAgencies' }" routerLink="/researchFundingAgencies">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Funding Agencies</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchTravelGrant' }" routerLink="/researchTravelGrant">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Travel Grant</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchEthics' }" routerLink="/researchEthics">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Research Ethics Committee</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchDownloadForms' }" routerLink="/researchDownloadForms">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Download forms</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/researchContactUs' }" routerLink="/researchContactUs">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"
                  style="text-transform: uppercase;">Contact us</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>