<div class="kingster-top-bar">
  <div class="kingster-top-bar-background"></div>
  <div class="kingster-top-bar-container kingster-container ">
    <div class="kingster-top-bar-container-inner clearfix">
      <div class="kingster-top-bar-left kingster-item-pdlr"><i class="fa fa-envelope-open-o"
          style="font-size: 15px ;color: #96a2b8 ;margin-right: 8px ;"></i> support@srnmcollege.ac.in <i
          class="fa fa-phone" style="font-size: 15px ;color: #96a2b8 ;margin-left: 18px ;margin-right: 6px ;"></i>
        04562 260326 </div>
      <div class="kingster-top-bar-right kingster-item-pdlr">
        <ul id="kingster-top-bar-menu"
          class="sf-menu kingster-top-bar-menu kingster-top-bar-right-menu sf-js-enabled sf-arrows"
          style="touch-action: pan-y;">
          <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5727 kingster-normal-menu"><a
              routerLink="/academicCalander">Academic Calendar</a></li> -->
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5727 kingster-normal-menu"><a
              routerLink="/dbt">DBT</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5727 kingster-normal-menu"><a
              routerLink="/nirf">NIRF</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5727 kingster-normal-menu"><a
              routerLink="iqac">IQAC</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6051 kingster-normal-menu"><a
              routerLink="/naac">NAAC</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6051 kingster-normal-menu"><a href="https://portal.srnmcollege.ac.in" target="_blank" >Staff Login</a></li>
        </ul>
        <div class="kingster-top-bar-right-social"></div><a class="kingster-top-bar-right-button"
        href="https://events.srnmcollege.ac.in" target="_blank">EVENTS @ SRNMC</a>
      </div>
    </div>
  </div>
</div>
<!--
<div class="kingster-top-bar">
  <div class="gdlr-core-pbf-wrapper " id="div_1dd7_1000" data-skin="Column Service">
    <div class="gdlr-core-pbf-background-wrap" id="div_1dd7_31"></div>
    <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
      <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
        <div class="gdlr-core-pbf-column gdlr-core-column-12 gdlr-core-column-first">
          <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-right-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                  id="div_1dd7_32">
                  <div class="gdlr-core-column-service-media gdlr-core-media-image" id="div_1dd7_33">
                    <img src="../../assets/images/logo_full.png" alt="" width="150" height="150" title="col-icon-1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gdlr-core-pbf-column gdlr-core-column-48" id="gdlr-core-column-5">
          <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
              <div class="gdlr-core-pbf-element">
                <div
                  class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                  id="div_1dd7_35">
                  <div class="gdlr-core-column-service-content-wrapper">
                    <div class="gdlr-core-column-service-title-wrap">
                      <h2 style="font-size: 32px; padding-top: 10px; color: white;"> Sri S.Ramasamy Naidu Memorial
                        College (Autonomous) </h2>
                      <h4 style="font-size: 14px; color: white;"> (A Co-educational,Autonomous and
                        Telugu Linguistic Minority Institution,affliated to Madurai Kamaraj University)
                      </h4>
                      <h4 style="font-size: 14px; color: white;"> (Re-Accredited with 'A' Grade by NAAC) </h4>
                      <h3 style="font-size: 20px; color: white;"> Sattur, Virudhunagar (DT), Tamilnadu - 626 203 </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div> -->
