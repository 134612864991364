import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-ethics',
  templateUrl: './research-ethics.component.html',
  styleUrls: ['./research-ethics.component.scss']
})
export class ResearchEthicsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
