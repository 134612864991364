import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqac-aqar-19-20',
  templateUrl: './iqac-aqar-19-20.component.html',
  styleUrls: ['./iqac-aqar-19-20.component.scss']
})
export class IQACAQAR1920Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
