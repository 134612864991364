<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Contact Us</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Contact Us</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p><b> Dr P. Sundara Venkatesh</b>
                    <br>
                    Dean – Research & Development,
                    <br>
                    Sri S. Ramasamy Naidu Memorial College,
                    <br>
                    Sattur – 626203.
                    <br>
                    <b>Email:</b> research@srnmcollege.ac.in
                    <!-- <br> -->
                    <!-- <b>Mobile:</b> +91 - 80723 57026 -->
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>