<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <div class="kingster-page-caption">IQAC</div>
        <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
      <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
        <div class="gdlr-core-page-builder-body">
          <div class="gdlr-core-pbf-section">
            <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Feedback Action Taken Report</h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify; height: 1000px;">
                        <ng2-pdfjs-viewer pdfSrc="https://storage.srnmcollege.ac.in/srnmcweb/pdf/1.4.2_L2_1d.pdf" [openFile]="false" [viewBookmark]="false"></ng2-pdfjs-viewer>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div class="gdlr-core-text-box-item-content" style="font-size: 16px ;text-transform: none ; text-align: justify; height: 1000px;">
                          <ng2-pdfjs-viewer pdfSrc="https://storage.srnmcollege.ac.in/srnmcweb/pdf/1_4_1_L2_1.pdf" [openFile]="false" [viewBookmark]="false"></ng2-pdfjs-viewer>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-iqac-menu></app-iqac-menu>
    </div>
  </div>
  