<div class=" kingster-sidebar-left kingster-column-15 kingster-line-height kingster-line-height side-iqac-menu">
    <div class="kingster-sidebar-area kingster-item-pdlr">
      <div id="text-5" class="widget widget_text kingster-widget">
        <div class="textwidget side-menu">
          <ul>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/iqac' }" routerLink="/iqac">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> OBJECTIVES AND FUNCTIONS OF IQAC
                </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/iqacCommiteeMember' }"  routerLink="/iqacCommiteeMember">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> CORE COMMITTEE MEMBERS </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/iqacMinutesReport' }" routerLink="/iqacMinutesReport">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> IQAC MINUTES AND ACTION TAKEN </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/aqarReport' }" routerLink="/aqarReport">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">AQAR REPORTS </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/stackholderFeedback' }" routerLink="/stackholderFeedback">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Stakeholder Feedback </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/feedbackActionTakenReport' }" routerLink="/feedbackActionTakenReport">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Feedback Action Taken Report </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/strategicPlan' }" routerLink="/strategicPlan">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Strategic Plan and Deployment </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/newsLetters' }" routerLink="/newsLetters">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">News Letters </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/bestPractices' }" routerLink="/bestPractices">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Best Practices</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/institutionalDistinctiveness' }" routerLink="/institutionalDistinctiveness">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Institutional Distinctiveness</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/codeOfConduct' }" routerLink="/codeOfConduct">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">Code of conducts</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>