<div class=" kingster-sidebar-left kingster-column-15 kingster-line-height kingster-line-height side-iqac-menu">
    <div class="kingster-sidebar-area kingster-item-pdlr">
      <div id="text-5" class="widget widget_text kingster-widget">
        <div class="textwidget side-menu">
          <ul>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt' }" routerLink="/dbt">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> HOME
                </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt-members' }"  routerLink="/dbt-members">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> ADVISORY COMMITTEE MEMBER </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt-equipment-purchased' }" routerLink="/dbt-equipment-purchased">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title"> EQUIPMENT PURCHASED </span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt-practicals' }" routerLink="/dbt-practicals">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">NEW PRACTICAL INTRODUCED/TO BE INTRODUCED</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt-programmes' }" routerLink="/dbt-programmes">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">PROGRAMMES ORGANIZED</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
            <li>
              <a class="gdlr-core-course-item-link" [ngClass]="{'active': currentRoute === '/dbt-progress-report' }" routerLink="/dbt">
                <span class="gdlr-core-course-item-title gdlr-core-skin-title">PROGRESS REPORT</span>
                <i class="gdlr-core-course-item-icon gdlr-core-skin-icon fa fa-long-arrow-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>