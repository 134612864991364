import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-commitee-members',
  templateUrl: './commitee-members.component.html',
  styleUrls: ['./commitee-members.component.scss']
})
export class CommiteeMembersComponent implements OnInit {

  public committeeMembers: any;
  public title: any;
  public id: any;
  constructor(
    public webService: WebServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
      this.loadCommitee();
  }

  ngOnInit(): void {
   
  }

  loadCommitee() {
    this.title = this.route.snapshot.paramMap.get('title');
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getCommitteeMembers(this.id).subscribe((data) => {
      this.committeeMembers = data;
    });
  }

  openCommiteeMembers(id: any, title: any) {
    this.router.navigate(['commiteeMembers', id, title])
  }

}
