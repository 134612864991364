<footer>
  <div class="kingster-footer-wrapper ">
    <div class="kingster-footer-container kingster-container clearfix">
      <div class="kingster-footer-column kingster-item-pdlr kingster-column-15">
        <div id="text-2" class="widget widget_text kingster-widget">
          <div class="textwidget">
            <p>
              <img src="../assets/images/slide/srnaidu.fbdcae92.png" alt="" />
              <br /> <span class="gdlr-core-space-shortcode" id="span_1dd7_10"></span>
              <br /> Sri S.Ramasamy Naidu
              <br /> Educationist & Social reformer
              <br /> 1901 - 1969 </p>
            <!-- <br /> <span class="gdlr-core-space-shortcode" id="span_1dd7_12"></span>
                        <a id="a_1dd7_8" href="http://srnaidu.com/"> http://srnaidu.com/ </a> -->
            <div class="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-left-align">
              <div class="gdlr-core-divider-line gdlr-core-skin-divider" id="div_1dd7_111">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kingster-footer-column kingster-item-pdlr kingster-column-30">
        <div id="gdlr-core-custom-menu-widget-2" class="widget widget_gdlr-core-custom-menu-widget kingster-widget">
          <h3 class="kingster-widget-title">Location</h3><span class="clear"></span>
          <div class="menu-our-campus-container">
            <iframe data-v-2d991952=""
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.319865563462!2d77.91451336465879!3d9.328677393313349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06b55a82858623%3A0x53e3c54f2f8e5400!2sS+Ramasamy+Naidu+Memorial+College%2C+Sadayampatti%2C+NH+44%2C+Othaiyal+Mettupatti%2C+Tamil+Nadu+626203!5e1!3m2!1sen!2sin!4v1535699047194"
              width="98%" height="300" frameborder="0" allowfullscreen="allowfullscreen" style="border: 0px;"></iframe>
          </div>
        </div>
      </div>
      <div class="kingster-footer-column kingster-item-pdlr kingster-column-15">
        <div id="gdlr-core-custom-menu-widget-4" class="widget widget_gdlr-core-custom-menu-widget kingster-widget">
          <h3 class="kingster-widget-title">Contact US</h3><span class="clear"></span>
          <div class="menu-academics-container">
            <div class="textwidget">
              The Principal,
              <br />Sri S.R.N.M College,
              <br />Sattur - 626 203
              <br />Virudhunagar District,
              <br />Tamil Nadu, India.
              <br /> College Office: 04562 260326
              <br /> College Office: +91 - 94867 60326
              <br /> Principal Office: 04562 299866
              <br /> principal@srnmcollege.ac.in
              <br /> prinsrnmc@gmail.com
              <br /> <span class="gdlr-core-space-shortcode" id="span_1dd7_10"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="kingster-copyright-wrapper">
    <div class="kingster-copyright-container kingster-container clearfix">
      <div class="kingster-copyright-left kingster-item-pdlr">Copyright All Right Reserved 2022, SRNM
        College</div>
      <div class="kingster-copyright-right kingster-item-pdlr">
        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align" id="div_1dd7_112">
          <a href="https://www.facebook.com/srnmcollege" target="_blank" class="gdlr-core-social-network-icon"
            title="facebook">
            <i class="fa fa-facebook"></i>
          </a>
          <a href="https://twitter.com/srnmcollege" target="_blank" class="gdlr-core-social-network-icon"
            title="twitter">
            <i class="fa fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/srnmcollege/" target="_blank" class="gdlr-core-social-network-icon"
            title="instagram">
            <i class="fa fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCZduQD2DMK_ZWya6Rqi37PA" target="_blank"
            class="gdlr-core-social-network-icon" title="youtube">
            <i class="fa fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/srnmcollege" target="_blank" class="gdlr-core-social-network-icon"
            title="youtube">
            <i class="fa fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>