<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Academic</div>
      <h1 class="kingster-page-title">Board of Management</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid"
          style="padding-bottom: 10px ;">
          <div class="gdlr-core-portfolio-item-holder clearfix" data-layout="fitrows">
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/1.jpg"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. S. Jegatheesan
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    President
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/2.JPG"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. T. R. Kannan
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Secretary
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/3.jpg"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Thiru K.R.Krishnaswamy
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Treasurer
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/4.JPG"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. E. Perumalsamy
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Vice - President
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/5.JPG"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. G. Seenivasan
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Joint Secretary
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/6.JPG"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mrs. P. Santhalakshmi
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/7.jpg"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. R. Suriyanarayanan
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/8.jpeg"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. S. Padmanaban
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/9.JPG"
                      style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                  style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                    style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. V. Govindarajan
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/10.JPG"
                         style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                     style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                      style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. A. Gopalsamy
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
            <div class="gdlr-core-item-list  gdlr-core-item-pdlr gdlr-core-column-20">
              <div class="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-with-frame gdlr-core-js">
                <div class="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
                  <div class="gdlr-core-portfolio-thumbnail-image-wrap">
                    <img src="https://storage.srnmcollege.ac.in/srnmcweb/board_of_management/11.jpeg"
                         style="width: 700px;height: 350px;" alt="">
                  </div>
                </div>
                <div class="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider"
                     style="padding-left: 18px; padding-right: 18px;">
                  <h3 class="gdlr-core-portfolio-title gdlr-core-skin-title"
                      style="font-size: 15px ;font-weight: 600 ;letter-spacing: 1px ; line-height: 20px; text-transform: none ;">
                    Mr. P. Subburaj
                  </h3>
                  <span
                    class="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
                    Director
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
