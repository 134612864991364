<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Controller of Examination</div>
      <h1 class="kingster-page-title">Rules & Regulations</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper" style="text-align: justify;">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div id="himainbo-col1" style="margin-top:0px; padding-top:0px; margin-left:30px;">
                <p><strong>Registration:</strong></p>
                <p>Every First semester student should register for the End Semester Examinations on
                  or before the last date. The registration consists of the following steps:</p>
                <ul>
                  <li>SSubmit the filled in application form for registration; Read the data already
                    printed, fill the remaining data, put the signature and submit the application in
                    the college office on or before the last date during working hours on all
                    working days.</li>
                  <li>Under any circumstances the applications will not be received after the last
                    date.</li>
                  <li>Candidates who have not registered for the Semester Terminal Examination
                    are not permitted to appear for the Regular/ Supplementary Examinations.</li>
                  <li>Candidate can collect his Hall Ticket from the Attendance in-charge of the
                    respective Department.</li>
                </ul>
                <p><strong>Attendance </strong></p>
                <p>As per the guidelines, approved by the academic council of our college the following
                  norms will be applied for attendance to appear for the End Semester Examinations.</p>
                <ul>
                  <li>The students who have attended the classes for 75% and above will be
                    permitted to appear for the ensuing End Semester Examinations without any
                    preconditions.</li>
                  <li>The Students who have put in attendance less than 75% have to repeat the
                    whole semester.</li>
                </ul>
                <p><strong>Examination Time Table </strong></p>
                <p>The End Semester Examination Time table will be put-up in the College Notice
                  Board and Website.</p>
                <p><strong>Examination Timings:</strong></p>
                <p>Examinations with 3 hours duration</p>
                <p>FN session – 10.00 a.m to 1.00 p.m. <br> AN session – 2.00 p.m. to 5.00 p.m.</p>
                <p>Examinations with 2 hours duration</p>
                <p>FN session – 10.00 a.m to 12.00 noon <br> AN session – 2.00 p.m. to 4.00 p.m.</p>
                <p><strong>Exam Hall Discipline </strong></p>
                <p>For the smooth conduct of the Examinations, the candidates are requested to adhere
                  to the following guidelines.<br> The candidates:</p>
                <ul>
                  <li>Have to check their seating arrangement daily.</li>
                  <li>Have to enter the hall 10 minutes before the commencement of the
                    examination and occupy their seat correctly.
                  </li>
                  <li>Are not allowed to enter the hall after 30 minutes from the commencement of
                    the Examination; Allowed to leave the hall only after 30 minutes from the
                    commencement of Examination.</li>
                  <li>Should posses Hall Ticket and Student Identity card in the examination hall. A
                    student without Hall Ticket or Student Identity card should get Temporary Hall
                    Ticket or Student Identity card from the Chief Superintendent (Principal) with
                    penalty fees. </li>
                  <li>Should not be in possession of mobile phone, programmable calculator, pager
                    and any notes or other written/ printed matter except as may be specifically
                    permitted by the Chief Superintendent / Invigilator.
                  </li>
                  <li>Have to check whether the Register Number, Name, and subject code/ name
                    are correctly entered in the answer booklet.</li>
                  <li>Have to, on receipt of question paper, verify the subject code / Name and
                    ensure that the correct question paper is received.</li>
                  <li>Should write legibly on both sides of the pages and papers shall not be
                    wasted.</li>
                  <li>Are prohibited from writing or leaving any distinguished marks so as to identify
                    their paper.</li>
                  <li>Should not detach any sheets from the answer booklet and answer booklet
                    should not be taken out of the hall.</li>
                  <li>Should not exchange any materials with other candidates inside the hall.</li>
                  <li>Have to return back any data books/ charts/ manuals issued to them before
                    leaving the hall.</li>
                  <li>Have to ensure whether they signed in the attendance sheet before leaving
                    the exam hall.</li>
                  <li>Have to maintain silence inside the examination hall.</li>
                  <li>Who resorts to malpractice of any sort will be dealt seriously and suitable
                    disciplinary action will also be taken against them. It may lead to even,
                    cancellation of all the exams written by them during the semester.</li>
                  <li>Can report any missing data/figure or discrepancy in the question paper to
                    the Invigilator inside the Hall.</li>
                  <li>Can leave the exam hall for any emergency like natural calls/illness with the
                    prior permission from the respective invigilator and with the assistance of
                    supporting staff.</li>
                </ul>






                <p><span><strong style="line-height: 22.4px;">Supplementary Examinations:</strong>&nbsp;</span></p>
                <p>
                  Supplementary Examinations will be conducted after the publication of results
                  once in a year during the even semester. No supplementary exam will be conducted
                  for Practical / Projects. Supplementary exam will be considered as an attempt.
                  <strong>UG/PG</strong> Students who have cleared all papers including practical up to
                  <strong>fifth/third</strong>
                  semester respectively and have arrears in the final Semester can apply for the
                  Supplementary Examinations through the prescribed application form.
                </p>
                <p><span><strong style="line-height: 22.4px;">Guidelines &amp; Conditions for Supplementary
                      examinations:</strong>&nbsp;</span></p>

                <ol>
                  <li>
                    The candidate can apply for maximum of THREE papers (Theory papers
                    listed for final semester only), provided the candidate has passed all the
                    subjects of previous semesters including practical.
                  </li>
                  <li>
                    The prescribed application form for Supplementary Examination can be
                    obtained from the college office.
                  </li>
                  <li>
                    The candidate applying for the Supplementary Examination for the Paper in
                    which he / she has failed in the final Semester is not eligible to apply for the
                    revaluation of the particular paper.
                  </li>
                  <li>
                    Only the students who have written and failed in their FINAL SEMESTER
                    EXAMINATIONS will be permitted for this supplementary examination.
                  </li>
                  <li>
                    The non refundable prescribed fees shall be paid by the student. The fee shall
                    be remitted by cash or D. D. drawn in favour of ‘The Principal,
                    Sri.S.R.N.M.College’, payable at Sattur.
                  </li>
                  <li>
                    The filled in application form to be submitted to the college office on or before
                    the last date announced.
                  </li>
                  <li>
                    Incomplete form and form not accompanied by relevant documents may be
                    rejected without assigning any reason whatsoever.
                  </li>
                  <li>
                    The candidates should bring the Hall tickets and Identity Cards when they
                    come to the Examination Hall.
                  </li>
                  <li>
                    Enclose i) A copy of the Internet Result of final Semester ii) Xerox copy of the
                    mark statements upto V th Semester iii) Fee Receipt
                  </li>
                </ol>
                <p><span><strong style="line-height: 22.4px;">Re-Evaluation of Answer Scripts</strong></span></p>
                <ol>
                  <li>
                    If the student is not satisfied with the marks awarded, he/she may apply for
                    revaluation in the prescribed form within the period and in the prescribed
                    format.
                  </li>
                  <li>
                    Revaluation of answer books shall be permissible for not more than FOUR
                    theory papers.
                  </li>
                  <li>
                    The revaluation of answer book shall not be permitted for Certificate and
                    Diploma courses / General Knowledge paper / practical examination / project
                    work / dissertation / internal assessment / Viva voce / Oral Examinations.
                  </li>
                  <li>
                    The prescribed application form for revaluation of answer book can be
                    obtained from the college office.
                  </li>
                  <li>
                    The non refundable prescribed fee shall be remitted by cash or D. D. drawn in
                    favour of ‘The Principal, Sri.S.R.N.M.College’, payable at Sattur.
                  </li>
                  <li>
                    The prescribed application form for revaluation of answer book duly filled in
                    and signed by the applicant is to be submitted along with the statement of
                    marks and prescribed fees particulars within the last date announced.
                  </li>
                  <li>
                    Incomplete form and form not accompanied by relevant documents may be
                    rejected without assigning any reason whatsoever.
                  </li>
                  <li>
                    The revised marks/grade after re-evaluation shall be made available in the
                    College Office.
                  </li>
                </ol>


                <p><span><strong style="line-height: 22.4px;">Passing
                      Minimum</strong>&nbsp;</span></p>
                <p><strong style="line-height: 22.3999996185303px;"><span>Marks and
                      Grades:</span></strong>
                </p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The grade point for each course is computed by
                  dividing the Total
                  Marks by 10.</p>
                <p align="justify">&nbsp;&nbsp;&nbsp;&nbsp;The following table gives the grade points and letter grades
                  for the total
                  marks obtained by the candidates in each course.</p>
                <h3 align="center">Table of Grade Points and Letter Grade</h3>
                <table height="291" border="1">
                  <tbody>
                    <tr>
                      <td width="190">
                        <p>RANGE&nbsp;&nbsp;&nbsp;</p>
                        <p>OF</p>
                        <p>MARKS</p>
                      </td>
                      <td width="103">
                        <p>100<br>-</p>90
                      </td>
                      <td width="79">
                        <p>89<br>-</p>80
                      </td>
                      <td width="84">
                        <p>79<br>-<br>70</p>
                      </td>
                      <td width="65">
                        <p>69<br>-</p>60
                      </td>
                      <td width="73">
                        <p>59<br>-<br>50</p>
                      </td>
                      <td width="76">
                        <p>49<br>-</p>40
                      </td>
                      <td width="74">
                        <p>39<br>-<br>30</p>
                      </td>
                      <td width="104">ABSENT</td>
                    </tr>
                    <tr>
                      <td>
                        <p>GRADE</p>POINTS
                      </td>
                      <td>10<br>-<br>9</td>
                      <td>
                        <p>8.9<br>-</p>8.0
                      </td>
                      <td>
                        <p>7.9<br>-</p>7.0
                      </td>
                      <td>
                        <p>6.9<br>-</p>6.0
                      </td>
                      <td>
                        <p>5.9<br>-<br>5.0</p>
                      </td>
                      <td>
                        <p>4.9<br>-</p>4.0
                      </td>
                      <td>
                        <p>0.0<br>-</p>
                      </td>
                      <td>0.0</td>
                    </tr>
                    <tr>
                      <td>
                        <p>LETTER</p>GRADE
                      </td>
                      <td>
                        <p>O</p>
                      </td>
                      <td>D</td>
                      <td>A</td>
                      <td>B</td>
                      <td>C</td>
                      <td>E</td>
                      <td>U</td>
                      <td>AA</td>
                    </tr>
                  </tbody>
                </table>
                <p>For example: For &nbsp;total marks of &nbsp;83, the grade point is8.3&nbsp;</p>
                <p><strong>Calculation of grade point average for a semester examination:</strong>&nbsp;</p>
                <p>GPA (Grade Point Average)=
                  (Σ<sub>i</sub>C<sub>i</sub>G<sub>i</sub>)/(Σ<sub>i</sub>C<sub>i</sub>)&nbsp;</p>
                <p>GPA=&nbsp;&nbsp;&nbsp;<u>(Sum of the multiplication of grade point by the respective credit of the
                    course cleared
                    in a semester)</u></p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sum
                  of the credits of the courses cleared in semester)&nbsp;</p>
                <p><strong>Calculation of cumulative grade point average for the entire programme:</strong>&nbsp;</p>
                <p>CGPA (Cumulative Grade Point Average)=
                  (Σ<sub>i</sub>C<sub>i</sub>G<sub>i</sub>)/(Σ<sub>i</sub>C<sub>i</sub>)&nbsp;
                </p>
                <p>CGPA=&nbsp;&nbsp;&nbsp;<u>Sum of the multiplication of grade point by the respective credit of the
                    course cleared
                    in the entier programme</u></p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sum
                  of the credits of all the courses cleared in the programme&nbsp;</p>
                <p>Where ‘C<sub>i</sub>’ - Credits earned for course I in any semester.</p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;‘G<sub>i</sub>’
                  –Grade
                  Points obtained for course I in any semester.&nbsp;</p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;‘Σ<sub>i</sub>'-
                  Summation of all the courses cleared in a semester in the case of GPA and all courses cleared in
                  all&nbsp;</p>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Semesters in the case of CGPA.&nbsp;</strong></p>
                <table border="1" align="center">
                  <tbody>
                    <tr align="center">
                      <td width="244">CGPA</td>
                      <td width="166">GRADE</td>
                      <td width="345">
                        <p align="center">Classification of Final Result</p>
                      </td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">9.0 -10.0</p>
                      </td>
                      <td>O</td>
                      <td rowspan="2">First Class with Distinction</td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">8.0 – 8.9</p>
                      </td>
                      <td>D</td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">7.0 – 7.9</p>
                      </td>
                      <td>A</td>
                      <td rowspan="2">First Class</td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">6.0 – 6.9</p>
                      </td>
                      <td>B</td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">5.0 – 5.9</p>
                      </td>
                      <td>C</td>
                      <td>Second Class</td>
                    </tr>
                    <tr align="center">
                      <td>
                        <p align="center">4.0 – 4.9</p>
                      </td>
                      <td>D</td>
                      <td>Third Class</td>
                    </tr>
                  </tbody>
                </table>
                <p><strong>Other Abbreviations</strong>&nbsp;</p>
                <p>Pass - P, Reappear – RA, Absent – AA&nbsp;</p>
                <p>INT – Internal, EXT – External, TOT - Total</p>
                <!--EndFragment-->
                <p></p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>