import { Component, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { Router, ActivatedRoute} from '@angular/router';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

@Component({
  selector: 'app-album-images',
  templateUrl: './album-images.component.html',
  styleUrls: ['./album-images.component.scss']
})
export class AlbumImagesComponent implements OnInit {

  public images: any = [];
  public items: GalleryItem[] = [];
  public coverImage: any;
  public title: any;
  public id: any;
  constructor(
    public webService: WebServiceService,
    public router: Router,
    public route: ActivatedRoute,
    public gallery: Gallery,
    public lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(id);
    this.webService.getInfraAlbum(this.id).subscribe((data) => {
      if(data.albumImages && data.albumImages.length) {
        this.title = data.title;
        this.images = data.albumImages;
        this.coverImage = this.getImage(this.images);
        this.items = this.images.map((item: any) => new ImageItem({ src: this.webService.getBucketURL(item), thumb: this.webService.getBucketURL(item) }));
         // Get a lightbox gallery ref
        const lightboxRef = this.gallery.ref('lightbox');

        // Add custom gallery config to the lightbox (optional)
        lightboxRef.setConfig({
          imageSize: ImageSize.Cover,
          thumbPosition: ThumbnailsPosition.Top
        });

        // Load items into the lightbox gallery ref
        lightboxRef.load(this.items);
      }
    });
  }

  getImage(images: Array<any>): string {
    const item = images[Math.floor(Math.random() * images.length)];
    return this.webService.getBucketURL(item);
  }

}
