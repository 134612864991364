import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { WebServiceService } from './../web-service.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';


declare var jQuery: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public academicNews: Array<any> = [];
  public events: Array<any> = [];
  public alumniData: Array<any> = [];
  public playerVisible = false;
  public naacGradeVisible = false;
  public enquiryVisible = false;
  public applicationForm: FormGroup | undefined;
  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    public webService: WebServiceService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createFormData();
  }

  ngAfterViewInit() {
    this.webService.getNewsByType('ACADEMIC_NEWS').subscribe((data) => {
      const filterDate = _.filter(data, (news: any)=> {
        var today = moment().format('YYYY-MM-DD').toString();
        var date = moment(news.endDate);
        var now = moment(today);
        if (now <= date) {
           return true;
        } else {
           return false;
        }
      });
      this.academicNews = filterDate;

      setTimeout(() => {
        this.showNewsScroll();
      }, 1000);
    });
    let events: Array<any> = [];
    this.webService.getAcademicEvents().subscribe((academicData) => {
      events = academicData;
      this.webService.getAllDepartmentEvents().subscribe((departmentData) => {
        events = events.concat(departmentData)
        const filterDate = _.filter(events, (event: any)=> {
          var today = moment().format('YYYY-MM-DD').toString();
          var date = moment(event.endDate);
          var now = moment(today);
          if (now <= date) {
             return true;
          } else {
             return false;
          }
        });
        this.events = filterDate;
        setTimeout(() => {
          this.showEventsScroll();
        }, 1000);
      });
    });

    this.webService.getAllAlumni().subscribe((data: any) => {
      this.alumniData = _.shuffle(data);
      setTimeout(() =>{
        const gdlr_core_js = jQuery(this.element.nativeElement).find('.gdlr-core-js-4');
        jQuery(this.element.nativeElement).gdlr_core_title_divider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_flexslider( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_sly( gdlr_core_js );
        jQuery(this.element.nativeElement).gdlr_core_isotope( gdlr_core_js );
      }, 500);
    });
   
    setTimeout(() => {
      this.showImages();
    }, 1000);
  }

  openEnquiryForm() {
    this.enquiryVisible = true;
  }

  createFormData() {
    this.applicationForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{10}$")]),
      course: new FormControl('', [Validators.required]),
      captchaConfirm: new FormControl(null, [Validators.required])
    });
  }

  onSubmit() {
    if (this.applicationForm && this.applicationForm.invalid) {
      const inputAryVar = this.applicationForm.controls;
      // tslint:disable-next-line: forin
      let otherInvalid = false;
      for (let keyVar in inputAryVar) {
        if (inputAryVar[keyVar].invalid) {
          otherInvalid = true;
        }
        inputAryVar[keyVar].markAsTouched();
        inputAryVar[keyVar].markAsDirty();
      }
    } else {
        console.log(' this.applicationForm',  this.applicationForm?.value)
        this.webService.saveAdmissionEnquiry(this.applicationForm?.value).subscribe(() => {
          this.enquiryVisible = false;
          this.toastr.success("Thank you for your inquiry. Our admission team will contact you soon.", "Submitted Successfully");
        }, () => {
          this.enquiryVisible = false;
        });
    }
  }

  showImages() {
    jQuery('#rev_slider_1_1').show().revolution({
      sliderType: "standard",
      sliderLayout: "auto",
      dottedOverlay: "none",
      delay: 3000,
      navigation: {
        keyboardNavigation: "off",
        keyboard_direction: "horizontal",
        mouseScrollNavigation: "off",
        mouseScrollReverse: "default",
        onHoverStop: "off",
        touch: {
          touchenabled: "on",
          touchOnDesktop: "off",
          swipe_threshold: 75,
          swipe_min_touches: 1,
          swipe_direction: "horizontal",
          drag_block_vertical: false
        },
        arrows: {
          style: "uranus",
          enable: true,
          hide_onmobile: true,
          hide_under: 1500,
          hide_onleave: true,
          hide_delay: 200,
          hide_delay_mobile: 1200,
          tmp: '',
          left: {
            h_align: "left",
            v_align: "center",
            h_offset: 20,
            v_offset: 0
          },
          right: {
            h_align: "right",
            v_align: "center",
            h_offset: 20,
            v_offset: 0
          }
        },
        bullets: {
          enable: true,
          hide_onmobile: false,
          hide_over: 1499,
          style: "uranus",
          hide_onleave: true,
          hide_delay: 200,
          hide_delay_mobile: 1200,
          direction: "horizontal",
          h_align: "center",
          v_align: "bottom",
          h_offset: 0,
          v_offset: 30,
          space: 7,
          tmp: '<span class="tp-bullet-inner"></span>'
        }
      },
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: 1380,
      gridheight: 713,
      lazyType: "none",
      shadow: 0,
      spinner: "off",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      disableProgressBar: "on",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLilmit: 0,
      debugMode: false,
      fallbacks: {
        simplifyAll: "off",
        nextSlideOnWindowFocus: "off",
        disableFocusListener: false,
      }
    });

  }

  showNewsScroll() {
    jQuery('#newsScroll').easyTicker({
      direction: 'up',
      visible: 5,
      easing: 'swing'
    });
  }
  
  showEventsScroll() {
    jQuery('#eventsScroll').easyTicker({
      direction: 'up',
      visible: 5,
      easing: 'swing'
    });
  }

  getDate(date: any) {
    if(date) {
      return moment(date).format('DD')
    } else {
      return '';
    }
  }
  
  getMonth(date: any) {
    if(date) {
      return moment(date).format('MMM')
    } else {
      return '';
    }
  }
  
  getDateFull(date: any) {
    if(date) {
      return moment(date).format('Do MMM YY')
    } else {
      return '';
    }
  }

  openPlayer() {
    this.playerVisible = true
  }

}
