import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dbt-equipment',
  templateUrl: './dbt-equipment.component.html',
  styleUrls: ['./dbt-equipment.component.scss']
})
export class DBTEquipmentComponent implements OnInit {


  public physics = [
    {
      "name": "Planck’s constant setup – SES Make",
      "quantity": 3
    },
    {
      "name": "Curie Temperature determination setup – Besto Make",
      "quantity": 3
    },
    {
      "name": "Solar cell efficiency calculation setup – Besto Make",
      "quantity": 3
    },
    {
      "name": "Four Probe setup – Ses Make",
      "quantity": 2
    },
    {
      "name": "Inverse Square law setup – NVIS-6006D",
      "quantity": 3
    },
    {
      "name": "Fibre Optic Experiment Setup – Pico Make (with two different lengths of cables)",
      "quantity": 2
    },
    {
      "name": "Function generator – Testronix Make",
      "quantity": 5
    },
    {
      "name": "Abbe’s Refractometer – Besto Make",
      "quantity": 2
    },
    {
      "name": "PN Junction Diode Characteristics Setup",
      "quantity": 6
    },
    {
      "name": "Lee’s Disc method with hot plate – Dollar Make",
      "quantity": 3
    },
    {
      "name": "Glucose concentration setup – Equiptronics Make",
      "quantity": 3
    },
    {
      "name": "Cathode Ray Oscilloscope – Scientific Tech",
      "quantity": 3
    },
    {
      "name": "Spectrometer – Weswox Make",
      "quantity": 6
    },
    {
      "name": "Ultrasonic Interferometer – Pico Make",
      "quantity": 2
    },
    {
      "name": "BH Curve Setup – Pico Make",
      "quantity": 2
    },
    {
      "name": "Hall Effect Setup - Pico Make",
      "quantity": 2
    }
  ];

  public chemistry = [
    {
      "name": "Digital pH meter \nRange: 0-14 pH, Accuracy: +/-0.01pH;; LCD Display ; manual temp. compensation with combined electrode",
      "quantity": 10
    },
    {
      "name": "Digital Conductivity meter \nRange 0 - 200mS supplied with conductivity cell constant 1.0",
      "quantity": 5
    },
    {
      "name": "Digital Potentiometer with two electrode\nRange 0 – 1.999V; dual channel with built-in standard cell",
      "quantity": 20
    },
    {
      "name": "Heating mantle – 250 ml",
      "quantity": 10
    },
    {
      "name": "Melting point apparatus - digital",
      "quantity": 1
    },
    {
      "name": "UV – Vis Spectrophotometer, Single beam , Grating 1200 lines/mm, Band width - 2 nm; Wave length : 190 – 1000 mm; 4 position 10 mm cell holder,Silicon photodiode detector, D2 lamp & Tungstan Halogen light source, Accessories: Software, 4 nos. 10 mm Glass cuvette",
      "quantity": 2
    },
    {
      "name": "Flame photometer with Na, K filter, With Air compressor, Pressure Gauge, Pressure Regulator & Air filter",
      "quantity": 1
    },
    {
      "name": "Mechanical shaker: Mild steel body with epoxy powder coated, Stainless steel tray of size : 425 * 425 mm, Shaking speed of 20 -200 rpm orbital strokes per min, Permanent magnet geared DC motor with 25 mm dia motion can hold 16 Nos. of 250 ml flask",
      "quantity": 3
    },
    {
      "name": "Centrifuge machine - RPM 3500; 4*15 ml tube",
      "quantity": 11
    },
    {
      "name": "Hot air oven – with digital temperature controller, Temp. Range: Ambient +5 to 250 deg C, Size : 450 * 450 * 450 mm",
      "quantity": 1
    },
    {
      "name": "Electronic balance, Maximum Capacity : 220 gm, Accuracy : 0.0001 gm",
      "quantity": 1
    },
    {
      "name": "Water analysis kit, 3 ½ digit LCD Display covering 5 Parameters pH 0-14/Cond. 0-20µs/ TDS 0-20ppt/ Turbidity 0-1000 NTU/ Temp 0-100°c",
      "quantity": 1
    },
    {
      "name": "Gel electrophoresis with power pack, Horizontal unit size :10 x 7 cm, 5 x 7 cm, Power pack : Output 50, 100, 150, 200, 250 V DC; 100mA",
      "quantity": 2
    },
    {
      "name": "Magnetic stirrer – with RPM indicator",
      "quantity": 10
    },
    {
      "name": "Dip coater – Manual method with motorized dipping unit",
      "quantity": 1
    },
    {
      "name": "Ultra sonicator – 2.5 lit - Fully Stainless steel body and Stainless steel tank incorporated with Digital Timer, Heater , Digital Temp. controller, S.S lid and S.S basket ; Tank Size : 240 L * 135 B * 65 H (mm)",
      "quantity": 1
    },
    {
      "name": "Magnetic stirrer with temperature controller -2MLH -REMI",
      "quantity": 3
    },
    {
      "name": "Rotary evaporator\nThe main unit is supplied with waterbath with digital controller and Portable vacuum pump ; Rotating spped 20 – 200 RPM with vertical condenser ; Temperature +35 deg C to 90 deg C",
      "quantity": 1
    },
    {
      "name": "Muffle furnace – Operating Temperature : 1000 deg C, Size : 150 * 150 * 300 mm",
      "quantity": 1
    },
    {
      "name": "Centrifuge machine with 6 * 1.5 ml rotor ; 10,000 RPM",
      "quantity": 1
    }
  ];

  public zoology = [
    {
      "name": "Micropipette (10µl to 100 µl)",
      "quantity": 5
    },
    {
      "name": "Micropipette (100 µl to 1000 µl)",
      "quantity": 5
    },
    {
      "name": "Magnetic stirrer",
      "quantity": 1
    },
    {
      "name": "Glass column chromatography",
      "quantity": 2
    },
    {
      "name": "Student Microscope",
      "quantity": 33
    },
    {
      "name": "Double distillation Unit",
      "quantity": 2
    },
    {
      "name": "SDS PAGE Instrument with Powerpack",
      "quantity": 1
    },
    {
      "name": "Soxhlet apparatus",
      "quantity": 1
    },
    {
      "name": "Binocular Vision Microscopes",
      "quantity": 2
    },
    {
      "name": "Digital thermometer and Hygrometer",
      "quantity": 2
    },
    {
      "name": "Digital Anemometer",
      "quantity": 1
    },
    {
      "name": "LUX meter",
      "quantity": 1
    },
    {
      "name": "Altimeter",
      "quantity": 1
    },
    {
      "name": "Compass",
      "quantity": 2
    },
    {
      "name": "Binoculars",
      "quantity": 4
    },
    {
      "name": "Rain Gauge",
      "quantity": 4
    },
    {
      "name": "Distance Meter",
      "quantity": 1
    },
    {
      "name": "Insect show case Cabinet 12 Drawers Capacity",
      "quantity": 1
    },
    {
      "name": "Insect collecting Net",
      "quantity": 11
    },
    {
      "name": "Insect aspirator",
      "quantity": 5
    },
    {
      "name": "Insect collecting Light trap",
      "quantity": 4
    },
    {
      "name": "Insect pins",
      "quantity": 10
    }
  ];

  public cs = [
    {
      "name": "HP Printer HP329DN Duplex AIO Printer Duplex Printing / Scan / Copy with Network",
      "quantity": 2,
    },
    {
      "name": "CISCO 24 Port Switch",
      "quantity": 2,
    },
    {
      "name": "Acer Veriton M200 : H510 Chipset core I5 11400 / 4 GB DDR4 RAM / 1 TB HDD/ NO DVD / Free DOS / USB Keyboard and Mouse / 19.5” Monitor ",
      "quantity": 15,
    },
    {
      "name": "Air Grid 5Ghz Network Access Point ",
      "quantity": 5,
    }
  ];

  public nandD = [
    {
      "name": "Glucometer",
      "quantity": 3
    },
    {
      "name": "Double Stage Water Distillation unit",
      "quantity": 1
    },
    {
      "name": "Blood pressure monitor",
      "quantity": 2
    },
    {
      "name": "Microscope",
      "quantity": 2
    },
    {
      "name": "Haemoglobin meter",
      "quantity": 3
    },
    {
      "name": "Digital bomb colorimeter model",
      "quantity": 1
    },
    {
      "name": "Hot air oven",
      "quantity": 1
    },
    {
      "name": "Digital electronic balance model",
      "quantity": 2
    },
    {
      "name": "Moisture analyzer",
      "quantity": 1
    },
    {
      "name": "Water Bath Rectangular",
      "quantity": 2
    },
    {
      "name": "Muffle furnace",
      "quantity": 1
    },
    {
      "name": "Soxhlet apparatus",
      "quantity": 1
    },
    {
      "name": "Hot plate",
      "quantity": 3
    },
    {
      "name": "Magnetic stirrer",
      "quantity": 3
    },
    {
      "name": "Body fat analyzer",
      "quantity": 1
    },
    {
      "name": "Calorimeter",
      "quantity": 1
    },
    {
      "name": "Micropipette",
      "quantity": 1
    },
    {
      "name": "Centrifuge",
      "quantity": 1
    },
    {
      "name": "Mixie",
      "quantity": 1
    },
    {
      "name": "Pressure Cooker- 3l",
      "quantity": 5
    },
    {
      "name": "Pressure Cooker-1.5 l",
      "quantity": 5
    },
    {
      "name": "Microwave Oven",
      "quantity": 2
    },
    {
      "name": "Vegetable Cutter",
      "quantity": 5
    },
    {
      "name": "Hand Blender",
      "quantity": 2
    },
    {
      "name": "Gas Stove",
      "quantity": 5
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }
  

}
