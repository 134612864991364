<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
    <div class="kingster-header-transparent-substitute"></div>
    <div class="kingster-page-title-overlay"></div>
    <div class="kingster-page-title-container kingster-container">
      <div class="kingster-page-title-content kingster-item-pdlr">
        <div class="kingster-page-caption">IQAC</div>
        <h1 class="kingster-page-title">Internal Quality Assurance Cell</h1>
      </div>
    </div>
  </div>
  <div class="kingster-page-wrapper" id="kingster-page-wrapper">
    <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
      <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
        <div class="gdlr-core-page-builder-body">
          <div class="gdlr-core-pbf-section">
            <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
              <div class="gdlr-core-pbf-element">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        News Letters </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div class="gdlr-core-pbf-element">
                      <div
                        class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                        style="padding-bottom: 45px ;">
                        <div>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20I.pdf" title="Newsletter - I"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20II.pdf" title="Newsletter - II"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20III.pdf" title="Newsletter - III"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20IV.pdf" title="Newsletter - IV"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20V.pdf" title="Newsletter - V"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20VI.pdf" title="Newsletter - VI"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20VII.pdf" title="Newsletter - VII"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20VIII.pdf" title="Newsletter - VIII"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20IX.pdf" title="Newsletter - IX"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20X.pdf" title="Newsletter - X"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20XI.pdf" title="Newsletter - XI"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20XII.pdf" title="Newsletter - XII"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20XIII.pdf" title="Newsletter - XIII"></app-file-viewer>
                          <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/news/Newsletter%20-%20XIV.pdf" title="Newsletter - XIV"></app-file-viewer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-iqac-menu></app-iqac-menu>
    </div>
  </div>