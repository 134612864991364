<div class="kingster-page-title-wrap  kingster-style-medium kingster-left-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">NAAC</div>
      <h1 class="kingster-page-title">National Assessment and Accreditation Council</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class="kingster-content-container kingster-container">
    <div class="gdlr-core-page-builder-body">
      <div class="gdlr-core-pbf-wrapper ">
        <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/naac_report/NAAC_2005.pdf"
                  title="I CYCLE - ACCREDITED WITH B++ STATUS IN 2005.">
                </app-file-viewer>
                <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/naac_report/naac_2012.pdf"
                  title="II CYCLE - RE-ACCREDITED WITH 'A ' GRADE IN 2012.">
                </app-file-viewer>
                <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/naac_report/naac_2017.pdf"
                  title="III CYCLE - RE-ACCREDITED WITH 'A' GRADE IN 2017 .">
                </app-file-viewer>
                <app-file-viewer src="https://storage.srnmcollege.ac.in/srnmcweb/naac_report/naac_2023.pdf"
                title="IV CYCLE - RE-ACCREDITED WITH 'A++' GRADE IN 2023">
              </app-file-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>