<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">Guidelines/ Regulations for Seed Money Grant for Research & Development</div>
      <h1 class="kingster-page-title">Research</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div
                class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style="padding-bottom: 40px ;">
                <div class="gdlr-core-title-item-title-wrap clearfix">
                  <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                    style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                    Guidelines/ Regulations for Seed Money Grant for Research & Development</h3>
                  <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                    style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                </div>
              </div>
            </div>
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                <div class="gdlr-core-text-box-item-content"
                  style="font-size: 16px ;text-transform: none ; text-align: justify;">
                  <p> <b>1. Preamble</b> </p>
                  <p> The Seed Money Grant Scheme of Sri S. Ramasamy Naidu Memorial College is initiated to promote the
                    research interest among the Faculty members as well as the Departmental research activities. This
                    paves way for the vibrant research atmosphere in our College </p>
                  <p> <b>Objectives</b> </p>
                  <ul>
                    <li>
                      To assist the Faculty members to test their pilot research initiatives that has the potential to
                      be converted in to better proposal to attract funding from external agencies.
                    </li>
                    <li>
                      To test their novel idea and to generate preliminary results before submitting proposals to the
                      external agencies.

                    </li>
                    <li>
                      To promote the Inter-Departmental Faculty collaboration in emerging areas.
                    </li>
                    <li>
                      To encourage the publications of the Faculty members in the reputed journals.
                    </li>
                    <li>
                      To attract and retain their talent.
                    </li>
                  </ul>
                  <p> <b>2. Eligibility</b> </p>
                  <p> The eligible criteria will be as follows:
                  </p>
                  <ul>
                    <li>
                      The applicant should be an Assistant Professor who has been appointed against a permanent vacancy
                      following due procedure of the UGC norms.

                    </li>
                    <li>
                      The application should be submitted after a period of one year (minimum) from the date of joining
                      the College.
                    </li>
                    <li>
                      The applicant should be a Ph.D. degree holder with a minimum of 3 (three) research publications in
                      Scopus/Web of Science/UGC-CARE List journals.
                    </li>
                    <li>
                      The applicant should not have any other on-going project funded by the Seed Money Grant scheme at
                      the time of the proposal submission.
                    </li>
                  </ul>
                  <p> <b>3. Guidelines/Regulations:
                    </b> </p>
                  <ul>
                    <li>
                      The application for financial assistance in the prescribed format (enclosed) should be submitted
                      in duplicate to the College Authority through the concerned Head of the Department. The
                      application format shall be available in the College website.
                    </li>
                    <li>
                      The investigators should submit a detailed proposal in the "Prescribed Format" within 30 days of
                      notification. The proposal shall be scrutinized and recommended for funding by the Expert
                      Committee as constituted by the Principal.
                    </li>
                    <li>
                      The maximum eligible funding for a project shall be limited to Rs. 15,000/- (Rupees Fifteen
                      Thousand) for Science and Rs. l0,000/-(Rupees ten thousand) for Arts & Humanities.
                    </li>
                    <li>
                      Subject to availability of the funds, proposals for financial assistance will be screened and
                      approved by the duly constituted committee, which will subsequently be sanctioned by the
                      Principal.
                    </li>
                    <li>
                      Item wise financial estimates should be mentioned clearly in the proposal submitted, showing
                      rates, quantity and total for each item. The maximum allowable expenditure for consumables,
                      fieldwork/travel and other expenses for a project shall not exceed 20%, 20% and 10% of the total
                      estimate respectively. There shall not be any salary/Honorarium component in the project.
                    </li>
                    <li>
                      The Progress Report of the research and its Utilization of Funds should be submitted every six
                      months.
                    </li>
                    <li>
                      The Investigators should make a presentation of the outcome of the research before a committee
                      constituted by the Principal for the purpose.
                    </li>
                    <li>
                      The Final Report, Statement of Expenditure and Utilization Certificate should be submitted after
                      the successful completion of the project. The duration of the project shall not exceed more than
                      one year.
                    </li>
                    <li>
                      Utilization Certificate and Statement of Expenditure (two copies) should be submitted to the
                      Office. The accounts will be audited by the Accounts officer / Finance officer of the Institution.
                    </li>
                    <li>
                      The PIs are instructed to submit two copies of the Project Report to the office along with final
                      Utilization Certificate.
                    </li>
                    <li>
                      The sanction of seed money under this scheme does not entail any right to the investigator for
                      further claims of funding from the College.
                    </li>
                    <li>
                      The decision of the Principal shall be final in all respects.
                    </li>
                  </ul>
                  <p> <b>4. Expected Outcomes:
                    </b> </p>
                  <ul>
                    <li>
                      Funding from External Agencies should be generated as the outcome of Seed Money Grant Scheme.
                    </li>
                    <li>
                      Publication of Research Articles in quality peer-reviewed Journals are expected as outcomes of the
                      project.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-research-menu></app-research-menu>
  </div>
</div>