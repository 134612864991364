import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research-download-forms',
  templateUrl: './research-download-forms.component.html',
  styleUrls: ['./research-download-forms.component.scss']
})
export class ResearchDownloadFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
