import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deans',
  templateUrl: './deans.component.html',
  styleUrls: ['./deans.component.scss']
})
export class DeansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
