<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">DBT</div>
      <h1 class="kingster-page-title">ADVISORY COMMITTEE MEMBER</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Chairman</h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Rajaguru </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Principal, Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Tamil Nadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 94426 10145 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: principal@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        DBT Representatives</h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. Garima Gupta </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Scientist-F </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Unit Head and In-Charge </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Biotechnology </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Govt. of India </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            C. G. O. Complex, Lodhi Road </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            New Delhi – 110 003 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Phone No.: 011-24369385 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: garima.g@nic.in </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. Abhishek Mehta </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Scientist 'C' </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Programme Officer </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Biotechnology </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Govt. of India </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            C. G. O. Complex, Lodhi Road </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            New Delhi – 110 003 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Phone No.: 011-24360718 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: ak.mehta@dbt.nic.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Members - External Experts (2) </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. R. Rajendran, M.Sc., PGDEM., MBA., PhD. </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Associate Professor, Department of Microbiology </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            PSG College of Arts & Science </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Civil Aerodrome (post), Coimbatore – 641 014 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Tamil Nadu, India </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 9443751400 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            E- mail: harathi@psgrkcw.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. B. Harathi, M.Sc., M.Phil., Ph.D. </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor, Department of Zoology </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            PSGR Krishnammal College for Women, Coimbatore – 641 004 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Tamil Nadu, India </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile. No: +91-9944960978 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            E- mail: harathi@psgrkcw.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Head of Science Departments </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Thamilmaran, M.Sc., M.Phil., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Associate Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Physics </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 94422 61920 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: thamilmaran@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. K. Krishnaveni, B.E., M.Tech., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Associate Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Computer Science </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 95858 00592 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: kkrishnaveni@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Rajaguru, M.Sc., M.Phil., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Associate Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Zoology </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 90809 10229 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: rajaguru@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. D. K. Nathan, M.Sc., M.Phil., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Associate Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Maths </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 94431 44766 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: nathan@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr P. Sarojini, M.Sc., M.Phil., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Chemistry </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 94875 25026 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: sarojini@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. M. Gayathri Devi, M.Sc., Ph.D., </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Nutrition & Dietetics </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 70751 88510 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: gayathridevi@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Overall Coordinator, Member – Secretary </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Sundara Venkatesh </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Physics </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sri S. Ramasamy Naidu Memorial College </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Sattur – 626 203, Virudhunagar District, Tamilnadu </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile: +91- 80723 57026 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: sundaravenkatesh@srnmcollege.ac.in </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Coordinators (from all Participating departments) </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. P. Sundara Venkatesh </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Physics </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. A. Ranichitra </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Computer Science </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. B. Ravichandran </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Zoology </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Thiripurasundari </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Mathematics </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. V. Latha </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Chemistry </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. M. Gayathri Devi </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Assistant Professor </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Nutrition & Dietetics </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Physics </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. P. Thamilmaran</td>
                              <td style="text-align: left; padding-left: 10px ;">Associate Professor</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. P. Sundara Venkatesh</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. Nithya</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. Pangajavalli</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. K. Saravana Kumar</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. Vijayalakshmi</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Computer Science </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. K. Krishnaveni</td>
                              <td style="text-align: left; padding-left: 10px ;">Associate Professor</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. K. Arunesh</td>
                              <td style="text-align: left; padding-left: 10px ;">Associate Professor</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="text-align: left; padding-left: 10px ;">G. Subramanian</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. A. Rajeshkanna</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. A. Ranichitra</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Zoology </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. P. Rajaguru</td>
                              <td style="text-align: left; padding-left: 10px ;">Associate Professor</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. R. Seenivasan</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. B. Ravichandran</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. P. Kaliraj</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. G. Pandiarajan</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Mathematics </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. D. K. Nathan</td>
                              <td style="text-align: left; padding-left: 10px ;">Associate Professor</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. V. Thiripurasundari</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. R. Sridevi</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. D. Meera Devi</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. Saravanakumar</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. P. Nirmala Devi</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. J. Krishnaveni</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. K. V. Geetha</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. S. Abirami</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. R. Revathy</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Chemistry </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. P. Sarojini</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. T. Kavitha</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. V. Latha</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. K. Buvaneswari</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Nutrition & Dietetics </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name </th>
                              <th> Designation </th>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td style="text-align: left; padding-left: 10px ;">Dr. M. Gayathri Devi</td>
                              <td style="text-align: left; padding-left: 10px ;">Assistant Professor</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        LIST OF MENTORS </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>





                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr. K. Jeganathan </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Professor</div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Director – Centre for Nanoscience and Nanotechnology</div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Physics </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Bharathidasan University, Tiruchirappalli , Tamil Nadu – 620 024 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile No.: 98945 46936 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            E-mail: kjeganthan@yahoo.com </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-medium gdlr-core-personnel-style-medium">
                    <div
                      class="gdlr-core-personnel-list-column  gdlr-core-column-60 gdlr-core-column-first gdlr-core-item-pdlr">
                      <div class="gdlr-core-personnel-list clearfix">
                        <div class="gdlr-core-personnel-list-content-wrap">
                          <h3 class="gdlr-core-personnel-list-title"
                            style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                            Dr N. Gopalakrishnan </h3>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Professor</div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Department of Physics </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            National Institute of Technology, Tiruchirappalli, Tamil Nadu - 620 015 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Mobile No.: 98949 14905 </div>
                          <div class="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
                            style="font-size: 16px ;font-weight: 500 ;font-style: normal ;letter-spacing: 0px ;">
                            Email: ngk@nitt.edu </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-dbt-menu></app-dbt-menu>
  </div>
</div>