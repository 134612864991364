<div class="kingster-page-title-wrap  kingster-style-medium kingster-center-align">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr">
      <div class="kingster-page-caption">DBT</div>
      <h1 class="kingster-page-title">Programmes organized</h1>
    </div>
  </div>
</div>
<div class="kingster-page-wrapper" id="kingster-page-wrapper">
  <div class=" kingster-sidebar-wrap clearfix kingster-line-height-0 kingster-sidebar-style-left">
    <div class=" kingster-sidebar-center kingster-column-45 kingster-line-height iqac-content">
      <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-section">
          <div class="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div class="gdlr-core-pbf-element">
              <div class="gdlr-core-course-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-course-style-list"
                style="padding-bottom: 45px ;">





                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name of the Programme </th>
                              <th> Date(s) </th>
                              <th> No. of Beneficiaries </th>
                            </tr>
                            <tr *ngFor="let phy of common; index as idx;">
                              <td style="width: 20px;" [width]="10">{{idx + 1}}</td>
                              <td style="text-align: left; padding-left: 10px ;" [width]="50">{{phy.name}}</td>
                              <td style="text-align: center; padding-left: 10px ;" [width]="30">{{phy.date}}</td>
                              <td style="text-align: center; padding-left: 10px ; width: 30px;" [width]="20">{{phy.beneficiaries}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Physics </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>



                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name of the Programme </th>
                              <th> Date(s) </th>
                              <th> No. of Beneficiaries </th>
                            </tr>
                            <tr *ngFor="let phy of physics; index as idx;">
                              <td style="width: 20px;" [width]="10">{{idx + 1}}</td>
                              <td style="text-align: left; padding-left: 10px ;" [width]="50">{{phy.name}}</td>
                              <td style="text-align: center; padding-left: 10px ;" [width]="30">{{phy.date}}</td>
                              <td style="text-align: center; padding-left: 10px ; width: 30px;" [width]="20">{{phy.beneficiaries}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

            
              
                <div class="gdlr-core-pbf-element">
                  <div
                    class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                    style="padding-bottom: 40px ;">
                    <div class="gdlr-core-title-item-title-wrap clearfix">
                      <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                        style="font-size: 22px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #223d71 ;margin-right: 30px ;">
                        Department of Nutrition & Dietetics 
                      </h3>
                      <div class="gdlr-core-title-item-divider gdlr-core-right gdlr-core-skin-divider"
                        style="font-size: 22px ;border-bottom-width: 3px ;"></div>
                    </div>
                  </div>
                </div>


                <div class="gdlr-core-pbf-element">
                  <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                    <div class="gdlr-core-text-box-item-content"
                      style="font-size: 16px ;text-transform: none ; text-align: justify;">
                      <div style="overflow-x: auto;">
                        <table style="min-width: 835px;">
                          <tbody>
                            <tr>
                              <th> S.No </th>
                              <th> Name of the Programme </th>
                              <th> Date(s) </th>
                              <th> No. of Beneficiaries </th>
                            </tr>
                            <tr *ngFor="let phy of nandD; index as idx;">
                              <td style="width: 20px;" [width]="10">{{idx + 1}}</td>
                              <td style="text-align: left; padding-left: 10px ;" [width]="50">{{phy.name}}</td>
                              <td style="text-align: center; padding-left: 10px ;" [width]="30">{{phy.date}}</td>
                              <td style="text-align: center; padding-left: 10px ; width: 30px;" [width]="20">{{phy.beneficiaries}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-dbt-menu></app-dbt-menu>
  </div>
</div>