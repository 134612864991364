<div class="kingster-page-title-wrap kingster-style-custom {{align === 'left' ? 'kingster-left-align': 'kingster-center-align'}}"
  style="background-image: url(./../../assets/images/arial_view.png) ;" id="aboutSRNMC">
  <div class="kingster-header-transparent-substitute"></div>
  <div class="kingster-page-title-overlay"></div>
  <div class="kingster-page-title-bottom-gradient"></div>
  <div class="kingster-page-title-container kingster-container">
    <div class="kingster-page-title-content kingster-item-pdlr" style="padding-top: 505px ;padding-bottom: 60px ;">
    <div class="kingster-page-caption" *ngIf="subtitle !== ''">{{subtitle}}</div>
      <h1 class="kingster-page-title"
        style="font-size: 48px ;font-weight: 700 ;text-transform: none ;letter-spacing: 0px ;color: #ffffff ;"> {{title}} </h1>
    </div>
  </div>
</div>